import { HttpResponse } from '@sophya/eyekia/lib/GeneratedClient';
import { client } from 'clients/eyekia';
import { AssetType } from 'stores/RootStore';

type UploadInfo = {download:{url:string}, upload:{url:string, fields: object}};



export async function uploadImageToAsset(assetId: string, type: AssetType, file: File | Blob): Promise<string> {
  const responseEyekia = await getEyekiaUploadUrl(type, assetId);
  if (!responseEyekia || !responseEyekia.ok) {
    console.error('eyekia get url failed');
    return;
  }
  
  const formData = new FormData();
  formData.append('Content-Type', file.type);
  Object.entries(responseEyekia.data.upload.fields).forEach(([k, v]) => {
    formData.append(k, v);
  });
  formData.append('file', file); // must be the last one
  const responseS3 = await fetch(responseEyekia.data.upload.url, {
    method: 'POST',
    body: formData,
  });
  if (!responseS3.ok) {
    console.error('image upload unsucessful');
    return;
  }

  return responseEyekia.data.download.url;
}


async function getEyekiaUploadUrl(type: AssetType, assetId: string) {
  switch (type){
    case AssetType.Furniture:
      return await client().v1.assetUploadUrlDetail(assetId);

    case AssetType.Wall:
      return await client().v1.wallUploadUrlDetail(assetId);

    case AssetType.Floor:
      return await client().v1.floorUploadUrlDetail(assetId);

    default:
      console.error('no type provided');
      return null;

  }
  
}