export function isValidUrl(testUrl: string): true | string {
  if (
    /^(ftp|http|https):\/\/[^ "]+$/.test(testUrl) || // url
    /^mailto:([^\?]*)/.test(testUrl) // mailto
  ) {
    return true;
  } else {
    return 'Please Enter A Valid Url';
  }
}

export function isValidFloat(val: string): true | string {
  if (/^-?([0-9]+(\.[0-9]*)?)|-?([0-9]*(\.[0-9]+))$/.test(val)) {
    return true;
  } else {
    return 'Please Enter a Valid Number';
  }
}

export function isValidInt(val: string): true | string {
  if (/^-?[0-9]+$/.test(val)) {
    return true;
  } else {
    return 'Please enter a valid integer';
  }
}
