import iter from 'iterare';
import React from 'react';
import { Add } from '@mui/icons-material';
import { IconButton, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { Filler } from 'common/components/Filler';
import { FlexColumn } from 'common/FlexColumn';
import { observer } from 'mobx-react-lite';
import { AssetConfigDO } from 'stores/AssetConfig';
import { FramePanel } from './FramePanel';

type ConfigPanelProps = {
  config: AssetConfigDO;
};
export const ConfigPanel = observer((props: ConfigPanelProps) => {
  const { config } = props;

  return (
    <FlexColumn>
      <Box display='flex'>
        <Typography sx={{ width: '100px' }}>frames:</Typography>
        <Filler />
        <IconButton onClick={config.createNewFrameConfig}>
          <Add />
        </IconButton>
      </Box>
      <FlexColumn flexGrow={1}>
        {iter(config.frames)
          .toArray()
          .map(([id, frameData]) => {
            return (
              <FramePanel onDelete={config.deleteFrameConfig} frame={frameData} frameId={id} />
            );
          })}
      </FlexColumn>
    </FlexColumn>
  );
});
