import { BaseStore } from './BaseStore';
import { Category } from '@sophya/eyekia';
import { client } from 'clients/eyekia';
import { action, makeObservable, observable, runInAction } from 'mobx';
import { AutoId } from 'common/AutoId';
import { CategoryDO } from './Category';
import { uploadImageToAsset } from 'common/uploadImageToAsset';
import { AssetType } from './RootStore';

export class CategoryStore extends BaseStore {
  constructor() {
    super();
    makeObservable(this, {
      categories: observable,
      categoryEditorOpen: observable,
      categoryIdToEdit: observable,
      refreshing: observable,
      thumbnailCategoryId: observable,
      thumbnailEditorOpen: observable,
      handleOpenEditor: action,
    });
    this.refreshCategories();
  }

  refreshing: boolean = false;

  categories = new Map<string, CategoryDO>();

  refreshCategories = async () => {
    this.refreshing = true;

    const res = await client().v1.categoryList();
    runInAction(() => {
      for (const obj of res.data.categories) {
        const cat = new CategoryDO(obj.id);
        cat.updateFromSchema(obj.data);
        this.categories.set(obj.id, cat);
      }

      const nullCat = new CategoryDO(null);
      nullCat.name.value = 'None';
      this.categories.set(null, nullCat);
    });
    this.refreshing = false;
  };

  handleDispose() {
    //
  }

  handleCreateCategory = async (name: string) => {
    const res = await client().v1.categoryUpdate(AutoId.newId(), {
      data: {
        name,
      },
    });
    runInAction(() => {
      const cat = new CategoryDO(res.data.id).updateFromSchema(res.data.data);
      this.categories.set(res.data.id, cat);
    });
  };

  handleDeleteCategory = async (id: string) => {
    const res = await client().v1.categoryDelete(id, {});

    runInAction(() => {
      this.categories.delete(id);
    });
  };

  categoryIdToEdit: string = null;
  categoryEditorOpen = false;

  handleOpenEditor = (id: string) => {
    this.categoryIdToEdit = id;
    this.categoryEditorOpen = true;
  };

  handleCloseEditor = () => {
    this.categoryIdToEdit = null;
    this.categoryEditorOpen = false;
  };

  handleSaveCategory = async (id: string) => {
    await this.categories.get(id)?.save();
  };

  handleSaveRank = async (id: string, rank: number) => {
    this.categories.get(id).rank.value = rank;
    await this.categories.get(id)?.save();
  };

  thumbnailCategoryId: string = null;
  thumbnailEditorOpen = false;

  handleOpenThumbnail = (id: string) => {
    this.thumbnailCategoryId = id;
    this.thumbnailEditorOpen = true;
  };

  handleCloseThumbnail = () => {
    this.thumbnailCategoryId = null;
    this.thumbnailEditorOpen = false;
  };

  handleUploadThumbnail = async (id: string, data: Blob) => {
    const category = this.get(id);
    if (category) {
      const downloadUrl = await uploadImageToAsset(
        'categories',
        AssetType.Furniture,
        new File([data], `${id}_thumbnail.png`, {
          type: 'image/png',
        }),
      );
      category.thumbnail.value = downloadUrl;

      await category.save();
    }
  };

  get = (id: string): CategoryDO | undefined => {
    return this.categories.get(id);
  };

  getName = (id: string): string | undefined => {
    return this.categories.get(id)?.name.value;
  };

  entries = () => {
    return this.categories.entries();
  };

  keys = () => {
    return [...this.categories.keys()];
  };
}
