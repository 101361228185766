import { ShapePrimitive } from '@sophya/eyekia';
import { FabricObjectBase, FabricObjectCallbacks } from './FabricObjectBase';
import { FabricStore2 } from './FabricStore2';
import { FabricZone } from './FabricZone';
import { fabric } from 'fabric';
import { Vector2 } from 'vector_math.js';
import { DEFAULT_CIRCLE } from './FabricCircle';
export class FabricShape extends FabricObjectBase<fabric.Circle> {
  constructor(
    store: FabricStore2,
    fabricObject: fabric.Circle,
    private schema: ShapePrimitive,
    callbacks?: FabricObjectCallbacks<FabricShape>,
  ) {
    super(store, fabricObject, callbacks);

    //create zone
    this.zone = new FabricZone(
      this.store,
      new fabric.Circle({
        ...DEFAULT_CIRCLE,
        radius: 0,
        left: this.x,
        top: this.y,
        selectable: true,
        excludeFromExport: true,
        name: `${this.fabricObject.name}-zone`,
        hasControls: false,
      }),
      schema.shape,
      this.color(),
      {
        onMove: this.handleZoneModify,
        onModify: this.handleZoneModify,
        onSelect: this.notifySelect,
        onDeselect: this.notifyDeselect,
      },
    );
    this.children.add(this.zone);
    this.childrenToMove.add(this.zone);

    //create height point
    this.heightPoint = new FabricObjectBase<fabric.Circle>(
      this.store,
      new fabric.Circle({
        ...DEFAULT_CIRCLE,
        left: this.zone.end.x,
        top: this.zone.end.y - this.schema.height,
        fill: this.color(),
        name: `${this.fabricObject.name}-height`,
        lockMovementX: true,
      }),
      {
        onMove: this.handleHeightModify,
        onSelect: this.notifySelect,
        onDeselect: this.notifyDeselect,
      },
    );

    this.children.add(this.heightPoint);
    this.childrenToMove.add(this.heightPoint);
    this.refreshLines();
    this.handleDeselect();
  }

  handleSelect = () => {
    this.zone.handleSelect();
    this.verticalLines.map(val => (val.fabricObject.opacity = 1));
    this.horizontalLines.map(val => (val.fabricObject.opacity = 1));
    this.heightPoint.enable();
  };

  handleDeselect = () => {
    this.zone.handleDeselect();
    this.verticalLines.map(val => (val.fabricObject.opacity = 0.5));
    this.horizontalLines.map(val => (val.fabricObject.opacity = 0.5));
    this.heightPoint.disable();
  };

  zone: FabricZone;

  heightPoint: FabricObjectBase<fabric.Circle>;

  cachedHeight: number;

  handleZoneModify = () => {
    this.callbacks?.onModify?.(this);
    this.heightPoint.x = this.zone.end.x;
    this.heightPoint.y = this.zone.end.y - this.cachedHeight;
    this.heightPoint.fabricObject.setCoords();
    this.refreshLines();
  };

  handleHeightModify = () => {
    this.callbacks?.onModify?.(this);
    this.refreshLines();
  };

  horizontalLines: FabricObjectBase<fabric.Line>[] = [];
  verticalLines: FabricObjectBase<fabric.Line>[] = [];

  refreshLines = () => {
    const height = this.calculateHeight();
    this.cachedHeight = height;
    // yOffset is negative hight, because in fabricjs, the y axis is switched;
    const yOffset = -height;

    // updating horizontal lines
    const horizontalLineData = this.zone.generateLineData();
    console.log({
      yOffset,
      x: horizontalLineData[0].end.x,
      y: horizontalLineData[0].end.y,
    });
    for (const data of horizontalLineData) {
      data.start.y += yOffset;
      data.end.y += yOffset;
    }

    console.log({
      yOffset,
      x: horizontalLineData[0].end.x,
      y: horizontalLineData[0].end.y,
    });

    if (this.horizontalLines.length === 0) {
      for (const line of horizontalLineData) {
        const fabLine = new FabricObjectBase(
          this.store,
          new fabric.Line([line.start.x, line.start.y, line.end.x, line.end.y], {
            stroke: this.color(),
            opacity: 1,
            selectable: false,
            evented: false,
            excludeFromExport: true,
          }),
        );

        this.children.add(fabLine);
        this.childrenToMove.add(fabLine);
        this.horizontalLines.push(fabLine);
      }
    } else {
      this.horizontalLines.map((line, index) => {
        const lineData = horizontalLineData[index];
        line.fabricObject.set({
          x1: lineData.start.x,
          y1: lineData.start.y,
          x2: lineData.end.x,
          y2: lineData.end.y,
        });
      });
    }

    // updating vertical lines
    const verticalLineData = this.zone.generateLineData();

    // changing the existing lines to vertical lines from the start point
    for (const data of verticalLineData) {
      data.end.x = data.start.x;
      data.end.y = data.start.y + yOffset;
    }

    if (this.verticalLines.length === 0) {
      for (const line of verticalLineData) {
        const fabLine = new FabricObjectBase(
          this.store,
          new fabric.Line([line.start.x, line.start.y, line.end.x, line.end.y], {
            stroke: this.color(),
            opacity: 1,
            selectable: false,
            evented: false,
            excludeFromExport: true,
          }),
        );

        this.children.add(fabLine);
        this.childrenToMove.add(fabLine);
        this.verticalLines.push(fabLine);
      }
    } else {
      this.verticalLines.map((line, index) => {
        const lineData = verticalLineData[index];
        line.fabricObject.set({
          x1: lineData.start.x,
          y1: lineData.start.y,
          x2: lineData.end.x,
          y2: lineData.end.y,
        });
      });
    }
  };

  calculateHeight = () => {
    return this.zone.end.y - this.heightPoint.y;
  };

  toSchema = (relativeTo: Vector2): ShapePrimitive => {
    return {
      shapeIntersectionType: this.schema.shapeIntersectionType,
      shape: this.zone.toSchema(relativeTo),
      height: this.calculateHeight(),
      type: 'shape',
    };
  };

  color() {
    if (this.schema.shapeIntersectionType === 'collider') {
      return 'cyan';
    } else if (this.schema.shapeIntersectionType === 'sensor') {
      return 'lime';
    } else {
      return 'lightcoral';
    }
  }
}
