import { Typography } from '@mui/material';
import { FlexRow } from 'common/FlexRow';
import * as React from 'react';

type SectionHeaderProps = React.PropsWithChildren<{ label?: string; secondary?: boolean }>;
export const SectionHeader = (props: SectionHeaderProps) => {
  const { secondary, label } = props;
  return (
    <FlexRow
      sx={{
        bgcolor: secondary ? '#9c27b061' : 'secondary.light',
        ml: -1,
        mr: -1,
        pl: 2,
        pr: 2,
      }}
    >
      {label && (
        <Typography variant={secondary ? 'h5' : 'h4'} fontWeight={secondary ? 'regular' : 'bold'}>
          {props.label}
        </Typography>
      )}
      {props.children}
    </FlexRow>
  );
};
