import { Dialog } from '@mui/material';
import ImageCrop from 'common/components/ImageCrop';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useDropzone } from 'react-dropzone';
import { useRoot } from './RootProvider';

type CategoryThumbnailModalProps = {};
export const CategoryThumbnailModal = observer((props: CategoryThumbnailModalProps) => {
  const root = useRoot();

  const categories = root.categories;

  const id = categories.thumbnailCategoryId;

  const category = categories.get(id);

  const [upImg, setUpImg] = React.useState<string>(null);

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: 'image/png',
  });

  React.useEffect(() => {
    return () => {
      setUpImg(null);
    };
  }, [id]);

  React.useEffect(() => {
    if (acceptedFiles.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => setUpImg(reader.result as string));
      reader.readAsDataURL(acceptedFiles[0]);
    }
  }, [acceptedFiles]);

  if (!category) {
    return null;
  }

  return (
    <Dialog open={categories.thumbnailEditorOpen} onClose={categories.handleCloseThumbnail}>
      <div {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
        <p>Drag 'n' drop some folders here, or click to select folders</p>
      </div>
      <ImageCrop
        onCrop={async data => {
          return categories.handleUploadThumbnail(id, data).then(categories.handleCloseThumbnail);
        }}
        onCancel={categories.handleCloseThumbnail}
        imgDataUrl={upImg}
        aspectRatio={1}
      />
    </Dialog>
  );
});
