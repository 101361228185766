import { FormControlLabel, FormGroup, Switch, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useConfirmDialog } from 'common/hooks/useConfirmDialog';
import { fabric } from 'fabric';
import { observer } from 'mobx-react-lite';
import { useEffect, useRef } from 'react';
import * as React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import isometricGrid from 'resources/isometricGrid.png';
import { AssetPanel } from './assets/AssetPanel';
import { ConfirmationDialog } from './ConfirmDialog';
import { useRoot } from './RootProvider';
import { useAssets } from './useAssets';
import { useFabric } from './useFabric';

type AssetEditorPageProps = {};

export const AssetEditorPage = observer((props: AssetEditorPageProps) => {
  const root = useRoot();
  const fabStore = useFabric();

  const params = useParams();
  const history = useHistory();
  const assets = useAssets();

  const { assetId } = params;

  const asset = assets.getAsset(assetId);

  const canvasRef = useRef(null);

  const [checked, setChecked] = React.useState(true);

  useEffect(() => {
    fabStore.setCanvas(
      new fabric.Canvas(canvasRef.current, {
        renderOnAddRemove: true,
        preserveObjectStacking: true,
      }),
    );
    asset?.findDefaultOrientation();
    fabStore.canvas.backgroundColor = fabStore.getGridLinePattern();
  }, [fabStore.setCanvas]);

  if (!asset) {
    history.replace('/');
  }

  assets.currentAsset = assetId;

  const [openOri, handleInitOri, handleCancelOri, handleConfirmOri] = useConfirmDialog(
    asset?.deleteOrientation,
  );
  const [openPrim, handleInitPrim, handleCancelPrim, handleConfirmPrim] = useConfirmDialog(
    asset?.deletePrimitive,
  );

  const [openImage, handleInitImage, handleCancelImage, handleConfirmImage] = useConfirmDialog(
    asset?.deleteImage,
  );

  const handleGridLineChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    fabStore.canvas.backgroundColor = checked ? null : fabStore.getGridLinePattern();
  };

  return (
    <Box display='flex'>
      <Box>
        <AssetPanel
          id={assetId}
          onDeleteOri={handleInitOri}
          onDeletePrim={handleInitPrim}
          onDeleteImage={handleInitImage}
        />
      </Box>
      <Box height='100%'>
        <Box display='flex' flexDirection='column'  sx={{ border: 1 }}>
          <canvas ref={canvasRef} style={{ display: 'inline' }}></canvas>
          <FormGroup>
            <FormControlLabel control={<Switch checked={checked} onChange={handleGridLineChange} />} label="Grid-line background" />
          </FormGroup>
          <Typography>{'Mouse wheel to zoom. Alt + Click / Drag to pan.'}</Typography>
          <Typography>{'Press q to cycle orientations'}</Typography>
          <Typography>{'Press w to cycle images'}</Typography>
          <Typography>{'Press e to cycle primitives'}</Typography>
        </Box>
      </Box>
      <ConfirmationDialog
        open={openOri}
        onClose={handleCancelOri}
        onConfirm={handleConfirmOri}
        title={'Delete Orientation?'}
        message={'Please confirm'}
      />
      <ConfirmationDialog
        open={openImage}
        onClose={handleCancelImage}
        onConfirm={handleConfirmImage}
        title={'Delete Image?'}
        message={'Please confirm'}
      />
      <ConfirmationDialog
        open={openPrim}
        onClose={handleCancelPrim}
        onConfirm={handleConfirmPrim}
        title={'Delete Primititve?'}
        message={'Please confirm'}
      />
    </Box>
  );
});
