import { MenuProps } from '@mui/material';
import React, { useState } from 'react';

export const useMenu = (
  props?: Partial<MenuProps>,
): [(ev: React.MouseEvent<HTMLElement, MouseEvent>) => void, () => void, MenuProps] => {
  const [anchorEl, setAnchorEl] = useState<any>(null);

  const handleOpen = (ev: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setAnchorEl(ev.currentTarget);
  };

  const onClose = (event: Record<string, unknown>, reason: 'backdropClick' | 'escapeKeyDown') => {
    props?.onClose?.(event, reason);
    setAnchorEl(null);
  };

  const handleClose = () => {
    onClose({}, 'escapeKeyDown');
  };

  const returnProps: MenuProps = {
    anchorEl,
    open: Boolean(anchorEl),
    onClose,
  };

  return [handleOpen, handleClose, returnProps];
};
