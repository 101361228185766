import * as React from 'react';
import { useAuth } from './AuthProvider';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

type LogoutPageProps = {};
export const LogoutPage = observer((props: LogoutPageProps) => {
  const auth = useAuth();

  const history = useHistory();

  React.useEffect(() => {
    auth.logOut().then(() => {
      console.log('replacing history');
      history.push('/');
    });
  }, [auth.user]);
  return <div>logging out</div>;
});
