import { D2Transform } from '@sophya/eyekia';
import { EditableFloat, EditableValue } from './EditableValue';
import { EditableVec2 } from './EditableVec2';

export const DEFAULT_D2_TRANSFORM: D2Transform = {
  position: {
    x: 0,
    y: 0,
  },
  frontPoint: {
    x: 0,
    y: 0,
  },
  depthBand: 0,
};

export class EditableD2Transform {
  changeCallback: (val: D2Transform) => void;
  constructor(
    public label: string,
    initialValue: D2Transform,
    private onSubmit: (data: D2Transform) => void,
  ) {
    this.position = new EditableVec2('position', initialValue.position, this.handleSubmit);
    this.frontPoint = new EditableVec2(
      'frontPoint',
      initialValue.frontPoint,
      this.handleSubmit,
      'green',
    );
    this.depthBand = new EditableFloat('depth', String(initialValue.depthBand), this.handleSubmit);

    if (initialValue.scale) {
      this.scale = new EditableVec2('scale', initialValue.scale, this.handleSubmit);
    }

    if (initialValue.rotationRad) {
      this.rotation = new EditableFloat(
        'rotation',
        String(initialValue.rotationRad),
        this.handleSubmit,
      );
    }
    this.changeCallback = onSubmit;
  }

  private handleSubmit = () => {
    this.changeCallback(this.toSchema());
  };

  public toSchema = (): D2Transform => {
    return {
      position: this.position.toSchema(),
      frontPoint: this.frontPoint.toSchema(),
      depthBand: Number(this.depthBand.value),
      scale: this.scale?.toSchema(),
      rotationRad: this.rotation ? Number(this.rotation.value) : undefined,
    };
  };

  position: EditableVec2;
  frontPoint: EditableVec2;
  depthBand: EditableValue<string>;
  scale: EditableVec2 | undefined;
  rotation: EditableValue<string> | undefined;
}
