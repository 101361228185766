import { CircularProgress, Button, ButtonProps } from '@mui/material';
import * as React from 'react';

type AsyncButtonProps = React.PropsWithChildren<
  Omit<ButtonProps, 'onClick'> & {
    onClick: (evt: any) => Promise<void>;
  }
>;
export const AsyncButton = (props: AsyncButtonProps) => {
  const { onClick, children, ...buttonProps } = props;

  const [waitingOnPromise, setIsWaitingOnPromise] = React.useState(false);

  const handleClick = (evt: any) => {
    setIsWaitingOnPromise(true);
    onClick(evt)
      .catch(console.error)
      .finally(() => {
        setIsWaitingOnPromise(false);
      });
  };

  return (
    <Button onClick={handleClick} {...buttonProps}>
      {waitingOnPromise ? <CircularProgress variant={'indeterminate'} /> : children}
    </Button>
  );
};
