import { CircularProgress, IconButton, IconButtonProps } from '@mui/material';
import * as React from 'react';

type AsyncIconButtonProps = React.PropsWithChildren<
  Omit<IconButtonProps, 'onClick'> & {
    onClick: () => Promise<void>;
  }
>;
export const AsyncIconButton = (props: AsyncIconButtonProps) => {
  const { onClick, children, ...iconButtonProps } = props;

  const [waitingOnPromise, setIsWaitingOnPromise] = React.useState(false);

  const handleClick = () => {
    setIsWaitingOnPromise(true);
    onClick()
      .catch(console.error)
      .finally(() => {
        setIsWaitingOnPromise(false);
      });
  };

  return (
    <IconButton onClick={handleClick} {...iconButtonProps}>
      {waitingOnPromise ? <CircularProgress variant={'indeterminate'} /> : children}
    </IconButton>
  );
};
