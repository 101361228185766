import { Add, DeleteForever } from '@mui/icons-material';
import LockIcon from '@mui/icons-material/Lock';
import UnlockIcon from '@mui/icons-material/LockOpen';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { IconButton, Menu, MenuItem, Tab, Tabs } from '@mui/material';
import { CollapsableSection } from 'common/components/CollapsableSection';
import { Filler } from 'common/components/Filler';
import { FlexColumn } from 'common/FlexColumn';
import { FlexRow } from 'common/FlexRow';
import { useMenu } from 'common/hooks/useMenu';
import { uploadImageToAsset } from 'common/uploadImageToAsset';
import { D2TransformEditor } from 'components/editor/D2TransformEditor';
import { SelectEditor } from 'components/editor/SelectEditor';
import { ValueEditor } from 'components/editor/ValueEditor';
import { SectionHeader } from 'components/SectionHeader';
import { useAssets } from 'components/useAssets';
import { useFabric } from 'components/useFabric';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { BlendModes, ImageIds } from 'stores/Image';
import { PrimitiveIds, PrimitiveTypes } from 'stores/Primitive';
import { AssetType } from 'stores/RootStore';
import { PrimitivePanel } from './PrimitivePanel';

type ImagePanelProps = {
  ids: ImageIds;
  onDeleteImage: (imageIds: ImageIds) => void;
  onDeletePrim: (primitiveIds: PrimitiveIds) => void;
};
export const ImagePanel = observer((props: ImagePanelProps) => {
  const { ids, onDeleteImage, onDeletePrim } = props;
  const { assetId, orientationKey, imageId } = ids;

  const fabStore = useFabric();
  const assets = useAssets();
  const asset = assets.getAsset(assetId);
  const image = assets.getImage(assetId, orientationKey, imageId);

  const [handleOpen, handleClose, menuProps] = useMenu();
  const [isLocked, setLock] = React.useState(false);

  //This hook update the lock status when the image selected change
  useEffect(() => {
    const image = fabStore.getImage(imageId);
    const isSelectable = image === undefined ? true : fabStore.getImage(imageId).fabricObject.selectable;
    setLock(!isSelectable);
    
    if(isLocked)
      fabStore.getCanvas().discardActiveObject().renderAll();
  }, [imageId]);

  function handleLock() {
    setLock(!isLocked);
    fabStore.getImage(imageId).fabricObject.selectable = isLocked

    fabStore.getCanvas().discardActiveObject().renderAll();
  };

  const UploadImage = () => {
    const onDrop = useCallback((acceptedFiles) => {
      acceptedFiles.forEach(async (file) => {
        if(file.type === 'image/png'){
          image.setUrl(await uploadImageToAsset(imageId,AssetType.Furniture,file));
          asset.networkUpdateAsset();
          fabStore.deleteImage(imageId);
          fabStore.loadImage(imageId, image.toSchema());
        }
      });
      
    }, []);
    const {getRootProps, getInputProps, open} = useDropzone({onDrop, noClick: true});

    return (
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <IconButton onClick={open}>
          <ModeEditIcon/>
        </IconButton>
      </div>
    );
  };

  if (!image || !asset) {
    return null;
  }

  return (
    <FlexColumn>
      <SectionHeader secondary label={`Image ${imageId}`}>
        <Filler />
        <UploadImage/>
        <IconButton onClick={() => {
            handleLock();
          }}>{isLocked ? (<LockIcon/ >) : (<UnlockIcon/ >)}
        </IconButton>
        <IconButton
          onClick={() => {
            onDeleteImage(ids);
          }}
        >
          <DeleteForever />
        </IconButton>
      </SectionHeader>
      <CollapsableSection label={'Image Details:'} closed>
        <ValueEditor value={image.url} />
        <D2TransformEditor value={image.transform} />
        <SelectEditor value={image.blendMode} getDisplay={val => BlendModes[val]} />
      </CollapsableSection>
      <SectionHeader label='Primitives' />
      <SectionHeader>
        <FlexRow flexGrow={1}>
          <Tabs
            variant='scrollable'
            value={image.currentPrimitive}
            onChange={(ev, value) => {
              console.log('change event from primitives', ev, value);
              image.selectPrimitive(value);
              image.selectToFabric();
            }}
            sx={{ borderRight: 1, borderColor: 'divider' }}
            scrollButtons='auto'
          >
            {[...image.primitives.entries()].map(([id, prim]) => {
              return <Tab value={id} label={prim.type} key={id} />;
            })}
          </Tabs>
          <IconButton onClick={handleOpen}>
            <Add />
          </IconButton>
          <Menu {...menuProps}>
            {PrimitiveTypes.map(val => (
              <MenuItem
                onClick={() => {
                  image.createPrimitive(val);
                  handleClose();
                }}
                key={val}
              >
                {val}
              </MenuItem>
            ))}
          </Menu>
        </FlexRow>
      </SectionHeader>
      <FlexColumn fullSize={false} height={'100%'} flexGrow={1}>
        {image.currentPrimitive && (
          <PrimitivePanel
            {...props}
            ids={{ ...ids, id: image.currentPrimitive }}
            onDeletePrim={onDeletePrim}
          />
        )}
      </FlexColumn>
    </FlexColumn>
  );
});
