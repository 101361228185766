import { PatternSharp } from '@mui/icons-material';
import { BaseStore } from './BaseStore';
import { EditableSelect } from './editor/EditableValue';
import { RootStore } from './RootStore';
import * as startcase from 'lodash.startcase';
import { AssetCreationJob } from './creation/CreationJob';
import { makeObservable, observable, action } from 'mobx';

declare interface File {
  path: string;
}

function parseFolderName(value: string) {
  const parts = value.split('/');
  if (parts.length > 1) {
    if (parts[0] !== '') {
      return parts[0];
    } else {
      return parts[1];
    }
  } else {
    throw new Error('Please drop in a folder');
  }
}

export class BulkCreationStore extends BaseStore {
  constructor(private root: RootStore) {
    super();
    makeObservable(this, {
      jobs: observable,
      deleteJob: action,
    });
    this.category = new EditableSelect('Category', null, () => {}, root.categories.keys);
  }

  category: EditableSelect<string>;

  jobs: AssetCreationJob[] = [];

  submitFiles(files: File[]) {
    const sortedFiles = files.sort((a, b) => {
      const folderA = parseFolderName(a.path);
      const folderB = parseFolderName(b.path);
      return folderA.localeCompare(folderB);
    });
    let groupOfFiles = [];
    let lastFolder = '';
    for (const file of sortedFiles) {
      const folderName = parseFolderName(file.path);
      if (folderName === lastFolder) {
        groupOfFiles.push(file);
      } else {
        //don't create empty one
        if (groupOfFiles.length > 0) {
          //create job
          const name = startcase(lastFolder);
          const category = this.category.value;
          const job = new AssetCreationJob(
            this.root.categories.keys,
            lastFolder,
            [...groupOfFiles],
            name,
            category,
          );
          this.jobs.push(job);
        }

        // reset
        groupOfFiles = [file];
        lastFolder = folderName;
      }
    }
    if (groupOfFiles.length > 0) {
      const name = startcase(lastFolder);
      const category = this.category.value;
      const job = new AssetCreationJob(
        this.root.categories.keys,
        lastFolder,
        [...groupOfFiles],
        name,
        category,
      );
      this.jobs.push(job);
    }

    console.log('submited files grouped', this.jobs);
  }

  deleteJob(key: string) {
    this.jobs = this.jobs.filter(job => job.key !== key);
  }

  startAll = async () => {
    await Promise.all(this.jobs.map(job => job.startJob()));
  };

  deleteAll = async () => {
    this.jobs = [];
  };

  handleDispose() {}
}
