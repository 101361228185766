import { SeatPrimitive } from '@sophya/eyekia';
import { DEFAULT_CIRCLE } from './FabricCircle';
import { FabricObjectBase, FabricObjectCallbacks } from './FabricObjectBase';
import { FabricStore2 } from './FabricStore2';
import { FabricZone } from './FabricZone';
import { fabric } from 'fabric';
import { Vector2 } from 'vector_math.js';
import { ISOPAL } from 'stores/fabric2/IsoPal';
import characterSeat_N from 'resources/characterSit/characterSeat_N.png';
import characterSeat_E from 'resources/characterSit/characterSeat_E.png';
import characterSeat_S from 'resources/characterSit/characterSeat_S.png';
import characterSeat_W from 'resources/characterSit/characterSeat_W.png';
import { Image } from 'fabric/fabric-impl';

let characterSitReference : Image;

export class FabricSeat extends FabricObjectBase<fabric.Circle> {
  constructor(
    store: FabricStore2,
    fabricObject: fabric.Circle,
    private schema: SeatPrimitive,
    callbacks?: FabricObjectCallbacks<FabricSeat>,
  ) {
    super(store, fabricObject, callbacks);

    //create zone
    const isoRelPosition = schema.shape.position;
    const isoPosition = new Vector2(isoRelPosition.x, isoRelPosition.y).add(
      ISOPAL.pal_to_iso_2d(this.position()),
    );

    const position = ISOPAL.iso_to_pal_2d(isoPosition);
    this.zone = new FabricZone(
      this.store,
      new fabric.Circle({
        ...DEFAULT_CIRCLE,
        radius: 0,
        left: position.x,
        top: position.y,
        selectable: true,
        excludeFromExport: true,
        name: `${this.fabricObject.name}-zone`,
        hasControls: false,
      }),
      schema.shape,
      'purple',
      {
        onMove: () => this.callbacks?.onModify(this),
        onModify: () => this.callbacks?.onModify(this),
        onSelect: this.notifySelect,
        onDeselect: this.notifyDeselect,
      },
    );

    this.children.add(this.zone);
    this.childrenToMove.add(this.zone);
    this.handleDeselect();
  }

  handleMove = (delta: Vector2) => {
    const position = this.position().sub(delta);
    const positionOffset = this.getOffsetPositionDir(this.schema.facingDir);
    characterSitReference.setPositionByOrigin(new fabric.Point(position.x + positionOffset.x, position.y + positionOffset.y), 'center', 'bottom');
  };

  handleSelect = () => {
    this.zone.handleSelect();
    this.enable();

    //When we select the menu, we generate the character sit png
    let canvas = this.store.canvas;
    const position = this.position();
    const characterFacing = this.getCharacterSit(this.schema.facingDir);
    const positionOffset = this.getOffsetPositionDir(this.schema.facingDir);
    fabric.Image.fromURL(characterFacing, function(img){
      characterSitReference = img;
      img.opacity = 0.3;
      img.scale(2);
      img.selectable = false;
      img.setPositionByOrigin(new fabric.Point(position.x + positionOffset.x, position.y + positionOffset.y), 'center', 'bottom');
      canvas.setOverlayImage(img, canvas.renderAll.bind(canvas));
    });
  };

  handleDeselect = () => {
    this.zone.handleDeselect();
    this.disable();

    //We remove the character png when we deselect the menu
    let canvas = this.store.canvas;
    canvas.remove(characterSitReference);
    canvas.overlayImage = null;
    canvas.renderAll.bind(canvas);
  };

  zone: FabricZone;

  toSchema = (relativeTo: Vector2): SeatPrimitive => {
    const position = this.position().sub(relativeTo);

    return {
      shapeIntersectionType: 'sensor',
      transform: {
        position: {
          x: position.x,
          y: -position.y,
        },
        rotationRad: 0,
        scale: {
          x: 1,
          y: 1,
        },
        frontPoint: {
          x: 0,
          y: 0,
        },
        depthBand: 0,
      },
      facingDir: this.schema.facingDir,
      type: 'seat',
      shape: this.zone.toSchema(this.position()),
    };
  };

  /**
   * This function returns the png location in the server based on the direction
   * @param direction The string direction [N, S, E, W]
   * @returns String with the route to the source character png file
   */
  getCharacterSit = (direction: String) => {
    switch(direction){
      case 'S': {
        return characterSeat_S;
      }
      case 'E': {
        return characterSeat_E;
      }
      case 'W': {
        return characterSeat_W;
      }
      default: {
        return characterSeat_N;
      }    
    }
  };

  /**
   * This function return a position offset for each view
   * @param direction The string direction [N, S, E, W]
   * @returns Point with the offset position to apply to the character pngs
   */
  getOffsetPositionDir = (direction: String) => {
    let vectorOffset = new fabric.Point(0, 0);
    switch(direction){
      case 'S': {
        vectorOffset.x = +40;
        vectorOffset.y = 0;
        break;
      }
      case 'E': {
        vectorOffset.x = -40;
        vectorOffset.y = 0;
        break;
      }
      case 'W': {
        vectorOffset.x = +40;
        vectorOffset.y = +15;
        break;
      }
      default: {
        vectorOffset.x = -40;
        vectorOffset.y = +15;
        break;
      } 
    }
    return vectorOffset;   
  };
}
