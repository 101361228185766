import { Divider, Typography } from '@mui/material';
import { CollapsableSection } from 'common/components/CollapsableSection';
import { FlexColumn } from 'common/FlexColumn';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { EditableIsoShape } from 'stores/editor/EditableIsoShape';
import { ValueEditor } from './ValueEditor';
import { Vec2Editor } from './Vec2Editor';

type IsoShapeEditorProps = { value: EditableIsoShape };
export const IsoShapeEditor = observer((props: IsoShapeEditorProps) => {
  const { value } = props;
  return (
    <FlexColumn p={1}>
      <CollapsableSection label={value.label}>
        <Vec2Editor value={value.position} />
        {value.scale && <Vec2Editor value={value.scale} />}
        {value.rotation && <ValueEditor value={value.rotation} />}
      </CollapsableSection>
    </FlexColumn>
  );
});
