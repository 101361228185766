import { AutoId } from 'common/AutoId';
import { isValidFloat, isValidInt } from 'common/validationFunctions';
import { computed, makeObservable, observable } from 'mobx';

export class EditableValue<T> {
  changeCallback: (val: T) => void;
  constructor(
    public label: string,
    startingValue: T,
    private onSubmit: (val: T) => void,
    public validationFunction?: (val: T) => true | string,
    public color: string = null,
  ) {
    makeObservable(this, {
      _value: observable,
      _err: observable,
      value: computed,
    });
    this._value = startingValue;
    this.changeCallback = onSubmit;
  }

  key = AutoId.newId();

  _value: T;

  get value() {
    return this._value;
  }

  set value(val: T) {
    console.log('editable value, setting value ', val, this.label);
    const error = this.validationFunction ? this.validationFunction(val) : true;

    if (typeof error === 'string') {
      this._err = error;
    } else if (val !== this._value) {
      this._value = val;
      this._err = null;
      this.changeCallback(val);
    }
  }

  _err: String;

  get error() {
    return this._err;
  }

  set error(err: String) {
    this._err = err;
  }
}

export class EditableFloat extends EditableValue<string> {
  constructor(
    label: string,
    startingValue: string,
    onSubmit: (val: string) => void,
    color?: string,
  ) {
    super(label, startingValue, onSubmit, isValidFloat, color);
  }
}

export class EditableInt extends EditableValue<string> {
  constructor(
    label: string,
    startingValue: string,
    onSubmit: (val: string) => void,
    color?: string,
  ) {
    super(label, startingValue, onSubmit, isValidInt, color);
  }
}

export class EditableSelect<T> extends EditableValue<T> {
  options: T[] | (() => T[]);
  constructor(
    label: string,
    startingValue: T,
    onSubmit: (val: T) => void,
    options: T[] | (() => T[]),
  ) {
    super(label, startingValue, onSubmit, (val: T) => {
      let innerOptions: T[] =
        typeof this.options === 'function' ? this.options() : (options as T[]);
      return innerOptions.includes(val) ? true : 'Please select one of the options';
    });
    this.options = options;
  }

  getOptions(): T[] {
    return typeof this.options === 'function' ? this.options() : (this.options as T[]);
  }
}
