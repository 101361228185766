import { Add } from '@mui/icons-material';
import { Button, Checkbox, IconButton, Tab, Tabs } from '@mui/material';
import { Box } from '@mui/system';
import { colorFromHSV } from 'common/ColorUtils';
import { FlexRow } from 'common/FlexRow';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { ColorPresetStore } from 'stores/editor/ColorPresetStore';
import { InputSlider } from './InputSlider';

type ColorPresetPanelProps = {
  store: ColorPresetStore;
};

export const ColorPresetPanel = observer((props: ColorPresetPanelProps) => {
  const { store } = props;

  const colorPresetSelected = store.currentPresetIndex !== undefined && store.currentPresetIndex !== null;

  return (
    <>
      <FlexRow>
        Base Color:
        <Button
          onClick={() => {
            store.selectPreset(null);
          }}
        >
          <Box bgcolor={store.baseColor} width='100px' height='60px'></Box>
        </Button>
        Use Base as Preset?
        <Checkbox checked={store.useBaseAsPreset} onChange={() => store.toggleBasePreset()} />
        <Button
          color='secondary'
          onClick={store.removePreset}
          disabled={!colorPresetSelected}
        >
          Delete Preset
        </Button>
      </FlexRow>
      <InputSlider 
        sliderName='Hue' 
        key={`hue-slider-${store.currentPresetIndex}`}
        max={360}
        disabled={!colorPresetSelected} 
        defaultValue={colorPresetSelected ? store.presets[store.currentPresetIndex].hueTarget : 0}
        onChange={store.rotateHueSlider}
      />
      <InputSlider 
        sliderName='Sat' 
        key={`sat-slider-${store.currentPresetIndex}`}
        max={2}
        step={2 / 100}
        disabled={!colorPresetSelected} 
        defaultValue={
          colorPresetSelected
            ? store.presets[store.currentPresetIndex].saturationMultiplier
            : 0
        }
        onChange={store.rotateSaturationSlider}
      />
      <InputSlider 
        sliderName='Val' 
        key={`val-slider-${store.currentPresetIndex}`}
        max={1}
        step={1 / 100}
        disabled={!colorPresetSelected} 
        defaultValue={
          colorPresetSelected
            ? store.presets[store.currentPresetIndex].valueMultiplier
            : 0
        }
        onChange={store.rotateValueSlider}
      />
      <FlexRow flexGrow={1}>
        <Tabs
          variant='scrollable'
          value={store.currentPresetIndex ?? false}
          onChange={(ev, value) => {
            store.selectPreset(value);
          }}
          sx={{ borderRight: 1, borderColor: 'divider' }}
          scrollButtons='auto'
        >
          {store.presets.map((v, index) => {
            return (
              <Tab
                value={index}
                label={`${index + 1}`}
                key={index}
                style={{
                  backgroundColor: colorFromHSV(
                    v.hueTarget / 360,
                    v.saturationMultiplier / 2.0,
                    v.valueMultiplier,
                  ),
                }}
              />
            );
          })}
        </Tabs>
        <IconButton onClick={() => store.addPreset()}>
          <Add />
        </IconButton>
      </FlexRow>
    </>
  );
});
