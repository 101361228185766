import { Typography } from '@mui/material';
import { FlexColumn } from 'common/FlexColumn';
import { IsoShapeEditor } from 'components/editor/IsoShapeEditor';
import { SelectEditor } from 'components/editor/SelectEditor';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { ShadowPrimitiveDO } from 'stores/Primitive';

type ShadowPrimitivePanelProps = {
  primitive: ShadowPrimitiveDO;
};
export const ShadowPrimitivePanel = observer((props: ShadowPrimitivePanelProps) => {
  const { primitive } = props;
  return (
    <FlexColumn>
      <IsoShapeEditor value={primitive.shape} />
      <Typography>
        {'WARNING! Ellipse visualization is wrong! Use the bounds of the rect'}
      </Typography>
      <SelectEditor value={primitive.shadowType} />
    </FlexColumn>
  );
});
