import { ArrowBack } from '@mui/icons-material';
import { Button, CircularProgress, IconButton, Tab, Tabs, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { FlexRow } from 'common/FlexRow';
import { useConfirmDialog } from 'common/hooks/useConfirmDialog';
import { ConfirmationDialog } from 'components/ConfirmDialog';
import { useRoot } from 'components/RootProvider';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useAssets } from '../useAssets';
import { AssetPreview } from './AssetPreview';
import { useHistory } from 'react-router-dom';
import { NewAssetButton } from './NewAssetButton';
import { AsyncIconButton } from 'common/components/AsyncIconButton';
import Add from '@mui/icons-material/Add';
import { useStructures } from 'components/useStructures';
import { StructurePreview } from './StructurePreview';
import { AssetType } from 'stores/RootStore';

type AssetListProps = {
  type: AssetType;
};
export const StructureBrowser = observer((props: AssetListProps) => {
  const structures = useStructures();
  const { type } = props;
  const history = useHistory();
  const root = useRoot();
  const { collator } = root;

  var displayText;
  switch (type) {
    case AssetType.Floor:
      displayText = 'Floor';
      break;
    case AssetType.Wall:
      displayText = 'Wall';
      break;
  }

  const deleteStructure = (id: string) => {
    structures.handleDeleteStructure(type, id);
  };
  const handleCreate = async () => {
    return structures.handleCreateStructure(type);
  };

  const [open, handleInit, handleCancel, handleConfirm] = useConfirmDialog(deleteStructure);

  const [openPub, handleInitPub, handleCancelPub, handleConfirmPub] = useConfirmDialog(async () => {
    return await structures.handlePublishAll(type);
  });

  return (
    <Box width='100%' height='100%' display='flex' flexDirection='column'>
      <FlexRow>
        <IconButton
          onClick={() => {
            history.push('/');
          }}
        >
          <ArrowBack />
        </IconButton>
        <Typography variant='h2'>{displayText}s</Typography>
      </FlexRow>
      <FlexRow>
        <Typography variant='h4'>{`Press button to create ${displayText} ->`}</Typography>
        <AsyncIconButton onClick={handleCreate}>
          <Add />
        </AsyncIconButton>
        <Button variant='contained' color='primary' onClick={handleInitPub}>
          Publish All
        </Button>
        <ConfirmationDialog
          open={openPub}
          onClose={handleCancelPub}
          onConfirm={handleConfirmPub}
          title={'PUBLISH all structures of ' + AssetType[type] + ' category?'}
          message={
            'Publishing these structures will make them available for people to use in the map maker. Do not do this until youve tested them in the web app! Please be careful! NOTE: this will take some time. Check the console for progress feedback.'
          }
        />
      </FlexRow>

      <FlexRow sx={{ flexWrap: 'wrap' }}>
        {structures.loadingStructures ? (
          <CircularProgress variant='indeterminate' />
        ) : (
          [...structures.items(type).entries()].map(([id, s]) => (
            <StructurePreview id={id} key={id} onDelete={handleInit} structure={s} type={type} />
          ))
        )}
      </FlexRow>
      <ConfirmationDialog
        open={open}
        onClose={handleCancel}
        onConfirm={handleConfirm}
        title={'Delete floor?'}
        message={'Please confirm'}
      />
    </Box>
  );
});
