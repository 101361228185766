import { Add, ArrowBack, Edit } from '@mui/icons-material';
import { Button, IconButton, Menu, MenuItem, Paper, Tab, Tabs, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { FlexRow } from 'common/FlexRow';
import { Filler } from 'common/components/Filler';
import { PublishSection } from 'components/PublishButton';
import { useRoot } from 'components/RootProvider';
import { SectionHeader } from 'components/SectionHeader';
import { SelectEditor } from 'components/editor/SelectEditor';
import { ValueEditor } from 'components/editor/ValueEditor';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AssetDO } from 'stores/Asset';
import { ImageIds } from 'stores/Image';
import { PrimitiveIds } from 'stores/Primitive';
import { FlexColumn } from '../../common/FlexColumn';
import { useMenu } from '../../common/hooks/useMenu';
import { OrientationKey, OrientationKeys } from '../../stores/Orientation';
import { useAssets } from '../useAssets';
import { AssetOrientationEditor } from './AssetOrientationEditor';
import AssetThumbnailModal from './AssetThumbnailModal';
import { ColorPresetPanel } from './ColorPresetPanel';
import { ConfigPanel } from './ConfigPanel';
import { ImportExistingAssets } from './ImportExistingAssets';
import { OrientationPanel } from './OrientationPanel';

type AssetPanelProps = {
  id: string;
  onDeleteOri: (orientationKey: OrientationKey) => void;
  onDeleteImage: (ids: ImageIds) => void;
  onDeletePrim: (ids: PrimitiveIds) => void;
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3, width: '750px' }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

interface AssetProps {
  asset: AssetDO;
  panelProps?: AssetPanelProps;
}

/**
 * This component contains all the fields and data that you can
 * find in Asset Details tab
 * @param asset is the asset that we're trying to get access
 * @param props is the general structure of the assets
 * @returns The Asset Detail component
 */
function AssetDetailsPanel(assetProps: AssetProps) {
  const root = useRoot();
  const categories = root.categories;
  const asset = assetProps.asset;

  // Forget which preset was selected, always default to no color preset
  // (workaround to fabric canvas not being shown with correct preset)
  asset.colorPresets.selectPreset(null);

  return (
    <FlexRow>
      <FlexColumn>
        <ValueEditor value={asset.name} />
        <ValueEditor value={asset.description} />
        <ValueEditor value={asset.userHint} />
        <SelectEditor value={asset.category} getDisplay={categories.getName} />
        <SelectEditor
          value={asset.defaultInteractionType}
          getDisplay={value => (value === null ? '---' : value)}
        />
        <ValueEditor value={asset.accessKey} />
        <ColorPresetPanel store={asset.colorPresets}></ColorPresetPanel>
        <ConfigPanel config={asset.config}></ConfigPanel>
      </FlexColumn>
    </FlexRow>
  );
}

/**
 * This component contains all the options to make all the changes for the images and orientations of the asset
 * @param asset is the asset that we're trying to get access
 * @param props is the general structure of the assets
 * @returns The Orientation Data component
 */
function OrientationDataPanel(assetProps: AssetProps) {
  const asset = assetProps.asset;
  const props = assetProps.panelProps;
  const availableOrientations = OrientationKeys.difference(new Set([...asset.orientations.keys()]));

  const [oriChangeOpen, setOriChangeOpen] = useState(false);
  const [handleOpen, handleClose, menuProps] = useMenu();

  const [tabOrientationValue, setValue] = useState(asset.currentOrientation);

  const handleChange = (event: React.SyntheticEvent, newValue: OrientationKey) => {
    setValue(newValue);
    asset.selectOrientation(newValue);
  };

  return (
    <Paper
      elevation={3}
      sx={{
        overflow: 'visible',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        m: 1,
        p: 1,
        pt: 0,
      }}
    >
      <SectionHeader label='Orientations'>
        <ImportExistingAssets asset={asset} />
      </SectionHeader>
      <SectionHeader>
        <Tabs
          value={tabOrientationValue}
          onChange={handleChange}
          aria-label='basic tabs example'
          scrollButtons='auto'
          variant='scrollable'
        >
          {[...asset.orientations.keys()].map(ori => {
            return <Tab label={ori} value={ori} key={ori} />;
          })}
        </Tabs>
        <Filler />
        <IconButton onClick={handleOpen}>
          <Add />
        </IconButton>
        <IconButton onClick={() => setOriChangeOpen(true)}>
          <Edit />
        </IconButton>
        <Box>
          <Menu {...menuProps}>
            {availableOrientations.map(ori => {
              return (
                <MenuItem
                  onClick={() => {
                    asset.handleCreateOrientation(ori);
                    handleClose();
                  }}
                  key={ori}
                >
                  {ori}
                </MenuItem>
              );
            })}
          </Menu>
        </Box>
      </SectionHeader>
      {asset.currentOrientation && (
        <OrientationPanel
          assetId={props.id}
          orientationKey={asset.currentOrientation}
          onDeleteOri={props.onDeleteOri}
          onDeletePrim={props.onDeletePrim}
          onDeleteImage={props.onDeleteImage}
        />
      )}
      <AssetOrientationEditor
        open={oriChangeOpen}
        onConfirm={newOrientations => asset.changeOrientations(newOrientations)}
        onClose={() => setOriChangeOpen(false)}
        mapping={asset.orientationMapping()}
      />
    </Paper>
  );
}

/**
 * @remarks This is the asset editor component
 */
export const AssetPanel = observer((props: AssetPanelProps) => {
  const assets = useAssets();

  var asset = assets.getAsset(props.id);
  const history = useHistory();

  if (!asset) {
    return null;
  }

  const [tabValue, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      <FlexRow>
        <IconButton
          onClick={() => {
            asset.handleFinishEdit();
            history.push('/asset');
          }}
        >
          <ArrowBack />
        </IconButton>
        <Typography variant='h5'>ID: {props.id}</Typography>
      </FlexRow>
      <FlexRow>
        <Button onClick={asset.createThumbnail}>Save as thumb</Button>
        <AssetThumbnailModal />
      </FlexRow>
      <FlexRow>
        {asset.thumbnailImage}
        <PublishSection asset={asset} />
      </FlexRow>

      <Box sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: 224 }}>
        <Tabs
          orientation='vertical'
          variant='scrollable'
          value={tabValue}
          onChange={handleChange}
          aria-label='Vertical tabs example'
          sx={{ borderRight: 1, borderColor: 'divider' }}
        >
          <Tab label='Asset Details' {...a11yProps(0)} />
          <Tab label='Orientation Data' {...a11yProps(1)} />
        </Tabs>
        <TabPanel value={tabValue} index={0}>
          <AssetDetailsPanel asset={asset} />
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <OrientationDataPanel asset={asset} panelProps={props} />
        </TabPanel>
      </Box>
    </>
  );
});
