import { Frame, Vec2 } from '@sophya/eyekia';
import { action, makeObservable, observable } from 'mobx';
import { EditableSelect, EditableValue } from './editor/EditableValue';
import { EditableVec2, VEC_ZERO } from './editor/EditableVec2';

export class FrameDO {
  constructor(public save: () => void) {
    makeObservable(this, {
      size: observable,
      keepAspect: observable,
      hasPlaceholder: observable,
      name: observable,
      updateFromSchema: action,
    });
  }

  public size = new EditableVec2('size', VEC_ZERO, this.save);
  public keepAspect = new EditableSelect<boolean>('keep aspect ratio', true, this.save, [
    true,
    false,
  ]);
  public hasPlaceholder = new EditableSelect<boolean>('has placeholder art', true, this.save, [
    true,
    false,
  ]);
  public name = new EditableValue<string>('frame name', 'Picture', this.save);

  updateFromSchema = (schema: Frame) => {
    this.size = new EditableVec2('size', schema.size, this.save);

    this.keepAspect = new EditableSelect<boolean>(
      'keep aspect ratio',
      schema.keepAspect,
      this.save,
      [true, false],
    );

    this.hasPlaceholder = new EditableSelect<boolean>(
      'has placeholder art',
      schema.hasPlaceholder,
      this.save,
      [true, false],
    );

    this.name = new EditableValue<string>('frame name', schema.name, this.save);
  };

  updateSize = (size: Vec2) => {
    this.size = new EditableVec2('size', size, this.save);
  };

  toSchema(): Frame {
    return {
      size: this.size.toSchema(),
      hasPlaceholder: this.hasPlaceholder.value,
      keepAspect: this.keepAspect.value,
      name: this.name.value,
    };
  }
}
