import { AssetConfig } from '@sophya/eyekia';
import { AutoId } from 'common/AutoId';
import { debounce } from 'lodash';
import { action, makeObservable, observable } from 'mobx';
import { FrameDO } from './FrameDO';
import { ConfigInterface } from './RootStore';

export class AssetConfigDO implements ConfigInterface {
  public frames? = new Map<string, FrameDO>();

  constructor(private save: () => void) {
    makeObservable(this, {
      frames: observable,
      updateFromSchema: action,
    });
  }

  getFrameList = () => {
    if (this.frames === null) {
      return [];
    }
    return Array.from(this.frames.keys());
  };

  public createNewFrameConfig = () : string => {
    const id = AutoId.newId();
    this.frames.set(id, new FrameDO(this.save));
    this.save();

    return id;
  };

  public deleteFrameConfig = (frameId: string) => {
    if (this.frames.has(frameId)) {
      this.frames.delete(frameId);
      this.save();
    }
  };

  updateFromSchema = (schema: AssetConfig) => {
    if (schema) {
      if (schema.frames) {
        for (const [id, data] of Object.entries(schema.frames)) {
          const frame = new FrameDO(this.save);
          frame.updateFromSchema(data);
          this.frames.set(id, frame);
        }
      } else {
        this.frames = null;
      }
    }
  };

  updateFrameSize = debounce(
    (frameId: string, size: { x: number; y: number }) => {
      if (this.frames.has(frameId)) {
        const current = this.frames.get(frameId);
        current.updateSize(size);
        this.save();
      }
    },
    1500,
    { trailing: true },
  );

  toSchema(): AssetConfig {
    const fs = {};
    this.frames.forEach((value, key) => {
      fs[key] = value.toSchema();
    });
    return {
      frames: fs,
    };
  }
}
