import { Delete, Image, Public, Warning } from '@mui/icons-material';
import { IconButton, Typography, Card, Tooltip } from '@mui/material';
import { Box, display, width } from '@mui/system';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { useAssets } from '../useAssets';
import { FlexColumn } from '../../common/FlexColumn';
import { FlexRow } from '../../common/FlexRow';
import { Filler } from '../../common/components/Filler';
import { useStructures } from 'components/useStructures';
import { StructureDO } from 'stores/Structure';
import { AssetType } from 'stores/RootStore';

type StructurePreviewProps = {
  id: string;
  structure: StructureDO;
  type: AssetType;
  onDelete: (assetId: string) => void;
};
export const StructurePreview = (props: StructurePreviewProps) => {
  const history = useHistory();
  const { structure, type } = props;

  var displayText;
  switch (type) {
    case AssetType.Floor:
      displayText = 'floor';
      break;
    case AssetType.Wall:
      displayText = 'wall';
      break;
  }

  const handleViewAsset = () => history.push(`/${displayText}/${props.id}`);

  return (
    <Card sx={{ width: '216px', m: 1 }}>
      <FlexColumn p={1}>
        <FlexRow>
          <Typography onClick={handleViewAsset}>{structure.name.value || props.id}</Typography>
          <Filler />
          <IconButton
            sx={{ position: 'relative' }}
            onClick={() => {
              props.onDelete(props.id);
            }}
          >
            <Delete sx={{ position: 'absolute', top: 0, right: 0 }} />
          </IconButton>
        </FlexRow>
        <Box sx={{ position: 'relative' }}>
          <img
            onClick={handleViewAsset}
            crossOrigin='anonymous'
            src={structure.previewUrl()}
            style={{ width: '200px' }}
          ></img>
          {structure.modifiedSinceLastPublish ? (
            <Tooltip title={'Modified but NOT Published'}>
              <Warning sx={{ color: 'orange', position: 'absolute', bottom: 0, right: 0 }} />
            </Tooltip>
          ) : (
            structure.published.value && (
              <Tooltip title={'Published'}>
                <Public sx={{ color: 'deepskyblue', position: 'absolute', bottom: 0, right: 0 }} />
              </Tooltip>
            )
          )}
        </Box>
      </FlexColumn>
    </Card>
  );
};
