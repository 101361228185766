import { makeObservable, observable, action, runInAction } from 'mobx';
import { OrientationKey } from './Orientation';
import { Image, Primitive } from '@sophya/eyekia';
import { EditableSelect, EditableValue } from './editor/EditableValue';
import { DEFAULT_D2_TRANSFORM, EditableD2Transform } from './editor/EditableD2Transform';
import { isValidUrl } from 'common/validationFunctions';
import {
  PrimitiveDO,
  PrimitiveIds,
  PrimitiveType,
  SeatPrimitiveDO,
  ShadowPrimitiveDO,
  ShapePrimitiveDO,
  FramePrimitiveDO,
} from './Primitive';
import { client } from 'clients/eyekia';
import { ConfigInterface, FabricInterface } from './RootStore';
import { AutoId } from 'common/AutoId';
import { Facing } from './fabric2/FabricStore2';
import { AssetConfigDO } from './AssetConfig';

export type ImageIds = {
  assetId: string;
  orientationKey: OrientationKey;
  imageId: string;
};

export type BlendMode = 'multiply' | 'colorDodge';

export const blendModeOpts = [null, 'multiply', 'colorDodge'];

export const BlendModes = {
  multiply: 'Multiply',
  colorDodge: 'Color Dodge',
  [null as any]: 'Normal',
};

export class ImageDO {
  constructor(
    private fabric: FabricInterface,
    private config: ConfigInterface,
    private ids: ImageIds,
    private onSave: () => Promise<void>,
  ) {
    makeObservable(this, {
      url: observable,
      transform: observable,
      currentPrimitive: observable,
      updateFromSchema: action,
      selectPrimitive: action,
    });
  }

  cyclePrimitive = () => {
    const primitiveKeys = Array.from(this.primitives.keys());
    let newPrimitiveId: string;
    if (this.currentPrimitive) {
      const index = primitiveKeys.findIndex(key => this.currentPrimitive === key);
      if (index === -1) {
        return;
      }
      const nextIndex = (index + 1) % primitiveKeys.length;
      newPrimitiveId = primitiveKeys[nextIndex];
      console.log({
        orientationKeys: primitiveKeys,
        index,
        nextIndex,
        newPrimitiveId,
      });
    } else if (primitiveKeys.length > 0) {
      newPrimitiveId = primitiveKeys[0];
    }

    if (newPrimitiveId) {
      this.selectPrimitive(newPrimitiveId);
      this.selectToFabric();
    }
  };

  callbacksave = () => {
    this.handleImagePanelSave(this.ids, this.toSchema());
  };

  public url: EditableValue<string>;

  public transform = new EditableD2Transform('transform', DEFAULT_D2_TRANSFORM, this.callbacksave);

  public blendMode = new EditableSelect('Blend Mode', null, this.callbacksave, blendModeOpts);

  public primitives: Map<string, PrimitiveDO> = new Map();

  public currentPrimitive: string | false = false;

  handleImagePanelSave = (ids: ImageIds, data: Image) => {
    this.fabric.deleteImage(ids.imageId);
    this.fabric.loadImage(ids.imageId, data);
    this.onSave();
  };

  handlePrimititvePanelSave = (ids: PrimitiveIds, data: Primitive) => {
    this.fabric.deletePrimitive(ids.imageId, ids.id);
    this.fabric.loadPrimitive(ids.imageId, ids.id, data);
    this.onSave();
  };

  createPrimitive = (type: PrimitiveType): void => {
    const id = AutoId.newId();

    let primitiveIds: PrimitiveIds = {
      ...this.ids,
      id,
    };

    runInAction(() => {
      let primitive: PrimitiveDO;
      switch (type) {
        case 'seat': {
          let defaultFacing: Facing = 'N';
          if (new Set(['N', 'S', 'E', 'W']).has(this.ids.orientationKey)) {
            defaultFacing = this.ids.orientationKey as Facing;
          }
          const seat = new SeatPrimitiveDO(
            primitiveIds,
            this.handlePrimititvePanelSave,
            defaultFacing,
          );
          primitive = seat;
          break;
        }
        case 'frame': {
          primitive = new FramePrimitiveDO(
            this.config,
            primitiveIds,
            this.handlePrimititvePanelSave,
          );
          break;
        }
        case 'shape': {
          primitive = new ShapePrimitiveDO(primitiveIds, this.handlePrimititvePanelSave);
          break;
        }
        case 'shadow': {
          primitive = new ShadowPrimitiveDO(primitiveIds, this.handlePrimititvePanelSave);
          break;
        }
        default: {
          primitive = new ShapePrimitiveDO(primitiveIds, this.handlePrimititvePanelSave);
        }
      }
      this.primitives.set(id, primitive);
      this.currentPrimitive = id;
      this.fabric.loadPrimitive(this.ids.imageId, id, primitive.toSchema());
      this.selectToFabric();
    });

    this.onSave();
  };

  deletePrimitive(id: string) {
    runInAction(() => {
      this.primitives.delete(id);
      if (id === this.currentPrimitive) {
        this.currentPrimitive = false;
      }
      this.fabric.deletePrimitive(this.ids.imageId, id);
    });
    this.onSave();
  }

  getPrimitive(id: string): PrimitiveDO | undefined {
    return this.primitives.get(id);
  }

  getCurrentPrimitive(): PrimitiveDO | undefined {
    if (this.currentPrimitive) {
      return this.primitives.get(this.currentPrimitive);
    } else {
      return undefined;
    }
  }

  selectPrimitive = (id: string) => {
    this.currentPrimitive = id;
  };

  selectToFabric() {
    if (this.currentPrimitive) {
      this.fabric.selectPrimitive(this.ids.imageId, this.currentPrimitive);
    }
  }

  updateFromSchema = (schema: Image) => {
    this.url = new EditableValue<string>('url', schema.url, this.callbacksave, isValidUrl);

    this.transform = new EditableD2Transform('transform', schema.transform, this.callbacksave);

    this.blendMode = new EditableSelect(
      'Blend Mode',
      schema.blendMode ?? null,
      this.callbacksave,
      blendModeOpts,
    );

    for (const [id, data] of Object.entries(schema.primitives)) {
      let primitiveIds: PrimitiveIds = {
        ...this.ids,
        id,
      };
      let primitive: PrimitiveDO;
      switch (data.type) {
        case 'seat': {
          primitive = new SeatPrimitiveDO(primitiveIds, this.handlePrimititvePanelSave);
          primitive.updateFromSchema(data);
          break;
        }
        case 'frame': {
          primitive = new FramePrimitiveDO(
            this.config,
            primitiveIds,
            this.handlePrimititvePanelSave,
          );
          primitive.updateFromSchema(data);
          break;
        }
        case 'shape': {
          primitive = new ShapePrimitiveDO(primitiveIds, this.handlePrimititvePanelSave);
          primitive.updateFromSchema(data);
          break;
        }
        case 'shadow': {
          primitive = new ShadowPrimitiveDO(primitiveIds, this.handlePrimititvePanelSave);
          primitive.updateFromSchema(data);
          break;
        }
        default: {
          console.warn('cannot create primitive of type', data);
        }
      }
      this.primitives.set(id, primitive);
    }
    // this.currentPrimitive = this.primitives.keys().next().value;
  };

  setUrl(url: string) {
    this.url = new EditableValue<string>('url', url, this.callbacksave, isValidUrl);
  }

  toSchema(): Image {
    const primitives: {
      [key: string]: Primitive;
    } = {};

    this.primitives.forEach((value, key) => {
      primitives[key] = value.toSchema();
    });

    let blendMode: BlendMode;
    let selectedMode = this.blendMode.value;
    if(selectedMode == 'multiply' || selectedMode == 'colorDodge'){
      blendMode = selectedMode;
    } else {
      blendMode = null;
    }

    return {
      type: 'image',
      url: this.url.value,
      transform: this.transform.toSchema(),
      primitives,
      blendMode,
    };
  }

  select() {
    this.currentPrimitive = false;
  }
}
