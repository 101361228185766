import { Image, Primitive } from '@sophya/eyekia';
import { fabric } from 'fabric';
// import { IImageOptions } from 'fabric/fabric-impl';
import { ISOPAL } from 'stores/fabric2/IsoPal';
import { Vector2 } from 'vector_math.js';
import { DEFAULT_CIRCLE } from './FabricCircle';
import { FabricFrame } from './FabricFrame';
import { FabricObjectBase, FabricObjectCallbacks } from './FabricObjectBase';
import { FabricPrimitive } from './FabricPrimitive';
import { FabricSeat } from './FabricSeat';
import { FabricShadow } from './FabricShadow';
import { FabricShape } from './FabricShape';
import { FabricStore2 } from './FabricStore2';

// const DEFAULT_IMG: IImageOptions = {
//   originX: 'center',
//   originY: 'center',
//   centeredScaling: true,
//   crossOrigin: 'anonymous',
//   lockScalingFlip: true,
// };

export type FabricCircle = FabricObjectBase<fabric.Circle>;

export class FabricImage extends FabricObjectBase<fabric.Image> {
  constructor(
    store: FabricStore2,
    fabricObject: fabric.Image,
    private schema: Image,
    callbacks?: FabricObjectCallbacks<FabricImage>,
  ) {
    super(store, fabricObject, callbacks);
    const position = new Vector2(schema.transform.position.x, -schema.transform.position.y).add(
      this.store.center,
    );
    this.x = position.x;
    this.y = position.y;

    if (schema.transform.scale) {
      const scale = schema.transform.scale;
      this.scaleX = scale.x;
      this.scaleY = scale.y;
    }

    fabricObject.setCoords();
    fabricObject.sendToBack();

    this.pastLocation.x = this.x;
    this.pastLocation.y = this.y;

    const frontPointData = schema.transform.frontPoint;
    const frontPointPosition = new Vector2(frontPointData.x, -frontPointData.y).add(
      this.position(),
    );

    const frontPoint = new fabric.Circle({
      ...DEFAULT_CIRCLE,
      left: frontPointPosition.x,
      top: frontPointPosition.y,
      radius: 6,
      fill: 'green',
      name: `${this.fabricObject.name}-front-point`,
      hasControls: false,
      excludeFromExport: true,
    });

    this.frontPoint = new FabricObjectBase(store, frontPoint, {
      onMove: () => this.callbacks?.onModify?.(this),
      onSelect: this.notifySelect,
      onDeselect: this.notifyDeselect,
    });

    this.children.add(this.frontPoint);
    this.childrenToMove.add(this.frontPoint);

    for (const [id, primitive] of Object.entries(schema.primitives)) {
      this.loadPrimitive(id, primitive);
    }
    this.handleDeselect();
  }

  primitives = new Map<string, FabricPrimitive>();

  loadPrimitive = (primitiveId: string, data: Primitive) => {
    let prim: FabricPrimitive;

    const callbacks: FabricObjectCallbacks<FabricPrimitive> = {
      onMove: () => this.callbacks?.onModify?.(this),
      onModify: () => this.callbacks?.onModify?.(this),
      onSelect: this.handlePrimitiveSelect,
    };

    switch (data.type) {
      case 'shape': {
        const isoRelativePosition = data.shape.position;
        const isoPosition = new Vector2(isoRelativePosition.x, isoRelativePosition.y).add(
          ISOPAL.pal_to_iso_2d(this.position()),
        );

        const position = ISOPAL.iso_to_pal_2d(isoPosition);

        prim = new FabricShape(
          this.store,
          new fabric.Circle({
            left: position.x,
            top: position.y,

            name: `${primitiveId}`,
            evented: false,
            excludeFromExport: true,
            selectable: false,
          }),
          data,
          callbacks,
        );
        break;
      }
      case 'seat': {
        const relativePosition = data.transform.position;
        const absolutePosition = new Vector2(relativePosition.x, -relativePosition.y).add(
          this.position(),
        );

        prim = new FabricSeat(
          this.store,
          new fabric.Circle({
            ...DEFAULT_CIRCLE,
            left: absolutePosition.x,
            top: absolutePosition.y,
            fill: 'purple',
            radius: 10,
            name: `${primitiveId}`,
          }),
          data,
          callbacks,
        );

        break;
      }
      case 'shadow': {
        const isoRelativePosition = data.shape.position;
        const isoPosition = new Vector2(isoRelativePosition.x, isoRelativePosition.y).add(
          ISOPAL.pal_to_iso_2d(this.position()),
        );

        const position = ISOPAL.iso_to_pal_2d(isoPosition);

        prim = new FabricShadow(
          this.store,
          new fabric.Circle({
            left: position.x,
            top: position.y,
            evented: false,
            excludeFromExport: true,
            selectable: false,
            name: `${primitiveId}`,
          }),
          data,
          callbacks,
        );
        break;
      }
      case 'frame': {
        const position = this.position();

        prim = new FabricFrame(
          this.store,
          new fabric.Circle({
            left: position.x,
            top: position.y,
            evented: false,
            excludeFromExport: true,
            selectable: false,
            name: `${primitiveId}`,
          }),
          data,
          callbacks,
        );
        break;
      }
      default: {
        // do nothing if surface or non-handled type
        console.error(
          'Default case reached but should not be reached. Check the data type of the primitive you are loading in FabricImage',
        );
      }
    }

    const oldPrim = this.primitives.get(primitiveId);
    if (oldPrim) {
      this.children.delete(oldPrim);
      this.childrenToMove.delete(oldPrim);
      this.primitives.delete(primitiveId);
    }

    this.primitives.set(primitiveId, prim);
    this.children.add(prim);
    this.childrenToMove.add(prim);
  };

  deletePrimitive = (primitiveId: string) => {
    const oldPrim = this.primitives.get(primitiveId);
    if (oldPrim) {
      oldPrim.delete();
      this.children.delete(oldPrim);
      this.childrenToMove.delete(oldPrim);
      this.primitives.delete(primitiveId);
    }
  };

  private selectedPrimitiveId: string = null;

  selectPrimitive = (primitiveId: string) => {
    if (this.selectedPrimitiveId) {
      this.primitives.get(this.selectedPrimitiveId)?.notifyDeselect();
    }
    this.primitives.get(primitiveId)?.notifySelect();
    this.selectedPrimitiveId = primitiveId;
  };

  handlePrimitiveSelect = () => {
    this.fabricObject.opacity = 1;
  };

  handleSelect = () => {
    this.frontPoint.visible = true;
    this.fabricObject.opacity = 1;
    for (const primitive of this.primitives.values()) {
      primitive.handleDeselect();
    }
  };

  handleDeselect = () => {
    this.frontPoint.visible = false;
    this.fabricObject.opacity = 0.5;
  };

  setColorFilter = (hue:number, saturation: number, value:number) => {
    const maxHue = (hue > 180) ? hue - 360 : hue
    const minHue = (maxHue < -180) ? maxHue + 360 : maxHue
    const normalizedHue = minHue/180
    this.fabricObject.filters = [];
    // @ts-ignore
    this.fabricObject.filters.push(new fabric.Image.filters.HueRotation({rotation: normalizedHue}));
    this.fabricObject.filters.push(new fabric.Image.filters.Saturation({saturation:saturation}));
    this.fabricObject.filters.push(new fabric.Image.filters.Brightness({brightness:value}));
    this.fabricObject.applyFilters();
  }

  toSchema(relativeTo: Vector2): Image {
    const type = 'image';
    const depth = this.schema.transform.depthBand;
    const absolutePosition = new Vector2(this.x, this.y);
    const position = absolutePosition.clone().sub(relativeTo);

    const fpPosition = new Vector2(this.frontPoint.x, this.frontPoint.y).sub(absolutePosition);

    const primitives: Record<string, Primitive> = {};
    for (const [id, prim] of this.primitives.entries()) {
      primitives[id] = prim.toSchema(absolutePosition);
    }

    return {
      transform: {
        position: {
          x: position.x,
          y: -position.y,
        },
        scale: {
          x: this.scaleX,
          y: this.scaleY,
        },
        rotationRad: this.angle,
        depthBand: depth,
        frontPoint: {
          x: fpPosition.x,
          y: -fpPosition.y,
        },
      },
      url: this.schema.url,
      type,
      primitives,
      blendMode: this.schema.blendMode,
    };
  }

  frontPoint: FabricCircle;
}
