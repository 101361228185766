import { ICircleOptions } from 'fabric/fabric-impl';
import { FabricObjectBase } from './FabricObjectBase';

export type FabricCircle = FabricObjectBase<fabric.Circle>;

export const DEFAULT_CIRCLE: ICircleOptions = {
  originX: 'center',
  originY: 'center',
  centeredScaling: true,
  lockScalingFlip: true,
  radius: 6,
  hasControls: false,
  excludeFromExport: true,
};
