import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Menu,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { AsyncButton } from 'common/components/AsyncButton';
import { FlexRow } from 'common/FlexRow';
import SmartSet from 'common/SmartSet';
import { useAssets } from 'components/useAssets';
import * as React from 'react';
import { OrientationKey, OrientationKeys } from 'stores/Orientation';

const UNSET = 'Unset / Delete';

type AssetOrientationEditorProps = {
  mapping: Map<OrientationKey, OrientationKey>;
  open: boolean;
  onConfirm: (mapping: Map<OrientationKey, OrientationKey>) => Promise<void>;
  onClose: () => void;
  invertArrow?: boolean;
  availableOrientations?: SmartSet<OrientationKey>;
};
export const AssetOrientationEditor = (props: AssetOrientationEditorProps) => {
  const { mapping, open, onClose, invertArrow, onConfirm } = props;

  const [newOrientations, setNewOrientations] = React.useState<Map<OrientationKey, OrientationKey>>(
    new Map(),
  );

  React.useEffect(() => {
    if (mapping && open) {
      setNewOrientations(mapping);
    } else {
      setNewOrientations(new Map());
    }
  }, [mapping, open]);

  const handleConfirm = async () => {
    await onConfirm(newOrientations).finally(() => onClose());
  };

  const availableOrientations =
    props.availableOrientations ??
    OrientationKeys.difference(new SmartSet([...newOrientations.values()]));

  const selectOrientation = (original: OrientationKey, newOri: OrientationKey | typeof UNSET) => {
    console.log('select orientation', original, newOri);
    const nextOrientations = new Map(newOrientations);
    if (newOri !== UNSET) {
      nextOrientations.set(original, newOri);
    } else {
      nextOrientations.delete(original);
    }
    setNewOrientations(nextOrientations);
  };

  console.log('newOrientations', newOrientations);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Select new Orientations:</DialogTitle>
      <DialogContent>
        {[...newOrientations.keys()].map(key => {
          const value = newOrientations.get(key) ?? UNSET;
          console.log('mapping', key, value);
          return (
            <FlexRow key={key}>
              <Typography>{key}</Typography>
              {invertArrow ? (
                <KeyboardArrowLeft></KeyboardArrowLeft>
              ) : (
                <KeyboardArrowRight></KeyboardArrowRight>
              )}
              <Select
                value={value}
                onChange={e => {
                  selectOrientation(key, e.target.value as OrientationKey);
                }}
                native={false}
              >
                {value !== UNSET && !props.availableOrientations && (
                  <MenuItem value={value} key={key}>
                    {value}
                  </MenuItem>
                )}
                <MenuItem value={UNSET} key='none'>
                  {UNSET}
                </MenuItem>
                {availableOrientations.map(oriKey => (
                  <MenuItem value={oriKey} key={oriKey}>
                    {oriKey}
                  </MenuItem>
                ))}
              </Select>
            </FlexRow>
          );
        })}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color='secondary'>
          Cancel
        </Button>
        <AsyncButton onClick={handleConfirm} variant='contained' color='primary'>
          Save
        </AsyncButton>
      </DialogActions>
    </Dialog>
  );
};
