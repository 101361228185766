import { Box, Button, TextField, TextFieldProps, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { ColoredDot } from 'common/components/ColoredDot';
import { FlexRow } from 'common/FlexRow';
import { useConfirmDialog } from 'common/hooks/useConfirmDialog';
import { useTextField, useUrlField } from 'common/hooks/useTextField';
import { ConfirmationDialog } from 'components/ConfirmDialog';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import Dropzone from 'react-dropzone';
import { EditableValue } from 'stores/editor/EditableValue';

type ImageUrlEditorProps = {
  value: EditableValue<string>;
  uploadFunction: (img: File) => Promise<string>;
  nested?: boolean;
  canDelete?: boolean;
};
export const ImageUrlEditor = observer((props: ImageUrlEditorProps) => {
  const { value: eValue, uploadFunction, nested, canDelete = false } = props;
  const inputRef = React.useRef<HTMLInputElement>(null);

  const [value, setValue] = React.useState(eValue.value ?? '');

  const handleImageDrop = (files: File[]) => {
    //console.log(files);
    if (files.length == 0) {
      console.log('no files submitted');
      return;
    }
    const f = files[0];
    const type = f.type;
    if (!(type === 'image/png')) {
      console.log('file type wrong; was -' + type + '-');
      return;
    }
    networkCreateImage(f);
  };

  const networkCreateImage = async (img: File): Promise<void> => {
    let downloadUrl = await uploadFunction(img);
    setValue(downloadUrl);
    eValue.value = downloadUrl;
  };
  const clearValue = () => {
    setValue('');
    eValue.value = '';
  };

  const [
    openImageDelete,
    handleInitImageDelete,
    handleCancelImageDelete,
    handleConfirmImageDelete,
  ] = useConfirmDialog(clearValue);

  return (
    <Box display='block' alignItems='baseline'>
      <FlexRow display='block'>
        <Typography sx={nested ? { color: grey[500] } : { mr: 1 }} display='inline-block'>
          {eValue.label}
        </Typography>
        <Dropzone onDrop={acceptedFiles => handleImageDrop(acceptedFiles)}>
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()} style={{ height: '100%', display: 'inline-block' }}>
              <input {...getInputProps()} />
              <p
                style={{
                  width: '100%',
                  margin: 'auto',
                  textAlign: 'center',
                  verticalAlign: 'top',
                }}
              >
                Select or Drop Files
              </p>
            </div>
          )}
        </Dropzone>
      </FlexRow>
      <FlexRow>
        <TextField
          fullWidth={true}
          size={'small'}
          inputRef={inputRef}
          value={value}
          disabled={true}
          style={{
            display: 'inline-block',
          }}
          InputProps={{
            inputProps: {
              style: { textAlign: 'right' },
            },
          }}
          sx={{
            p: 1,
          }}
        />
        {canDelete && <Button onClick={handleInitImageDelete}>X</Button>}
        <ConfirmationDialog
          open={openImageDelete}
          onClose={handleCancelImageDelete}
          onConfirm={handleConfirmImageDelete}
          title={'Delete Image From Orientation?'}
          message={'Please confirm'}
        />
      </FlexRow>
    </Box>
  );
});
