import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { AsyncButton } from 'common/components/AsyncButton';
import React from 'react';

export interface ConfirmationDialogCoreProps {
  onClose: (event: React.MouseEvent<HTMLElement>) => void;
  onConfirm: (() => void) | (() => Promise<void>);
  open: boolean;
  title: string;
  message: string;
  confirmLabel?: string;
  cancelLabel?: string;
  keepMounted?: boolean;
  classes?: Record<'paper', string>;
}

// const useStyles = makeStyles((theme: Theme) =>
//   createStyles({
//     root: {
//       width: '100%',
//       maxWidth: 360,
//       backgroundColor: theme.palette.background.paper,
//     },
//     paper: {
//       width: '80%',
//       maxHeight: 435,
//     },
//   }),
// );

export function ConfirmationDialog(props: ConfirmationDialogCoreProps) {
  const { onClose, onConfirm, open, title, message, confirmLabel, cancelLabel, ...other } = props;

  async function handleOk(evt) {
    evt.preventDefault();
    await onConfirm();
  }

  return (
    <Dialog
      maxWidth='xs'
      aria-labelledby='confirmation-dialog'
      open={open}
      onClose={onClose}
      {...other}
    >
      <DialogTitle id='confirmation-dialog'>{title}</DialogTitle>
      <DialogContent>
        <Typography>{message}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{cancelLabel || 'Cancel'}</Button>
        <AsyncButton variant='contained' color='primary' onClick={handleOk}>
          {confirmLabel || 'OK'}
        </AsyncButton>
      </DialogActions>
    </Dialog>
  );
}
