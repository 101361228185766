import { DeleteForever } from '@mui/icons-material';
import LockIcon from '@mui/icons-material/Lock';
import UnlockIcon from '@mui/icons-material/LockOpen';
import {
  Avatar,
  IconButton,
  Tab,
  Tabs,
} from '@mui/material';
import { Filler } from 'common/components/Filler';
import { FlexRow } from 'common/FlexRow';
import { SectionHeader } from 'components/SectionHeader';
import { useAssets } from 'components/useAssets';
import { useFabric } from 'components/useFabric';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { ImageIds } from 'stores/Image';
import { PrimitiveIds } from 'stores/Primitive';
import { FlexColumn } from '../../common/FlexColumn';
import { OrientationKey } from '../../stores/Orientation';
import { AddOrientationImages } from './AddOrientationImages';
import { ImagePanel } from './ImagePanel';

type OrientationPanelProps = {
  assetId: string;
  orientationKey: OrientationKey;
  onDeleteOri: (orientationKey: OrientationKey) => void;
  onDeleteImage: (ids: ImageIds) => void;
  onDeletePrim: (ids: PrimitiveIds) => void;
};

export const OrientationPanel = observer((props: OrientationPanelProps) => {
  const { assetId, orientationKey, onDeleteOri, onDeleteImage, onDeletePrim } = props;
  const assets = useAssets();

  const asset = assets.getAsset(assetId);
  const orientation = assets.getOrientation(assetId, orientationKey);
  const fabStore = useFabric();

  if (!asset || !orientation) {
    return null;
  }

  function handleLockAll(state: boolean) {
    fabStore.getAllImages().forEach(image => {
      image.fabricObject.selectable = !state;
    });

    if(state === true)
      fabStore.getCanvas().discardActiveObject().renderAll();
  };

  return (
    <>
      <SectionHeader secondary label={`orientation ${props.orientationKey}`}>
        <Filler />
        <IconButton
          onClick={() => {
            onDeleteOri(orientationKey);
          }}
        >
          <DeleteForever />
        </IconButton>
      </SectionHeader>
      <SectionHeader label={'Images'} />
      <SectionHeader>
        <FlexRow width='700px'>
          <Tabs
            variant='scrollable'
            value={orientation.currentImage}
            onChange={(ev, value) => {
              orientation.selectImage(value);
              orientation.selectToFabric();
            }}
            sx={{ borderRight: 1, borderColor: 'divider' }}
            scrollButtons='auto'
          >
            {[...orientation.images.keys()].map((images, index) => {
              return <Tab value={images} label={`Image ${index}`} key={images} icon={<Avatar src={orientation.images.get(images).url._value} imgProps={{ crossOrigin: "anonymous" }}/>}/>;
            })}
            {}
          </Tabs>
          <AddOrientationImages orientation={orientation}/>
          <Filler />
          <IconButton onClick={() => handleLockAll(true)}>
            <LockIcon/>
          </IconButton>
          <IconButton onClick={() => handleLockAll(false)}>
            <UnlockIcon/>
          </IconButton>
          {/* <Menu {...menuProps}>
            {PrimitiveTypes.map(val => (
              <MenuItem
                onClick={() => {
                  orientation.createImage();
                  handleClose();
                }}
                key={val}
              >
                {val}
              </MenuItem>
            ))}
          </Menu> */}
        </FlexRow>
      </SectionHeader>
      <FlexColumn fullSize={false} height={'100%'} flexGrow={1}>
        {orientation.currentImage && (
          <ImagePanel
            ids={{ assetId, orientationKey, imageId: orientation.currentImage }}
            onDeleteImage={onDeleteImage}
            onDeletePrim={onDeletePrim}
          />
        )}
      </FlexColumn>
      <div />
    </>
  );
});
