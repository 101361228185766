import * as React from 'react';
import { Button, createStyles, IconButton, TextField, Typography, useTheme } from '@mui/material';

import { useFabric } from './useFabric';
import { useEffect, useRef } from 'react';
import { fabric } from 'fabric';
import { observer } from 'mobx-react-lite';
import { Box } from '@mui/system';
import { AssetPanel } from './assets/AssetPanel';
import { useParams, useHistory } from 'react-router-dom';
import { useAssets } from './useAssets';
import { useConfirmDialog } from 'common/hooks/useConfirmDialog';
import { OrientationKey } from 'stores/Orientation';
import { ConfirmationDialog } from './ConfirmDialog';
import { useRoot } from './RootProvider';
import { useStructures } from './useStructures';
import { StructurePanel } from './assets/StructurePanel';
import { AssetType } from 'stores/RootStore';
import { FlexRow } from 'common/FlexRow';
import { ArrowBack } from '@mui/icons-material';

type StructureEditorPageProps = { type: AssetType };

export const StructureEditorPage = observer((props: StructureEditorPageProps) => {
  const root = useRoot();

  const params = useParams();
  const history = useHistory();
  const store = useStructures();
  const { type } = props;

  var displayText;
  switch (type) {
    case AssetType.Floor:
      displayText = 'floor';
      break;
    case AssetType.Wall:
      displayText = 'wall';
      break;
  }
  const { structureId } = params;
  if (!structureId) {
    history.push('/');
  }
  const structure = store.getStructure(props.type, structureId);
  if (!structure) {
    history.push('/');
  }
  //add floor to canvas?

  return (
    <Box width='100vw' height='120vh' display='flex'>
      <Box height='100%' width='80%' display='flex' flexDirection='column'>
        <FlexRow>
          <IconButton
            onClick={() => {
              history.push(`/${displayText}`);
            }}
          >
            <ArrowBack />
          </IconButton>
          <Typography variant='h3'>{structure.id}</Typography>
        </FlexRow>
        <StructurePanel id={structureId} structureDO={structure} />
      </Box>
    </Box>
  );
});
