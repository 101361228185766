import { FabricObjectBase, FabricObjectCallbacks } from './FabricObjectBase';
import { fabric } from 'fabric';
import { FabricStore2 } from './FabricStore2';
import { IsoShape } from '@sophya/eyekia';
import { Vector2 } from 'vector_math.js';
import { DEFAULT_CIRCLE, FabricCircle } from './FabricCircle';
import { ISOPAL } from 'stores/fabric2/IsoPal';
import { LineData } from './FabricZone';

export type Plane = 'XY' | 'YZ' | 'XZ';

export class FabricPlane extends FabricObjectBase<fabric.Circle> {
  constructor(
    store: FabricStore2,
    fabricObject: fabric.Circle,
    private plane: Plane,
    private schema: IsoShape,
    private color: string,
    callbacks?: FabricObjectCallbacks<FabricPlane>,
  ) {
    super(store, fabricObject, callbacks);

    const center_pre_offset = ISOPAL.pal_to_iso_2d(
      new Vector2(this.fabricObject.left, this.fabricObject.top),
    );
    const center = new Vector2(
      center_pre_offset.x + schema.position.x,
      center_pre_offset.y + schema.position.y,
    );

    var iso_half, pal_half: Vector2;
    switch (plane) {
      case 'XY':
        iso_half = new Vector2(schema.scale.x * 0.5, schema.scale.y * 0.5);
        pal_half = new Vector2(0.0, 0.0);
        break;
      case 'YZ':
        iso_half = new Vector2(0.0, schema.scale.y * 0.5);
        pal_half = new Vector2(0.0, schema.scale.x * 0.5);
        break;
      case 'XZ':
        iso_half = new Vector2(schema.scale.x * 0.5, 0.0);
        pal_half = new Vector2(0.0, schema.scale.y * 0.5);
        break;
      default: {
        throw new Error(
          'Default case reached but should not be reached. Check the plane you are supplying to FabricPlane',
        );
      }
    }
    const start_pal = ISOPAL.iso_to_pal_2d(
      new Vector2(center.x - iso_half.x, center.y - iso_half.y),
    );
    const end_pal = ISOPAL.iso_to_pal_2d(new Vector2(center.x + iso_half.x, center.y + iso_half.y));

    const start = new Vector2(start_pal.x - pal_half.x, start_pal.y - pal_half.y);
    const end = new Vector2(end_pal.x + pal_half.x, end_pal.y + pal_half.y);

    // create start point
    this.start = new FabricObjectBase<fabric.Circle>(
      this.store,
      new fabric.Circle({
        ...DEFAULT_CIRCLE,
        top: start.y,
        left: start.x,
        fill: this.color,
        name: `${this.id}-start`,
      }),
      {
        onMove: this.handleEndpointModify,
        onSelect: this.notifySelect,
        onDeselect: this.notifyDeselect,
      },
    );

    this.children.add(this.start);
    this.childrenToMove.add(this.start);

    // create end point
    this.end = new FabricObjectBase<fabric.Circle>(
      this.store,
      new fabric.Circle({
        ...DEFAULT_CIRCLE,
        top: end.y,
        left: end.x,
        fill: 'green',
        name: `${this.id}-end`,
      }),
      {
        onMove: this.handleEndpointModify,
        onSelect: this.notifySelect,
        onDeselect: this.notifyDeselect,
      },
    );

    this.children.add(this.end);
    this.childrenToMove.add(this.end);

    //redraw the lines
    this.refreshLines();
    this.handleDeselect();
  }

  handleSelect = () => {
    this.enable();
    this.start.enable();
    this.end.enable();
    this.lines.map(val => (val.fabricObject.opacity = 1));
  };

  handleDeselect = () => {
    this.disable();
    this.start.disable();
    this.end.disable();
    this.lines.map(val => (val.fabricObject.opacity = 0.5));
  };

  start: FabricCircle;
  end: FabricCircle;

  lines: FabricObjectBase<fabric.Line>[] = [];

  handleMove = () => {
    this.refreshLines();
  };

  handleEndpointModify = () => {
    this.handlePlaneReverse();
    this.callbacks?.onModify?.(this);
    this.refreshLines();
  };

  handlePlaneReverse = () => {
    switch (this.plane) {
      case 'XZ':
        if (this.start.x > this.end.x) {
          this.plane = 'YZ';
        }
        break;
      case 'YZ':
        if (this.start.x < this.end.x) {
          this.plane = 'XZ';
        }
        break;
      default: {
        // do nothing
      }
    }
  };
  getPlane = () => {
    return this.plane;
  };

  generateLineData = (): LineData[] => {
    const startPal = new Vector2(this.start.x, this.start.y);
    const endPal = new Vector2(this.end.x, this.end.y);
    const startIso = ISOPAL.pal_to_iso_2d(startPal);
    const endIso = ISOPAL.pal_to_iso_2d(endPal);

    var leftPal, rightPal, height;
    switch (this.plane) {
      case 'XY':
        leftPal = ISOPAL.iso_to_pal_2d(new Vector2(startIso.x, endIso.y));
        rightPal = ISOPAL.iso_to_pal_2d(new Vector2(endIso.x, startIso.y));
        break;
      case 'YZ':
        height = this.calculateDimensions().z;

        leftPal = new Vector2(endPal.x, endPal.y - height);
        rightPal = new Vector2(startPal.x, startPal.y + height);
        break;
      case 'XZ':
        height = this.calculateDimensions().z;

        leftPal = new Vector2(endPal.x, endPal.y - height);
        rightPal = new Vector2(startPal.x, startPal.y + height);
        break;
    }

    const lineDatas: LineData[] = [];
    lineDatas.push({ start: leftPal.clone(), end: startPal.clone() });
    lineDatas.push({ start: startPal.clone(), end: rightPal.clone() });
    lineDatas.push({ start: rightPal.clone(), end: endPal.clone() });
    lineDatas.push({ start: endPal.clone(), end: leftPal.clone() });
    return lineDatas;
  };

  calculateDimensions = () => {
    const startPal = new Vector2(this.start.x, this.start.y);
    const endPal = new Vector2(this.end.x, this.end.y);
    const startIso = ISOPAL.pal_to_iso_2d(startPal);
    const endIso = ISOPAL.pal_to_iso_2d(endPal);
    var iso_pivot, pal_pivot: Vector2;
    var iso_diff: number;
    switch (this.plane) {
      case 'XY':
        return {
          x: endIso.x - startIso.x,
          y: endIso.y - startIso.y,
          z: 0.0,
        };
      case 'YZ':
        iso_diff = endIso.x - startIso.x;
        iso_pivot = new Vector2(startIso.x + iso_diff, startIso.y + iso_diff);
        pal_pivot = ISOPAL.iso_to_pal_2d(iso_pivot);
        return {
          x: 0.0,
          y: endIso.y - iso_pivot.y,
          z: Math.abs(startPal.y - pal_pivot.y),
        };
      case 'XZ':
        iso_diff = endIso.y - startIso.y;
        iso_pivot = new Vector2(startIso.x + iso_diff, startIso.y + iso_diff);
        pal_pivot = ISOPAL.iso_to_pal_2d(iso_pivot);
        return {
          x: endIso.x - iso_pivot.x,
          y: 0.0,
          z: Math.abs(startPal.y - pal_pivot.y),
        };
    }
  };

  refreshLines = () => {
    const lineDatas = this.generateLineData();

    if (this.lines.length === 0) {
      for (const line of lineDatas) {
        const fabLine = new FabricObjectBase(
          this.store,
          new fabric.Line([line.start.x, line.start.y, line.end.x, line.end.y], {
            stroke: this.color,
            opacity: 1,
            selectable: false,
            evented: false,
            excludeFromExport: true,
          }),
        );

        this.lines.push(fabLine);
        this.children.add(fabLine);
        this.childrenToMove.add(fabLine);
      }
    } else {
      this.lines.map((line, index) => {
        const lineData = lineDatas[index];
        line.fabricObject.set({
          x1: lineData.start.x,
          y1: lineData.start.y,
          x2: lineData.end.x,
          y2: lineData.end.y,
        });
      });
    }
    // refresh center point
    const startPal = new Vector2(this.start.x, this.start.y);
    const endPal = new Vector2(this.end.x, this.end.y);
    this.x = (startPal.x + endPal.x) * 0.5;
    this.y = (startPal.y + endPal.y) * 0.5;
    this.pastLocation = new Vector2(this.x, this.y);
    this.fabricObject.setCoords();
  };

  toSchema = (relativeTo: Vector2): IsoShape => {
    const startPal = new Vector2(this.start.x, this.start.y).sub(relativeTo);
    const endPal = new Vector2(this.end.x, this.end.y).sub(relativeTo);
    const startIso = ISOPAL.pal_to_iso_2d(startPal);
    const endIso = ISOPAL.pal_to_iso_2d(endPal);

    const centerIso = new Vector2((startIso.x + endIso.x) * 0.5, (startIso.y + endIso.y) * 0.5);

    const dims = this.calculateDimensions();

    var size_x, size_y;
    switch (this.plane) {
      case 'XY':
        size_x = dims.x;
        size_y = dims.y;
        break;
      case 'YZ':
        size_x = dims.z;
        size_y = dims.y;
        break;
      case 'XZ':
        size_x = dims.x;
        size_y = dims.z;
        break;
    }

    return {
      shape: this.schema.shape,
      position: {
        x: centerIso.x,
        y: centerIso.y,
      },
      scale: {
        x: size_x,
        y: size_y,
      },
      rotationRad: 0,
    };
  };
}
