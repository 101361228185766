import SmartSet from 'common/SmartSet';
import { isValidFloat, isValidInt, isValidUrl } from 'common/validationFunctions';
import { action, makeObservable, observable } from 'mobx';
import { IsoShapeSchema, Primitive } from '@sophya/eyekia/lib';
import { DEFAULT_D2_TRANSFORM, EditableD2Transform } from './editor/EditableD2Transform';
import { DEFAULT_ISO_SHAPE, EditableIsoShape } from './editor/EditableIsoShape';
import { EditableFloat, EditableSelect, EditableValue } from './editor/EditableValue';
import { EditableVec2 } from './editor/EditableVec2';
import {
  ColliderType,
  ColliderTypes,
  Facing,
  FacingFront,
  Placement,
} from './fabric2/FabricStore2';
import { OrientationKey } from './Orientation';
import { useAssets } from 'components/useAssets';
import { ConfigInterface } from './RootStore';

export type PrimitiveType = 'seat' | 'shape' | 'frame' | 'shadow';

export const PrimitiveTypes = new SmartSet<PrimitiveType>(['seat', 'shape', 'frame', 'shadow']);

export type PrimitiveIds = {
  assetId: string;
  orientationKey: OrientationKey;
  imageId: string;
  id: string;
};

export abstract class PrimitiveDO {
  constructor(
    public ids: PrimitiveIds,
    public type: PrimitiveType,
    private onSave: (ids: PrimitiveIds, data: Primitive) => void,
  ) {
    //
  }

  abstract updateFromSchema(schema: Primitive);

  abstract toSchema(): Primitive;

  save = () => {
    this.onSave(this.ids, this.toSchema());
  };
}

export type ShadowType = 'rect' | 'ellipse';
export const ShadowTypes: ShadowType[] = ['rect', 'ellipse'];
export class ShadowPrimitiveDO extends PrimitiveDO {
  constructor(ids: PrimitiveIds, onSave: (ids: PrimitiveIds, data: Primitive) => void) {
    super(ids, 'shadow', onSave);

    makeObservable(this, {
      shape: observable,
      shadowType: observable,
      updateFromSchema: action,
    });
  }

  public shape = new EditableIsoShape('shape', DEFAULT_ISO_SHAPE, this.save);
  public shadowType = new EditableSelect<ShadowType>('shadow type', 'rect', this.save, ShadowTypes);

  updateFromSchema = (schema: Primitive) => {
    if (schema.type === 'shadow') {
      this.shape = new EditableIsoShape('shape', schema.shape, this.save);
      this.shadowType = new EditableSelect<ShadowType>(
        'shadow type',
        schema.shadowType as ShadowType,
        this.save,
        ShadowTypes,
      );
    }
  };

  toSchema(): Primitive {
    return {
      type: 'shadow',
      shadowType: this.shadowType.value,
      shape: this.shape.toSchema(),
    };
  }
}

export class SeatPrimitiveDO extends PrimitiveDO {
  constructor(
    ids: PrimitiveIds,
    onSave: (ids: PrimitiveIds, data: Primitive) => void,
    defaultFacing: Facing = 'N',
  ) {
    super(ids, 'seat', onSave);

    makeObservable(this, {
      shape: observable,
      facing: observable,
      transform: observable,
      updateFromSchema: action,
    });

    this.facing = new EditableSelect<Facing>('facing', defaultFacing, this.save, [
      'N',
      'E',
      'W',
      'S',
    ]);
  }

  public transform = new EditableD2Transform('transform', DEFAULT_D2_TRANSFORM, this.save);
  public shape = new EditableIsoShape('shape', DEFAULT_ISO_SHAPE, this.save);
  public facing: EditableSelect<Facing>;

  updateFromSchema = (schema: Primitive) => {
    //('update from schema', schema, this);
    if (schema.type === 'seat') {
      this.transform = new EditableD2Transform('transform', schema.transform, this.save);
      this.shape = new EditableIsoShape('shape', schema.shape, this.save);
      this.facing = new EditableSelect<Facing>('facing', schema.facingDir as Facing, this.save, [
        'N',
        'E',
        'W',
        'S',
      ]);
    }
  };

  toSchema(): Primitive {
    return {
      type: 'seat',
      shape: this.shape.toSchema(),
      transform: this.transform.toSchema(),
      facingDir: this.facing.value,
      shapeIntersectionType: 'sensor',
    };
  }
}
export class FramePrimitiveDO extends PrimitiveDO {
  public shape: EditableIsoShape;
  public facing: EditableSelect<FacingFront>;
  public placement: EditableSelect<Placement>;
  public frameId: EditableSelect<string>;
  public validFrames: Array<string> = [];

  constructor(
    private config: ConfigInterface,
    ids: PrimitiveIds,
    onSave: (ids: PrimitiveIds, data: Primitive) => void,
  ) {
    super(ids, 'frame', onSave);

    makeObservable(this, {
      shape: observable,
      facing: observable,
      placement: observable,
      frameId: observable,
      updateFromSchema: action,
    });

    this.updateFrameList(config.getFrameList());
    this.shape = new EditableIsoShape('shape', DEFAULT_ISO_SHAPE, this.save);
    this.facing = new EditableSelect<FacingFront>('facing', 'E', this.save, ['E', 'S']);
    this.placement = new EditableSelect<Placement>('placement', 'wall', this.save, [
      'wall',
      'floor',
    ]);
    this.frameId = new EditableSelect<string>(
      'frame id',
      this.validFrames[0],
      this.save,
      this.validFrames,
    );
  }

  private updateFrameList = (list: Array<string>) => {
    this.validFrames = list;
  };

  updateFromSchema = (schema: Primitive) => {
    // console.log('update from schema', schema, this);
    if (schema.type === 'frame') {
      this.shape = new EditableIsoShape('shape', schema.shape, this.save);
      this.facing = new EditableSelect<FacingFront>(
        'facing',
        schema.facingDir as FacingFront,
        this.save,
        ['E', 'S'],
      );
      this.placement = new EditableSelect<Placement>(
        'placement',
        schema.placement as Placement,
        this.save,
        ['wall', 'floor'],
      );

      this.frameId = new EditableSelect<string>(
        'frame id',
        schema.frameId,
        this.save,
        this.config.getFrameList,
      );
      /*
      this.config.updateFrameSize(schema.frameId, {
        x: schema.shape.scale.x,
        y: schema.shape.scale.y,
      });
      */
    }
  };

  toSchema(): Primitive {
    return {
      type: 'frame',
      shape: this.shape.toSchema(),
      facingDir: this.facing.value,
      placement: this.placement.value,
      frameId: this.frameId.value,
    };
  }
}

export class ShapePrimitiveDO extends PrimitiveDO {
  constructor(ids: PrimitiveIds, onSave: (ids: PrimitiveIds, data: Primitive) => void) {
    super(ids, 'shape', onSave);

    makeObservable(this, {
      shape: observable,
      colliderType: observable,
      updateFromSchema: action,
    });
  }

  public shape = new EditableIsoShape('shape', DEFAULT_ISO_SHAPE, this.save);
  public height = new EditableFloat('height', String(100.0), this.save);
  public colliderType = new EditableSelect<ColliderType>('is sensor', 'collider', this.save, [
    'collider',
    'selector',
  ]);

  updateFromSchema = (schema: Primitive) => {
    //console.log('update from schema', schema, this);
    if (schema.type === 'shape') {
      this.shape = new EditableIsoShape('shape', schema.shape, this.save);
      this.height = new EditableFloat('height', String(schema.height), this.save);
      this.colliderType = new EditableSelect<ColliderType>(
        'is sensor',
        schema.shapeIntersectionType as ColliderType,
        this.save,
        ['collider', 'selector'],
      );
    }
  };

  toSchema(): Primitive {
    return {
      type: 'shape',
      height: Number(this.height.value),
      shape: this.shape.toSchema(),
      shapeIntersectionType: this.colliderType.value,
    };
  }
}
