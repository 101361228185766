import { client } from 'clients/eyekia';
import { generateHexCodeMeanImage } from 'common/ColorUtils';
import { uploadImageToAsset } from 'common/uploadImageToAsset';
import { recolorUrls } from 'common/VariationRenderer';
import debounce from 'lodash.debounce';
import { action, makeObservable, observable, runInAction } from 'mobx';
import { OrientationStructureKey, Structure, ColorVariation, StructureImage } from '@sophya/eyekia';
import { BaseStore } from './BaseStore';
import { ColorPresetStore } from './editor/ColorPresetStore';
import { EditableValue } from './editor/EditableValue';
import { EditableVec2 } from './editor/EditableVec2';
import { AssetType } from './RootStore';
import { StructureStore } from './StructureStore';
import { StructureImageDO } from './StructureImage';

const structureOrientations: OrientationStructureKey[] = ['N', 'E', 'S', 'W'];
type StructureUpdateResponse = {
  data: Structure;
};

export class StructureDO extends BaseStore {
  public orientations: Map<OrientationStructureKey, StructureImageDO> = new Map();
  firstOrientation: OrientationStructureKey;

  public name: EditableValue<string>;
  public description: EditableValue<string>;

  public height: EditableValue<string>;
  public pixelDensity: EditableValue<string>;

  public isCorner: boolean;

  public colorPresets: ColorPresetStore;
  public currentColor: ColorVariation;

  accessKey: EditableValue<string>;

  published: EditableValue<boolean>;
  modifiedSinceLastPublish: boolean = false;

  constructor(public id: string, private store: StructureStore, private type: AssetType) {
    super();
    makeObservable(this, {
      name: observable,
      modifiedSinceLastPublish: observable,
      updateDisplayUrls: action,
    });
    this.currentColor = {
      hueRotationDegrees: 0,
      saturationMultiplier: 1.0,
      valueMultiplier: 1.0,
    };
    this.colorPresets = new ColorPresetStore(this.setColorVariation, this.handleAssetChange);
  }

  previewUrl = (): string => {
    if (this.orientations && this.firstOrientation) {
      const north: StructureImageDO = this.orientations['N'];
      if (north) {
        return north.url.value;
      }
      const entry: StructureImageDO = this.orientations[this.firstOrientation];
      return entry.url.value;
    }
    return null;
  };

  getOrientations = (): StructureImageDO[] => {
    var array: StructureImageDO[] = [];
    structureOrientations.forEach(ori => {
      const entry = this.orientations[ori];
      if (entry) {
        array.push(entry);
      }
    });
    return array;
  };

  public setBaseColor = (image: HTMLImageElement) => {
    const color = generateHexCodeMeanImage(image);
    this.colorPresets.setBaseColor(color);
  };

  networkUpdateAsset = async (): Promise<void> => {
    let data: StructureUpdateResponse;
    switch (this.type) {
      case AssetType.Wall:
        //@ts-ignore
        data = await client().v1.wallUpdate(this.id, { data: this.toSchema() });
        break;
      case AssetType.Floor:
        //@ts-ignore
        data = await client().v1.floorUpdate(this.id, { data: this.toSchema() });
        break;
      default:
        console.error('Unexpected asset type in Structure');
        break;
    }
    if (data) {
      this.modifiedSinceLastPublish = data.data.modifiedSinceLastPublish;
    }
  };

  save = debounce(this.networkUpdateAsset, 1000);

  updateDisplayUrls = async () => {
    structureOrientations.forEach(key => {
      const image = this.orientations[key];
      if (image) {
        image.recolor(this.currentColor);
      }
    });
  };

  updateColorVariation = (rotation: number, saturation: number, value: number) => {
    this.currentColor = {
      hueRotationDegrees: rotation,
      saturationMultiplier: saturation,
      valueMultiplier: value,
    };
    this.updateDisplayUrls();
  };

  public setColorVariation = debounce(this.updateColorVariation, 1000);

  private loading = false;
  private handleAssetChange = (): Promise<void> => {
    if (!this.loading) {
      return this.save();
    }
  };

  uploadImage = async (img: File | Blob) => {
    return await uploadImageToAsset(this.id, this.type, img);
  };

  public toggleCorner = () => {
    this.isCorner = !this.isCorner;
    this.save();
  };

  public isWall = () => {
    return this.type === AssetType.Wall;
  };

  updateFromSchema(structure: Structure) {
    this.loading = true;

    this.firstOrientation = undefined;
    structureOrientations.forEach(key => {
      this.orientations[key] = new StructureImageDO(key, this.handleAssetChange, this.uploadImage);
      if (structure.orientations[key]) {
        this.firstOrientation = key;
        this.orientations[key].updateFromSchema(structure.orientations[key]);
      }
    });

    if (this.type === AssetType.Wall) {
      this.isCorner = structure.isCorner;
    }
    //this.updateDisplayUrls();

    this.colorPresets.updateFromSchema(structure.colors);
    this.name = new EditableValue<string>('name', structure.name, this.handleAssetChange);

    this.description = new EditableValue<string>(
      'description',
      structure.description,
      this.handleAssetChange,
    );
    this.accessKey = new EditableValue<string>(
      'Access Key',
      structure.accessKey,
      this.handleAssetChange,
    );

    this.height = new EditableValue<string>(
      'height',
      String(structure.height),
      this.handleAssetChange,
    );
    this.pixelDensity = new EditableValue<string>(
      'Pixel Density',
      String(structure.pixelDensity),
      this.handleAssetChange,
    );

    //this.thumbnail = floor.thumbnail;
    this.published = new EditableValue<boolean>(
      'Published',
      structure.published ?? false,
      this.handleAssetChange,
    );
    this.modifiedSinceLastPublish = structure.modifiedSinceLastPublish;
    this.loading = false;
  }

  toSchema = (): Structure => {
    const orientations = {};
    structureOrientations.forEach(key => {
      if (this.orientations[key] && this.orientations[key].hasURL()) {
        orientations[key] = this.orientations[key].toSchema();
      }
    });
    let isCorner: boolean;
    let structureType: 'wall' | 'floor';
    switch (this.type) {
      case AssetType.Wall:
        structureType = 'wall';
        isCorner = this.isCorner;
        break;
      case AssetType.Floor:
        structureType = 'floor';
        break;
      default:
        structureType = 'floor';
        break;
    }
    return {
      orientations,
      type: structureType,
      height: this.height.value,
      pixelDensity: Number(this.pixelDensity.value),
      isCorner: isCorner,
      colors: this.colorPresets.toSchema(),
      name: this.name.value,
      description: this.description.value,
      published: this.published.value ?? false,
      modifiedSinceLastPublish: this.modifiedSinceLastPublish,
      accessKey: this.accessKey.value,
    };
  };
  static dataURLtoBlob(dataurl: string) {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  }

  publishAsset = async () => {
    let res;
    switch (this.type) {
      case AssetType.Wall:
        res = await client().v1.wallPublishCreate(this.id, {});

        break;
      case AssetType.Floor:
        res = await client().v1.floorPublishCreate(this.id, {});
        break;
    }

    runInAction(() => {
      this.published.value = res.data.data.published;
      this.modifiedSinceLastPublish = res.data.data.modifiedSinceLastPublish;
    });
    console.log(AssetType[this.type] + " published successfully: ", this.name.value || this.id);
  };

  handleDispose = () => {};
}
