import {
  Checkbox,
} from '@mui/material';
import { Box } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { FlexColumn } from '../../common/FlexColumn';
import { useHistory } from 'react-router-dom';
import { FlexRow } from 'common/FlexRow';
import { ValueEditor } from 'components/editor/ValueEditor';
import { SelectEditor } from 'components/editor/SelectEditor';
import { PublishSection } from 'components/PublishButton';
import { useRoot } from 'components/RootProvider';
import { Structure } from '@sophya/eyekia';
import { useStructures } from 'components/useStructures';
import { ImageUrlEditor } from 'components/editor/ImageUrlEditor';
import { AssetType } from 'stores/RootStore';
import { ColorPresetPanel } from './ColorPresetPanel';
import { StructureDO } from 'stores/Structure';
import { Vec2Editor } from 'components/editor/Vec2Editor';
import { StructureImagePanel } from './StructureImagePanel';

type StructurePanelProps = {
  id: string;
  structureDO: StructureDO;
};
export const StructurePanel = observer((props: StructurePanelProps) => {
  const floors = useStructures().floors;
  const root = useRoot();
  const { structureDO } = props;
  const history = useHistory();

  return (
    <>
      <FlexRow>
        <FlexColumn width='100%'>
          <img
            src={structureDO.previewUrl()}
            hidden={true}
            crossOrigin='anonymous'
            onLoad={ev => {
              structureDO.setBaseColor(ev.currentTarget);
            }}
          ></img>
          <ValueEditor value={structureDO.name} />
          <ValueEditor value={structureDO.description} />
          <ValueEditor value={structureDO.accessKey} />
          <ValueEditor value={structureDO.height} />
          <ValueEditor value={structureDO.pixelDensity} />
          {structureDO.isWall() && (
            <Box>
              is a Corner Piece?
              <Checkbox
                checked={structureDO.isCorner}
                onChange={() => structureDO.toggleCorner()}
              />
            </Box>
          )}
          <ColorPresetPanel store={structureDO.colorPresets}></ColorPresetPanel>
          <FlexRow>
            {structureDO.getOrientations().map((image) => {
              return (
                <StructureImagePanel key={image.orientation} image={image}/>
              );
            })}
          </FlexRow>
        </FlexColumn>
      </FlexRow>
      <PublishSection asset={structureDO} />
    </>
  );
});
