import { ArrowBack } from '@mui/icons-material';
import { Button, CircularProgress, IconButton, Tab, Tabs, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { FlexRow } from 'common/FlexRow';
import { useConfirmDialog } from 'common/hooks/useConfirmDialog';
import { ConfirmationDialog } from 'components/ConfirmDialog';
import { useRoot } from 'components/RootProvider';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useAssets } from '../useAssets';
import { AssetPreview } from './AssetPreview';
import { useHistory } from 'react-router-dom';
import { NewAssetButton } from './NewAssetButton';
import { AsyncIconButton } from 'common/components/AsyncIconButton';

type AssetListProps = {};
export const AssetList = observer((props: AssetListProps) => {
  const root = useRoot();
  const { collator } = root;
  const assets = useAssets();

  const history = useHistory();

  const deleteAsset = (assetId: string) => {
    assets.deleteAsset(assetId);
  };

  const [open, handleInit, handleCancel, handleConfirm] = useConfirmDialog(deleteAsset);

  const categoryAssetIds = assets.assetsByCategory.get(assets.currentCategoryId);

  const [openPub, handleInitPub, handleCancelPub, handleConfirmPub] = useConfirmDialog(async () => {
    return await assets.handlePublishAll();
  });

  return (
    <Box width='100%' height='100%' display='flex' flexDirection='column'>
      <FlexRow>
        <IconButton
          onClick={() => {
            history.push('/');
          }}
        >
          <ArrowBack />
        </IconButton>
        <Typography variant='h2'>Assets</Typography>
      </FlexRow>
      <FlexRow>
        <Typography variant='h4'>{'Press button to create moar ->'}</Typography>
        <NewAssetButton></NewAssetButton>
        <Button variant='contained' color='primary' onClick={handleInitPub}>
          Publish All
        </Button>
        <ConfirmationDialog
          open={openPub}
          onClose={handleCancelPub}
          onConfirm={handleConfirmPub}
          title={'PUBLISH all assets of this category?'}
          message={
            'Publishing these assets will make them available for people to use in the map maker. Do not do this until youve tested them in the web app! Please be careful! NOTE: this will take some time. Check the console for progress feedback.'
          }
        />

      </FlexRow>
      {root.categories.refreshing ? (
        <CircularProgress variant='indeterminate'></CircularProgress>
      ) : (
        <Tabs
          variant='scrollable'
          onChange={(ev, value) => {
            assets.handleLoadAssets(value);
          }}
          value={assets.currentCategoryId}
        >
          {root.categories.categories.size > 0 &&
            [...root.categories.categories.entries()]
              .sort((a, b) => collator.compare(a[1].name.value, b[1].name.value))
              .map(([id, data]) => {
                return <Tab key={id} value={id ?? 'null'} label={data.name.value} />;
              })}
        </Tabs>
      )}
      <FlexRow sx={{ flexWrap: 'wrap' }}>
        {assets.loadingAssets ? (
          <CircularProgress variant='indeterminate' />
        ) : (
          categoryAssetIds
            ?.slice()
            .map(id => <AssetPreview id={id} key={id} onDelete={handleInit}></AssetPreview>)
        )}
      </FlexRow>
      <ConfirmationDialog
        open={open}
        onClose={handleCancel}
        onConfirm={handleConfirm}
        title={'Delete asset?'}
        message={'Please confirm'}
      />
    </Box>
  );
});
