import { IsoShape } from '@sophya/eyekia';
import { EditableFloat, EditableValue } from './EditableValue';
import { EditableVec2 } from './EditableVec2';

export const DEFAULT_ISO_SHAPE: IsoShape = {
  position: {
    x: 0,
    y: 0,
  },
  scale: {
    x: 100,
    y: 100,
  },
  rotationRad: 0,
  shape: 'rect',
};

export class EditableIsoShape {
  changeCallback: (val: IsoShape) => void;
  constructor(
    public label: string,
    initialValue: IsoShape,
    private onSubmit: (data: IsoShape) => void,
  ) {
    this.position = new EditableVec2('position', initialValue.position, this.handleSubmit);

    this.scale = new EditableVec2('scale', initialValue.scale, this.handleSubmit);

    this.rotation = new EditableFloat(
      'rotation',
      String(initialValue.rotationRad),
      this.handleSubmit,
    );
    this.changeCallback = onSubmit;
  }

  private handleSubmit = () => {
    this.changeCallback(this.toSchema());
  };

  public toSchema = (): IsoShape => {
    return {
      position: this.position.toSchema(),
      scale: this.scale.toSchema(),
      rotationRad: Number(this.rotation.value),
      shape: 'rect',
    };
  };

  position: EditableVec2;
  scale: EditableVec2 | undefined;
  rotation: EditableValue<string> | undefined;
}
