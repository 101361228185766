import { Divider, Typography } from '@mui/material';
import { CollapsableSection } from 'common/components/CollapsableSection';
import { FlexColumn } from 'common/FlexColumn';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { EditableD2Transform } from 'stores/editor/EditableD2Transform';
import { ValueEditor } from './ValueEditor';
import { Vec2Editor } from './Vec2Editor';

type D2TransformEditorProps = { value: EditableD2Transform };
export const D2TransformEditor = observer((props: D2TransformEditorProps) => {
  const { value } = props;
  return (
    <FlexColumn>
      <CollapsableSection label={value.label}>
        <Vec2Editor value={value.position} />
        {value.rotation && <ValueEditor value={value.rotation} />}
        {value.scale && <Vec2Editor value={value.scale} />}
        <Vec2Editor value={value.frontPoint} />
        <ValueEditor value={value.depthBand} />
      </CollapsableSection>
    </FlexColumn>
  );
});
