import { Box, Menu, MenuItem, TextField, TextFieldProps, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { useMenu } from 'common/hooks/useMenu';
import { useTextField, useUrlField } from 'common/hooks/useTextField';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { EditableSelect, EditableValue } from 'stores/editor/EditableValue';
import { ColliderType, Facing } from 'stores/fabric2/FabricStore2';

type SelectEditorProps<T> = {
  value: EditableSelect<T>;
  getDisplay?: (value: T) => string;
  autoFocus?: boolean;
  nested?: boolean;
  small?: boolean;
};

const SelectEditorCore = <T extends any>(props: SelectEditorProps<T>) => {
  const { value: eValue, autoFocus, getDisplay, nested, small } = props;
  const inputRef = React.useRef<HTMLInputElement>(null);

  const [value, setValue] = React.useState(eValue.value ?? '');

  const onBlur = e => {
    console.debug('onBlur called', value);
    eValue.value = value as T;
  };

  const onChange = e => {
    setValue(e.target.value);
  };
  const hasError = Boolean(eValue.error);
  const errorProps: TextFieldProps = hasError ? { error: true, helperText: eValue.error } : null;

  const [handleOpen, handleClose, menuProps] = useMenu();

  React.useEffect(() => {
    setValue(eValue.value);
  }, [eValue.key]);

  const smallProps = small
    ? { variant: 'outlined' as const, label: eValue.label }
    : { variant: 'standard' as const };

  return (
    <Box display='flex' alignItems='baseline'>
      {!small && (
        <Typography sx={nested ? { color: grey[500] } : { width: '100px', mr: 1 }}>
          {eValue.label}
        </Typography>
      )}
      <TextField
        size={'small'}
        {...smallProps}
        inputRef={inputRef}
        value={getDisplay ? getDisplay(value as T) : value}
        onChange={onChange}
        onBlur={onBlur}
        {...errorProps}
        autoFocus={autoFocus}
        onKeyDown={ev => {
          console.debug(ev.key);
          if (ev.key === 'Enter' || ev.key === 'Escape') {
            inputRef.current?.blur();
          }
        }}
        sx={{
          p: 1,
        }}
        onClick={handleOpen}
      ></TextField>
      <Menu {...menuProps}>
        {props.value.getOptions().map(val => (
          <MenuItem
            onClick={() => {
              setValue(val);
              handleClose();
            }}
            key={String(val)}
          >
            {getDisplay ? getDisplay(val) : String(val)}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export const SelectEditor = observer(SelectEditorCore);
