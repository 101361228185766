import { Box } from '@mui/system';
import * as React from 'react';

type ColoredDotProps = { color: string };
export const ColoredDot = (props: ColoredDotProps) => {
  const { color } = props;
  return (
    <Box
      sx={{
        position: 'absolute',
        left: '-30px',
        top: 'calc(50% - 10px)',
        borderRadius: '8px',
        width: '16px',
        height: '16px',
        backgroundColor: color,
      }}
    />
  );
};
