import { CollapsableSection } from 'common/components/CollapsableSection';
import { SelectEditor } from 'components/editor/SelectEditor';
import { ValueEditor } from 'components/editor/ValueEditor';
import { Vec2Editor } from 'components/editor/Vec2Editor';
import { useAssets } from 'components/useAssets';
import { observer } from 'mobx-react-lite';
import { FrameDO } from 'stores/FrameDO';

type FramePanelProps = {
  frameId: string;
  frame: FrameDO;
  onDelete: (frameId: string) => void;
};
export const FramePanel = observer((props: FramePanelProps) => {
  const { frame, frameId, onDelete } = props;

  return (
    <CollapsableSection label={frameId} onDelete={() => onDelete(frameId)} closed>
      <ValueEditor value={frame.name} />
      <SelectEditor value={frame.keepAspect} />
      <SelectEditor value={frame.hasPlaceholder} />
      <Vec2Editor value={frame.size} />
    </CollapsableSection>
  );
});
