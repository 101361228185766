import firebase from 'firebase/app';

// Initialize Firebase
let env = process.env;

const config = {
  apiKey: env.REACT_APP_FIREBASE_APIKEY, //"",
  authDomain: env.REACT_APP_FIREBASE_AUTHDOMAIN, // "staging-app.sophya.ai",
  databaseURL: env.REACT_APP_FIREBASE_DATABASEURL, //"https://sage-studio-staging.firebaseio.com",
  projectId: env.REACT_APP_FIREBASE_PROJECTID, // "sage-studio-staging",
  storageBucket: env.REACT_APP_FIREBASE_STORAGEBUCKET, //"sage-studio-staging.appspot.com",
  messagingSenderId: env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
};

export const app = firebase.initializeApp(config);

export default firebase;
