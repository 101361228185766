import { Matrix2, Vector2 } from 'vector_math.js';

class IsoPal {
  constructor() {
    const x_angle = 360.0 - 26.565;
    const y_angle = 180.0 + 26.565;

    const x_rad = x_angle * (Math.PI / 180);
    const y_rad = y_angle * (Math.PI / 180);

    const x_cos = Math.cos(x_rad);
    const x_sin = Math.sin(x_rad);
    const y_cos = Math.cos(y_rad);
    const y_sin = Math.sin(y_rad);

    this.ISO_TO_PAL_MATRIX_2D = new Matrix2(x_cos, x_sin, y_cos, y_sin);
    const y_flip = new Matrix2(1, 0, 0, -1);
    const quart_back_rot = new Matrix2(0, -1, 1, 0);
    this.ISO_TO_PAL_MATRIX_2D = this.ISO_TO_PAL_MATRIX_2D.mult(quart_back_rot.mult(y_flip));
    this.PAL_TO_ISO_MATRIX_2D = Matrix2.copy(this.ISO_TO_PAL_MATRIX_2D);
    this.PAL_TO_ISO_MATRIX_2D.invert();
  }

  ISO_TO_PAL_MATRIX_2D: Matrix2;

  PAL_TO_ISO_MATRIX_2D: Matrix2;

  pal_to_iso_2d(input: Vector2): Vector2 {
    return this.PAL_TO_ISO_MATRIX_2D.transform(new Vector2(input.x, input.y));
  }

  iso_to_pal_2d(input: Vector2): Vector2 {
    return this.ISO_TO_PAL_MATRIX_2D.transform(new Vector2(input.x, input.y));
  }
}

export const ISOPAL = new IsoPal();
