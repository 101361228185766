import {
  Asset,
  Image,
  Orientation,
  StructureType,
  Structure,
  StructureOrientation,
} from '@sophya/eyekia';
import { client } from 'clients/eyekia';
import { AutoId } from 'common/AutoId';
import { imageDimensions } from 'common/getImageDimensions';
import { uploadImageToAsset } from 'common/uploadImageToAsset';
import iterate from 'iterare';
import { makeObservable, observable } from 'mobx';
import { BaseStore } from 'stores/BaseStore';
import { EditableSelect, EditableValue } from 'stores/editor/EditableValue';
import { OrientationKey, isOriKey } from 'stores/Orientation';
import { AssetType } from 'stores/RootStore';
import { fileNameToOriKey } from './CreationJob';

const numberToOriKey: OrientationKey[] = ['N', 'NE', 'E', 'SE', 'S', 'SW', 'W', 'NW'];

type UploadValues = {
  url: string;
  file: File;
  dims: {
    width: number;
    height: number;
  };
};

export class StructureCreationJob extends BaseStore {
  constructor(
    private files: File[],
    initialName: string,
    height: string,
    public type: StructureType,
    public folder: string,
    private isCorner?: boolean,
  ) {
    super();
    makeObservable(this, {
      status: observable,
    });
    if (type === 'wall') {
      this.creatorType = AssetType.Wall;
    } else {
      this.creatorType = AssetType.Floor;
    }
    this.name = new EditableValue<string>('name', initialName, () => {});
    this.height = new EditableValue<string>('height', height, () => {});
    this.countDirections();
  }

  creatorType: AssetType;
  key: string = AutoId.newId();

  structureId: string = null;

  numDirections: number = 0;

  directions: Set<OrientationKey>;

  name: EditableValue<string>;
  height: EditableValue<string>;

  status: 'init' | 'started' | 'finished' = 'init';

  private countDirections() {
    const directions = new Set<OrientationKey>();
    for (const file of this.files) {
      const oriKey = fileNameToOriKey(file.name);
      if (oriKey) {
        directions.add(oriKey);
      }
    }
    this.numDirections = directions.size;
    this.directions = directions;
  }

  startJob = async () => {
    if (this.status !== 'init') {
      return;
    }

    this.status = 'started';
    const eyekia = client();

    let assetResponse;
    switch (this.type) {
      case 'floor':
        assetResponse = await eyekia.v1.floorCreate({});
        this.structureId = assetResponse.data.floorId;
        break;
      case 'wall':
        assetResponse = await eyekia.v1.wallCreate({});
        this.structureId = assetResponse.data.wallId;
        break;
      default:
        return;
    }

    const filesByOrientation = new Map<OrientationKey, File>();

    for (const file of this.files) {
      const oriKey = fileNameToOriKey(file.name);
      if (oriKey) {
        filesByOrientation.set(oriKey, file);
      }
    }

    const allOrientations = await Promise.all(
      iterate(filesByOrientation.entries())
        .map(async ([ori, file]) => {
          //
          const urlPromise = uploadImageToAsset(this.structureId, this.creatorType, file);
          const dimensionsPromise = imageDimensions(file);
          const res = await Promise.all([urlPromise, dimensionsPromise]);
          return {
            ori,
            url: res[0],
            dims: res[1],
            file,
          };
        })
        .toArray(),
    );

    const orientations: StructureOrientation = {};

    for (const data of allOrientations) {
      const width = data.dims.width;
      let pivotPosition= {
        x: width,
        y: 67,
      };
      if(data.ori in ['N', 'E']){
        pivotPosition.x = 0;
      }

      orientations[data.ori] = {
        url:data.url,
        pivotPosition,
      };
    }

    

    const structure: Structure = {
      name: this.name.value,
      description: '',
      height: this.height.value,
      orientations,
      type: this.type,
      
      pixelDensity: 211,
      isCorner: this.isCorner,
    };
    console.log(structure);
    //@ts-ignore string index types are wrong
    switch (this.type) {
      case 'floor':
        await eyekia.v1.floorUpdate(this.structureId, { data: structure });
        break;
      case 'wall':
        await eyekia.v1.wallUpdate(this.structureId, { data: structure });
        break;
      default:
        return;
    }
    this.status = 'finished';
  };

  handleDispose() {}
}
