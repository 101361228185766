import 'firebase/auth';
import firebase from './firebase';

let _auth: firebase.auth.Auth = null;
export function firebaseAuth() {
  if (_auth === null) {
    // import('firebase/auth').then(module => {
    _auth = firebase.auth();
    return _auth;
    // });
  } else {
    return _auth;
  }
}