import { Box } from '@mui/system';
import * as React from 'react';
import { AssetList } from './assets/AssetList';
import { NewAssetButton } from './assets/NewAssetButton';

type AssetBrowserProps = {};
export const AssetBrowser = (props: AssetBrowserProps) => {
  return (
    <Box width={'100vw'} height={'100vh'} display='flex' flexDirection='column'>
      <AssetList />
    </Box>
  );
};
