import { ThirtyFpsRounded } from '@mui/icons-material';
import { client } from 'clients/eyekia';
import { firebaseAuth } from 'data/firebaseAuth';
import { makeObservable, observable, runInAction } from 'mobx';
import { BaseStore } from './BaseStore';

export class AuthStore extends BaseStore {
  constructor() {
    super();
    makeObservable(this, {
      user: observable,
      authorized: observable,
    });
    firebaseAuth().onAuthStateChanged(this.handleAuthChange);
  }

  handleAuthChange = (user: firebase.default.User): any => {
    console.log('handle auth change', user);
    if (user) {
      this.user = user;
      this.verifyClaims();
      this.authenticateApi();
    }
  };

  verifyClaims = async () => {
    const token = await this.user?.getIdTokenResult();

    console.log('has asset editor', token.claims);
    if (!!token.claims['assetEditor']) {
      runInAction(() => {
        this.authorized = true;
      });
    }
  };

  getUserToken = async () => {
    if (this.user) {
      const token = await this.user.getIdToken();
      return token;
    } else {
      return '';
    }
  };

  authenticateApi = async () => {
    client().setSecurityData({
      token: this.getUserToken,
    });
  };

  user: firebase.default.User = null;

  authorized: boolean = false;

  logOut = async () => {
    console.log('signing out!');
    await firebaseAuth().signOut();
    runInAction(() => {
      console.log('running in ');
      this.user = null;
      this.authorized = false;
    });
    client().setSecurityData({
      token: '',
    });
    console.log('signed out!');
  };

  handleDispose() {}
}
