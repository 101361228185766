import { FlexColumn } from 'common/FlexColumn';
import { D2TransformEditor } from 'components/editor/D2TransformEditor';
import { IsoShapeEditor } from 'components/editor/IsoShapeEditor';
import { SelectEditor } from 'components/editor/SelectEditor';
import { ValueEditor } from 'components/editor/ValueEditor';
import { Vec2Editor } from 'components/editor/Vec2Editor';
import { useAssets } from 'components/useAssets';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { EditableSelect } from 'stores/editor/EditableValue';
import { FramePrimitiveDO } from 'stores/Primitive';

type FramePrimitivePanelProps = {
  primitive: FramePrimitiveDO;
};

export const FrameReferencePrimitivePanel = observer((props: FramePrimitivePanelProps) => {
  const { primitive } = props;

  return (
    <FlexColumn>
      <SelectEditor value={primitive.facing} />
      <SelectEditor value={primitive.placement} />
      <SelectEditor value={primitive.frameId} />
      <IsoShapeEditor value={primitive.shape} />
    </FlexColumn>
  );
});
