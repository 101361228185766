import { createTheme, ThemeProvider } from '@mui/material/styles';
import { AuthPage } from 'components/AuthPage';
import { AuthProvider } from 'components/AuthProvider';
import React from 'react';
import { BasePage } from './components/BasePage';
import { RootProvider } from './components/RootProvider';
import { BrowserRouter as Router } from 'react-router-dom';

const darkTheme = createTheme({
  palette: {},
});

function App() {
  return (
    <ThemeProvider theme={darkTheme}>
      <Router>
        <div className='App'>
          <AuthProvider>
            <BasePage />
          </AuthProvider>
        </div>
      </Router>
    </ThemeProvider>
  );
}

export default App;
