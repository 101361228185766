import { colorFromHue, DEFAULT_COLOR, fromHexColor, hueFromColor } from 'common/ColorUtils';
import { RGBtoHSV } from 'common/HSVConverter';
import { action, makeObservable, observable } from 'mobx';
import { ColorPreset, ColorVariation } from '@sophya/eyekia/lib/schemas/Types';
import { AutoId } from 'common/AutoId';

type ColorVariant = {
  hueTarget: number;
  saturationMultiplier: number;
  valueMultiplier: number;
  key: string;
};

export class ColorPresetStore {
  public baseColor: string;
  baseHue: number;

  public useBaseAsPreset: boolean;

  public presets: ColorVariant[];
  public currentPresetIndex: number = null;

  constructor(
    public updateColor: (rotation: number, saturation: number, value: number) => void,
    private save: () => Promise<void>,
  ) {
    makeObservable(this, {
      baseColor: observable,
      presets: observable,
      currentPresetIndex: observable,
      useBaseAsPreset: observable,
      setBaseColor: action,
      rotateHueSlider: action,
      addPreset: action,
      selectPreset: action,
      updateFromSchema: action,
      toggleBasePreset: action,
    });
  }

  public setBaseColor = (color: string) => {
    this.baseColor = color;
    this.baseHue = hueFromColor(color) * 360;
    this.updateCanvas();
  };

  public rotateHueSlider = (_e, v) => {
    this.presets[this.currentPresetIndex].hueTarget = v;
    this.updateCanvas();
    this.save();
  };

  public rotateSaturationSlider = (_e, v) => {
    this.presets[this.currentPresetIndex].saturationMultiplier = v;
    this.updateCanvas();
    this.save();
  };
  public rotateValueSlider = (_e, v) => {
    this.presets[this.currentPresetIndex].valueMultiplier = v;
    this.updateCanvas();
    this.save();
  };

  updateCanvas = () => {
    if (this.currentPresetIndex == null) {
      this.updateColor(0, 1.0, 1.0);
    } else {
      const preset = this.presets[this.currentPresetIndex];
      const degrees = preset.hueTarget;
      const diff = degrees - this.baseHue;
      this.updateColor(diff, preset.saturationMultiplier, preset.valueMultiplier);
    }
  };
  public toggleBasePreset = () => {
    this.useBaseAsPreset = !this.useBaseAsPreset;
    this.save();
  };
  public addPreset = () => {
    this.presets.push({
      hueTarget: this.baseHue ?? 0,
      saturationMultiplier: 1.0,
      valueMultiplier: 1.0,
      key: AutoId.newId(),
    });
    this.save();
  };
  public removePreset = () => {
    this.presets.splice(this.currentPresetIndex, 1);
    this.currentPresetIndex = null;
    this.updateCanvas();
    this.save();
  };
  public selectPreset = index => {
    this.currentPresetIndex = index;
    this.updateCanvas();
  };

  public updateFromSchema = (preset: ColorPreset) => {
    if (preset) {
      this.setBaseColor(preset.baseColor);
      if (preset.presetColorsDictionary) {
        this.presets = Object.keys(preset.presetColorsDictionary)
          .sort()
          .map(key => {
            const variant = preset.presetColorsDictionary[key];
            return {
              hueTarget: variant.hueRotationDegrees + this.baseHue,
              saturationMultiplier: variant.saturationMultiplier ?? 1.0,
              valueMultiplier: variant.valueMultiplier ?? 1.0,
              key,
            } as ColorVariant;
          });
      } else {
        this.presets = [];
      }
      this.useBaseAsPreset = preset.useBaseAsPreset;
    } else {
      this.baseColor = DEFAULT_COLOR;
      this.baseHue = 0;
      this.presets = [];
      this.useBaseAsPreset = true;
    }
  };

  toSchema = (): ColorPreset => {
    return {
      baseColor: this.baseColor,
      useBaseAsPreset: this.useBaseAsPreset,
      presetColors: this.presets.map(
        v =>
          ({
            hueRotationDegrees: v.hueTarget - this.baseHue,
            saturationMultiplier: v.saturationMultiplier,
            valueMultiplier: v.valueMultiplier,
          } as ColorVariation),
      ),
      presetColorsDictionary: Object.assign(
        {},
        ...this.presets.map(v => ({
          [v.key]: {
            hueRotationDegrees: v.hueTarget - this.baseHue,
            saturationMultiplier: v.saturationMultiplier,
            valueMultiplier: v.valueMultiplier,
          } as ColorVariation,
        })),
      ),
    };
  };
}
