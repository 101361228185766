import { Box, BoxProps } from '@mui/system';
import * as React from 'react';

type FlexRowProps = React.PropsWithChildren<
  BoxProps & {
    fullSize?: boolean;
  }
>;
export const FlexRow = (props: FlexRowProps) => {
  const { children, fullSize = false, ...boxProps } = props;

  const fullSizeProps = fullSize ? { width: '100%', height: '100%' } : {};

  return (
    <Box {...fullSizeProps} display='flex' flexDirection='row' alignItems='center' {...boxProps}>
      {props.children}
    </Box>
  );
};
