import * as React from 'react';
import { useAuth } from './AuthProvider';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from '../data/firebase';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';

// Configure FirebaseUI.
const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: 'popup',
  // We will display Google and Facebook as auth providers.
  signInOptions: [firebase.auth.GoogleAuthProvider.PROVIDER_ID],
  callbacks: {
    // Avoid redirects after sign-in.
    signInSuccessWithAuthResult: () => false,
  },
};

type AuthPageProps = React.PropsWithChildren<{}>;

export const AuthPage = observer((props: AuthPageProps) => {
  const auth = useAuth();

  console.log('auth user', auth.user);

  if (!auth.user && !auth.authorized) {
    return (
      <div>
        <h1>Asset Creator</h1>
        <p>Please sign-in:</p>
        <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
      </div>
    );
  } else if (auth.user && !auth.authorized) {
    return (
      <div>
        <h1>you are not authorized!</h1>
        <Link to='/logout'>Logout</Link>
      </div>
    );
  }

  return <React.Fragment>{props.children}</React.Fragment>;
});
