import { HSVtoRGB, RGBtoHSV } from "./HSVConverter";

export const DEFAULT_COLOR = "#FFFFFFFF"

export enum SwatchGenerateMode {
    Mean,
    Mode
}
function toHex(n: number){
    return n < 16 ? '0' + n.toString(16) : n.toString(16);
}
function fromHex(n: string){
    return parseInt(n,16);
}
function toHexColor(r: number, g:number, b: number, a:number){
    return '#' + toHex(r) + toHex(g) + toHex(b) + toHex(a);
}
export function fromHexColor(color: string){
    const rs = color.substr(1,2);
    const gs = color.substr(3,2);
    const bs = color.substr(5,2);
    return {r:fromHex(rs),g:fromHex(gs),b:fromHex(bs),}
}
export function colorFromHue(h: number){
    if(h<0){
        h+=1;
    }
    const c = HSVtoRGB(h,1,1);
  return toHexColor(c.r,c.g,c.b,255);
}
export function colorFromHSV(h: number, s: number, v: number){
    if(h<0){
        h+=1;
    }
    const c = HSVtoRGB(h,s,v);
  return toHexColor(c.r,c.g,c.b,255);
}
export function hueFromColor(color: string){
    const c = fromHexColor(color);
    return RGBtoHSV(c.r,c.g,c.b).h;
}
export function generateHexCodeMeanImage(img:HTMLImageElement){
    const canvas = document.createElement('canvas');
    canvas.width = img.width;
    canvas.height = img.height;
    const context = canvas.getContext('2d');
    context.drawImage(img, 0, 0, img.width, img.height);
    return generateHexCodeMeanCanvas(canvas);
}

export function generateHexCodeMeanCanvas(canvas:HTMLCanvasElement) {   
    const context = canvas.getContext('2d');
    var png = context.getImageData(0, 0, canvas.width, canvas.height);
    
    let weights = {r:0,g:0,b:0};
    let pixelCount=0;
    let invis = 0;
    for (var y = 0; y < png.height; y++) {
        for (var x = 0; x < png.width; x++) {
            var idx = (png.width * y + x) << 2;

            const r = png.data[idx];
            const g = png.data[idx + 1];
            const b = png.data[idx + 2];
            const a = png.data[idx + 3];
            

            //skip invisible colors
            if (a === 0) {
                invis++;
                continue;
            }

            const hsv = RGBtoHSV(r,g,b);

            weights = {
              r: weights.r +hsv.s * r,
              g: weights.g +hsv.s * g,
              b: weights.b +hsv.s * b,
            }

            pixelCount += hsv.s;
            
        }
    }
    let color:string;
    if (pixelCount != 0) {
        const average = {
            r: Math.floor(weights.r/pixelCount),
            g: Math.floor(weights.g/pixelCount),
            b: Math.floor(weights.b/pixelCount),
          }
        color = toHexColor(average.r,average.g,average.b,255);
    } else {
        color = DEFAULT_COLOR;
    }

    return color;
    
}