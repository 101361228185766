import { Asset, DefaultInteractionType, DefaultInteractions } from '@sophya/eyekia';
import { client } from 'clients/eyekia';
import { generateHexCodeMeanImage } from 'common/ColorUtils';
import debounce from 'lodash.debounce';
import { action, makeObservable, observable, runInAction } from 'mobx';
import { createElement } from 'react';
import { AssetConfigDO } from './AssetConfig';
import { AssetStore } from './AssetStore';
import { BaseStore } from './BaseStore';
import { ImageIds } from './Image';
import {
  OrientationDO,
  OrientationKey,
  orientationClockwise,
  orientationOpposite,
} from './Orientation';
import { PrimitiveIds } from './Primitive';
import { FabricInterface } from './RootStore';
import { ColorPresetStore } from './editor/ColorPresetStore';
import { EditableSelect, EditableValue } from './editor/EditableValue';

const defaultInteractionTypes: DefaultInteractionType[] = DefaultInteractions.concat([null]);

export class AssetDO extends BaseStore {
  public name: EditableValue<string>;
  public description: EditableValue<string>;
  public userHint: EditableValue<string>;

  thumbnail: string = null;
  public thumbnailImage;

  category: EditableSelect<string>;

  accessKey: EditableValue<string>;

  public colorPresets: ColorPresetStore;
  public currentHueRotation = 0;
  public currentSaturation = 1.0;
  public currentValue = 1.0;

  defaultInteractionType: EditableSelect<DefaultInteractionType>;

  orientations = new Map<OrientationKey, OrientationDO>();

  config: AssetConfigDO;

  published: EditableValue<boolean>;

  currentOrientation: OrientationKey = null;

  modifiedSinceLastPublish: boolean = false;

  assetImportOpen: boolean = false;

  constructor(public id: string, private fabric: FabricInterface, private store: AssetStore) {
    super();
    makeObservable(this, {
      name: observable,
      currentOrientation: observable,
      thumbnail: observable,
      orientations: observable,
      thumbnailModalOpen: observable,
      modifiedSinceLastPublish: observable,
      assetImportOpen: observable,
      handleFinishEdit: action,
      selectOrientation: action,
    });
    this.colorPresets = new ColorPresetStore(this.setColorVariation, this.handleAssetChange);
  }

  cycleOrientation = () => {
    const orientationKeys = Array.from(this.orientations.keys());
    const index = orientationKeys.findIndex(key => this.currentOrientation === key);
    if (index === -1) {
      return;
    }
    const nextIndex = (index + 1) % orientationKeys.length;
    const newOrientation = orientationKeys[nextIndex];
    console.log({
      orientationKeys,
      index,
      nextIndex,
      newOrientation,
    });
    this.selectOrientation(newOrientation);
  };

  openAssetImport = (): void => {
    this.assetImportOpen = true;
  };

  closeAssetImport = (): void => {
    this.assetImportOpen = false;
  };

  handleFinishEdit = () => {
    this.fabric.dumpCanvas();
  };

  handleCreateOrientation = async (orientationKey: OrientationKey): Promise<void> => {
    runInAction(() => {
      if (this.currentOrientation !== null) {
        this.fabric.dumpCanvas();
      }
      this.orientations.set(
        orientationKey,
        new OrientationDO(
          this.id,
          orientationKey,
          this.fabric,
          this.config,
          this.handleAssetChange,
        ),
      );
      this.currentOrientation = orientationKey;
    });

    // await client().v1.assetOrientationUpdate(this.id, orientationKey, {
    //   //@ts-ignore Open Api types arent matching Eyekia types exactly;  looks okay in firebase though
    //   data: this.orientations.get(this.currentOrientation).toSchema(),
    // });
    this.save.cancel();
    await this.networkUpdateAsset();
  };

  deleteOrientation = (orientationKey: OrientationKey): void => {
    runInAction(() => {
      this.orientations.delete(orientationKey);
      if (orientationKey === this.currentOrientation) {
        this.fabric.dumpCanvas();
        this.currentOrientation = null;
        this.findDefaultOrientation();
      }
    });
    this.networkDeleteOrientation(orientationKey);
  };

  networkDeleteOrientation = async (orientationKey: OrientationKey): Promise<void> => {
    await client().v1.assetOrientationDelete(this.id, orientationKey);
  };

  deleteImage = (ids: ImageIds) => {
    this.orientations.get(ids.orientationKey)?.deleteImage(ids.imageId);
  };

  deletePrimitive = (ids: PrimitiveIds) => {
    this.orientations.get(ids.orientationKey)?.getImage(ids.imageId)?.deletePrimitive(ids.id);
  };

  findDefaultOrientation() {
    if (this.orientations.size > 0) {
      this.currentOrientation = this.orientations.keys().next().value;
      this.loadCurrentOrientation();
    } else {
      this.currentOrientation = null;
    }
  }
  selectOrientation(orientationKey: OrientationKey): void {
    if (orientationKey !== this.currentOrientation) {
      this.fabric.dumpCanvas();
      this.currentOrientation = orientationKey;
      this.loadCurrentOrientation();
    }
  }
  refreshCanvas() {
    this.fabric.dumpCanvas();
    this.loadCurrentOrientation();
  }

  loadCurrentOrientation(): void {
    this.orientations.get(this.currentOrientation).populateCanvas();
  }

  getOrientation(orientationKey: OrientationKey): OrientationDO | undefined {
    return this.orientations.get(orientationKey);
  }

  getNextOrientation(key: OrientationKey): OrientationDO | undefined {
    const startingKey = key;
    let orientation;
    key = orientationClockwise(startingKey);
    while (key !== startingKey && key !== null && key !== undefined) {
      orientation = this.orientations.get(key);
      if (orientation) {
        return orientation;
      }
      key = orientationClockwise(key);
    }
    return undefined;
  }

  getBestOrientation(key: OrientationKey): OrientationDO | undefined {
    let orientation = this.orientations.get(key);
    if (orientation) {
      return orientation;
    }
    const startingKey = key;
    key = orientationOpposite(key);
    orientation = this.orientations.get(key);
    if (orientation) {
      return orientation;
    }
    return this.getNextOrientation(startingKey);
  }

  networkUpdateAsset = async (): Promise<void> => {
    //@ts-ignore
    const data = await client().v1.assetUpdate(this.id, { data: this.toSchema() });
    this.modifiedSinceLastPublish = data.data.modifiedSinceLastPublish;
  };

  save = debounce(this.networkUpdateAsset, 1000);

  private loading = false;
  private handleAssetChange = (): Promise<void> => {
    if (!this.loading) {
      return this.save();
    }
  };

  public setColorVariation = (rotation: number, saturation: number, value: number) => {
    this.currentHueRotation = rotation;
    this.currentSaturation = saturation;
    this.currentValue = value;
    this.fabric.setColorFilter(rotation, saturation, value);
  };

  public setThumbnail = () => {
    const imgLoad = ev => {
      const paintImage = ev.currentTarget as HTMLImageElement;
      const color = generateHexCodeMeanImage(paintImage);
      this.colorPresets.setBaseColor(color);
    };

    this.thumbnailImage = createElement('img', {
      width: '200px',
      crossOrigin: 'anonymous',
      onLoad: imgLoad,
      src: this.thumbnail,
    });
  };

  //RZNewman: ignoring "Type instantiation is excessively deep and possibly infinite"  because
  //The API needs to use a 'partial' instead of a record for orientations
  //@ts-ignore
  updateFromSchema(asset: Asset) {
    this.loading = true;
    this.name = new EditableValue<string>('Name', asset.name, this.handleAssetChange);
    this.description = new EditableValue<string>(
      'Description',
      asset.description,
      this.handleAssetChange,
    );
    this.userHint = new EditableValue<string>('User Hint', asset.userHint, this.handleAssetChange);

    this.config = new AssetConfigDO(this.handleAssetChange);
    this.config.updateFromSchema(asset.config);

    this.category = new EditableSelect<string>(
      'Categories',
      asset.categoryId ?? null,
      this.handleAssetChange,
      this.store.getCategories,
    );

    this.accessKey = new EditableValue<string>(
      'Access Key',
      asset.accessKey,
      this.handleAssetChange,
    );

    this.colorPresets.updateFromSchema(asset.colors);

    this.defaultInteractionType = new EditableSelect<DefaultInteractionType>(
      'Default Interaction Type',
      asset.defaultInteractionType ?? null,
      this.handleAssetChange,
      defaultInteractionTypes,
    );

    this.thumbnail = asset.thumbnail;
    this.setThumbnail();

    this.published = new EditableValue<boolean>(
      'Published',
      asset.published ?? false,
      this.handleAssetChange,
    );

    this.modifiedSinceLastPublish = asset.modifiedSinceLastPublish;

    // console.log(' is this asset published? ', {
    //   assetId: this.id,
    //   published: asset.published,
    //   editableValue: this.published,
    // });

    for (const [oriKey, data] of Object.entries(asset.orientations)) {
      const ori = new OrientationDO(
        this.id,
        oriKey as OrientationKey,
        this.fabric,
        this.config,
        this.handleAssetChange,
      );
      ori.updateFromSchema(data);
      this.orientations.set(oriKey as OrientationKey, ori);
    }
    this.currentOrientation = this.orientations.keys().next()?.value;

    this.loading = false;
  }

  toSchema = (): Asset => {
    const oris = {};
    this.orientations.forEach((value, key) => {
      oris[key] = value.toSchema();
    });
    return {
      name: this.name.value,
      description: this.description.value,
      userHint: this.userHint.value,
      thumbnail: this.thumbnail,
      categoryId: this.category.value,

      colors: this.colorPresets.toSchema(),

      published: this.published.value ?? false,
      modifiedSinceLastPublish: this.modifiedSinceLastPublish,
      defaultInteractionType: this.defaultInteractionType.value,
      orientations: oris,
      config: this.config.toSchema(),
      accessKey: this.accessKey.value,
    };
  };
  static dataURLtoBlob(dataurl: string) {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  }

  createThumbnail = () => {
    this.thumbnailModalOpen = true;
    this.thumbnailPreCropDataUrl = this.fabric.getThumbnail();
  };

  saveThumbnailCrop = async (data: Blob) => {
    await this.networkCreateThumb(data);
    this.thumbnailModalOpen = false;
    this.thumbnailPreCropDataUrl = null;
  };

  cancelThumbnailCrop = () => {
    this.thumbnailModalOpen = false;
    this.thumbnailPreCropDataUrl = null;
  };

  thumbnailModalOpen: boolean = false;
  thumbnailPreCropDataUrl: string = null;

  networkCreateThumb = async (img: Blob): Promise<void> => {
    const responseEyekia = await client().v1.assetUploadUrlDetail(this.id);
    if (!responseEyekia.ok) {
      console.error('eyekia get url failed');
      return;
    }
    const formData = new FormData();
    formData.append('Content-Type', img.type);
    Object.entries(responseEyekia.data.upload.fields).forEach(([k, v]) => {
      formData.append(k, v);
    });
    formData.append('file', img); // must be the last one
    const responseS3 = await fetch(responseEyekia.data.upload.url, {
      method: 'POST',
      body: formData,
    });
    if (!responseS3.ok) {
      console.error('image upload unsucessful');
      return;
    }

    this.thumbnail = responseEyekia.data.download.url;
    this.setThumbnail();
    this.networkUpdateAsset();
  };

  orientationMapping = (): Map<OrientationKey, OrientationKey> => {
    const newMap = new Map<OrientationKey, OrientationKey>();
    for (const key of this.orientations.keys()) {
      newMap.set(key, key);
    }
    return newMap;
  };

  changeOrientations = (changes: Map<OrientationKey, OrientationKey>) => {
    const newOrientations = new Map<OrientationKey, OrientationDO>();
    for (const [key, value] of changes.entries()) {
      const oriDo = this.orientations.get(key);
      if (key) {
        newOrientations.set(value, oriDo);
      }
    }
    this.orientations = newOrientations;
    this.save.cancel();
    return this.networkUpdateAsset();
  };

  publishAsset = async () => {
    const res = await client().v1.assetPublishCreate(this.id, {});
    runInAction(() => {
      this.published.value = res.data.data.published;
      this.modifiedSinceLastPublish = res.data.data.modifiedSinceLastPublish;
    });
    console.log("Asset published successfully: ", this.name.value || this.id);
  };

  handleDispose = () => { };
}
