import { PatternSharp } from '@mui/icons-material';
import { BaseStore } from './BaseStore';
import { EditableSelect } from './editor/EditableValue';
import { RootStore } from './RootStore';
import * as startcase from 'lodash.startcase';
import { AssetCreationJob } from './creation/CreationJob';
import { makeObservable, observable, action } from 'mobx';
import { Static } from '@sinclair/typebox';
import { StructureCreationJob } from './creation/StructureBulkJob';
import * as fs from 'fs';

declare interface CreatorCustomFile {
  path: string;
}

function parseStructureInfo(folder: string) {
  const info = folder.split('_');
  if (info.length >= 4) {
    var name = info[3];
    //loop ignores last element
    for (var i = 4; i < info.length - 1; i++) {
      name += ' ' + info[i];
    }
    const isCorner = info[3].toLowerCase() === 'corner';
    return {
      height: info[1],
      type: info[2].toLowerCase(),
      name,
      isCorner,
    };
  } else {
    throw new Error('Folder name format error');
  }
}

function parseFolderName(value: string) {
  const parts = value.split('/');
  if (parts.length > 1) {
    if (parts[0] !== '') {
      return parts[0];
    } else {
      return parts[1];
    }
  } else {
    throw new Error('Please drop in a folder');
  }
}

function createFromFolder(files: File[], folderName: string) {
  const info = parseStructureInfo(folderName);
  return new StructureCreationJob(
    files,
    info.name,
    info.height,
    info.type == 'wall' ? 'wall' : 'floor',
    folderName,
    info.type == 'wall' ? info.isCorner : undefined,
  );
}

export class StructureBulkStore extends BaseStore {
  constructor(private root: RootStore) {
    super();
    makeObservable(this, {
      jobs: observable,
      submitFiles: action,
      deleteJob: action,
    });
  }
  jobs: StructureCreationJob[] = [];

  submitFiles(files: CreatorCustomFile[]) {
    const sortedFiles = files.sort((a, b) => {
      const folderA = parseFolderName(a.path);
      const folderB = parseFolderName(b.path);
      return folderA.localeCompare(folderB);
    });
    let groupOfFiles = [];
    let lastFolder = '';
    const createJob = () => {
      const name = lastFolder;

      this.jobs.push(createFromFolder(groupOfFiles, name));
      console.log('job pushed for ' + name);
    };
    for (const file of sortedFiles) {
      const folderName = parseFolderName(file.path);
      if (folderName === lastFolder) {
        groupOfFiles.push(file);
      } else {
        //don't create empty one
        if (groupOfFiles.length > 0) {
          createJob();
        }

        // reset
        groupOfFiles = [file];
        lastFolder = folderName;
      }
    }
    if (groupOfFiles.length > 0) {
      createJob();
    }

    console.log('submited files grouped', this.jobs);
  }

  deleteJob(key: string) {
    this.jobs = this.jobs.filter(job => job.key !== key);
  }

  startAll = async () => {
    await Promise.all(this.jobs.map(job => job.startJob()));
  };

  deleteAll = async () => {
    this.jobs = [];
  };

  handleDispose() {}
}
