import { BaseStore } from './BaseStore';
import { FabricStore2 as FabricStore } from './fabric2/FabricStore2';
import { AssetDO } from './Asset';
import { action, makeObservable, observable, runInAction } from 'mobx';
import { AutoId } from '../common/AutoId';
import { OrientationDO, OrientationKey } from './Orientation';
import { PrimitiveDO } from './Primitive';
import { FabricInterface, RootStore } from './RootStore';
import { Image, Asset, Primitive } from '@sophya/eyekia';
import { client } from 'clients/eyekia';
import { ImageDO } from './Image';

export class AssetStore extends BaseStore {
  assets = new Map<string, AssetDO>();
  assetsByCategory = new Map<string, string[]>();

  constructor(private fabric: FabricInterface, private root: RootStore) {
    super();
    makeObservable(this, {
      assets: observable,
      loadingAssets: observable,
      currentAsset: observable,
      currentCategoryId: observable,
      assetsByCategory: observable,
    });

    fabric.setImageUpdateFn(this.handleFabricUpdateImage);
    fabric.setPrimUpdateFn(this.handleFabricUpdatePrimititve);
    fabric.setSelectFn(this.handleFabricSelect);

    document.addEventListener('keydown', this.handleKeyDown);
    this.keyDisposer = () => document.removeEventListener('keydown', this.handleKeyDown);

    this.handleLoadAssets('null');
  }

  keyDisposer: () => void;

  handleKeyDown = (e: KeyboardEvent) => {
    switch (e.key) {
      case 'q': {
        this.getCurrentAsset()?.cycleOrientation();
        break;
      }
      case 'w': {
        this.getCurrentOrientation()?.cycleImage();
        break;
      }
      case 'e': {
        this.getCurrentImage()?.cyclePrimitive();
        break;
      }
    }
  };

  loadingAssets: boolean = false;

  handleLoadAssets = async (categoryId: string) => {
    this.loadingAssets = true;
    const res = await client().v1.assetList({ categoryId, noCache: true });
    runInAction(() => {
      const assetIds = [];
      for (const resAsset of res.data.assets) {
        const { id, data } = resAsset;
        assetIds.push(id);
        const asset = new AssetDO(id, this.fabric, this);
        //Rudolf - ignoring 'deep type instance' for Asset
        //@ts-ignore
        asset.updateFromSchema(data as Asset);
        this.assets.set(id, asset);
      }
      this.assetsByCategory.set(categoryId, assetIds);
      this.currentCategoryId = categoryId;
      console.log('loaded assets ', categoryId, assetIds);
      this.loadingAssets = false;
    });
  };

  currentCategoryId: string;

  currentAsset: string;

  getCategories = () => {
    return this.root.categories.keys();
  };

  handleCreateAsset = async (): Promise<void> => {
    const assetRes = await client().v1.assetCreate({});
    const id = assetRes.data.assetId;
    runInAction(() => {
      this.assets.set(id, new AssetDO(id, this.fabric, this));
    });
  };

  handlePublishAll = async (): Promise<void> => {
    const categoryAssetIds = this.assetsByCategory.get(this.currentCategoryId);
    const publishTasks = categoryAssetIds.map((assetId) => {
      const assetData = this.getAsset(assetId);
        if (!assetData.published.value || assetData.modifiedSinceLastPublish) {
          return assetData.publishAsset();
        }
    });
    await Promise.all(publishTasks);
  };

  handleFabricUpdateImage = (id: string, data: Image) => {
    const asset = this.getAsset(this.currentAsset);
    const orientation = asset?.getOrientation(asset?.currentOrientation);
    const image = orientation?.getImage(id);

    image?.updateFromSchema(data);
    asset?.save();
  };

  handleFabricUpdatePrimititve = (id: string, data: Primitive) => {
    const asset = this.getAsset(this.currentAsset);
    const orientation = asset?.getOrientation(asset?.currentOrientation);
    const image = orientation?.getImage(orientation.currentImage);
    const prim = image?.getPrimitive(id);

    prim?.updateFromSchema(data);
    asset?.save();
  };

  handleFabricSelect = (imageId: string, primitiveID: string) => {
    const asset = this.getAsset(this.currentAsset);
    const orientation = asset?.getOrientation(asset?.currentOrientation);

    orientation?.selectImage(imageId);
  };

  handleDispose(): void {
    this.keyDisposer?.();
    for (const asset of this.assets.values()) {
      asset.dispose();
    }
  }

  getAsset(assetId: string): AssetDO | undefined {
    return this.assets.get(assetId);
  }

  getCurrentAsset(): AssetDO | undefined {
    return this.assets.get(this.currentAsset);
  }

  deleteAsset(id: string): void {
    runInAction(() => {
      const asset = this.assets.get(id);
      let cat = asset?.category?.value;
      if (cat === null) {
        cat = 'null';
      }
      const assetsCategory = this.assetsByCategory.get(cat);
      if (assetsCategory) {
        console.debug('removing asset ', id, ' from cat ', cat, assetsCategory, this);
        this.assetsByCategory.set(
          cat,
          assetsCategory.filter(value => value !== id),
        );
      }
      this.assets.delete(id);
    });
    this.networkDeleteAsset(id);
  }
  networkDeleteAsset = async (id: string): Promise<void> => {
    await client().v1.assetDelete(id);
  };

  getOrientation(assetId: string, orientationKey: OrientationKey): OrientationDO | undefined {
    return this.getAsset(assetId)?.getOrientation(orientationKey);
  }

  getNextOrientation(assetId: string, orientationKey: OrientationKey): OrientationDO | undefined {
    return this.getAsset(assetId)?.getNextOrientation(orientationKey);
  }

  getBestOrientation(assetId: string, orientationKey: OrientationKey): OrientationDO | undefined {
    return this.getAsset(assetId)?.getBestOrientation(orientationKey);
  }

  getCurrentOrientation(): OrientationDO | undefined {
    const asset = this.getCurrentAsset();
    return asset?.getOrientation(asset?.currentOrientation);
  }

  getImage(assetId: string, orientationKey: OrientationKey, imageId: string): ImageDO | undefined {
    return this.getOrientation(assetId, orientationKey)?.getImage(imageId);
  }

  getCurrentImage(): ImageDO | undefined {
    const orientation = this.getCurrentOrientation();
    return orientation?.getImage(orientation?.currentImage);
  }

  getPrimitive(
    assetId: string,
    orientationKey: OrientationKey,
    imageId: string,
    primitiveId: string,
  ): PrimitiveDO | undefined {
    return this.getImage(assetId, orientationKey, imageId)?.getPrimitive(primitiveId);
  }

  getCurrentPrimitive(): PrimitiveDO | undefined {
    const image = this.getCurrentImage();
    return image?.getCurrentPrimitive();
  }
}
