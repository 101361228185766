import { DeleteForever } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { CollapsableSection } from 'common/components/CollapsableSection';
import { Filler } from 'common/components/Filler';
import { FlexColumn } from 'common/FlexColumn';
import { FlexRow } from 'common/FlexRow';
import { ValueEditor } from 'components/editor/ValueEditor';
import { SectionHeader } from 'components/SectionHeader';
import { useAssets } from 'components/useAssets';
import * as React from 'react';
import { OrientationKey } from 'stores/Orientation';
import {
  PrimitiveIds,
  SeatPrimitiveDO,
  ShadowPrimitiveDO,
  ShapePrimitiveDO,
  FramePrimitiveDO,
} from 'stores/Primitive';
import { SeatPrimitivePanel } from './SeatPrimitivePanel';
import { ShadowPrimitivePanel } from './ShadowPrimitivePanel';
import { ShapePrimitivePanel } from './ShapePrimitivePanel';
import { FrameReferencePrimitivePanel } from './FrameReferencePrimitivePanel';

type PrimitivePanelProps = {
  ids: PrimitiveIds;
  onDeletePrim: (ids: PrimitiveIds) => void;
};
export const PrimitivePanel = (props: PrimitivePanelProps) => {
  const { onDeletePrim, ids } = props;
  const { assetId, orientationKey, imageId, id } = ids;

  const assets = useAssets();
  const orientation = assets.getOrientation(assetId, orientationKey);
  const primitive = assets.getPrimitive(assetId, orientationKey, imageId, id);

  if (!orientation || !primitive) {
    return null;
  }

  let panel: React.ReactNode;

  switch (primitive.type) {
    case 'seat': {
      panel = <SeatPrimitivePanel primitive={primitive as SeatPrimitiveDO} />;
      break;
    }
    case 'frame': {
      panel = <FrameReferencePrimitivePanel primitive={primitive as FramePrimitiveDO} />;
      break;
    }
    case 'shape': {
      panel = <ShapePrimitivePanel primitive={primitive as ShapePrimitiveDO} />;
      break;
    }
    case 'shadow': {
      panel = <ShadowPrimitivePanel primitive={primitive as ShadowPrimitiveDO} />;
      break;
    }
    default: {
      panel = null;
    }
  }

  return (
    <FlexColumn>
      <SectionHeader secondary label={`Primitive ${id}, type: ${primitive.type}`}>
        <Filler />
        <IconButton
          onClick={() => {
            onDeletePrim(ids);
          }}
        >
          <DeleteForever />
        </IconButton>
      </SectionHeader>
      <CollapsableSection label={'Primitive Details:'}>{panel}</CollapsableSection>
    </FlexColumn>
  );
};
