/**
 * A base class for all BaseStore. Dispose is called when the entire client is shutting down
 */
export abstract class BaseStore {
  // #region Public Methods (1)

  public dispose(): void {
    this.handleDispose();
  }

  // #endregion Public Methods (1)

  // #region Protected Abstract Methods (1)

  protected abstract handleDispose(): void;

  // #endregion Protected Abstract Methods (1)
}
