import { Image, Primitive } from '@sophya/eyekia';
import { AutoId } from 'common/AutoId';
import { BaseStore } from 'stores/BaseStore';
import { FabricInterface } from 'stores/RootStore';
import { FabricObjectBase, FabricObjectHandlers } from './FabricObjectBase';
import { fabric } from 'fabric';
import { Vector2 } from 'vector_math.js';
import { FabricImage } from './FabricImage';
import isometricGrid from 'resources/isometricGrid.png';

export type Facing = 'S' | 'E' | 'N' | 'W';
export type FacingFront = 'S' | 'E';
export type Placement = 'wall' | 'floor';
export type ColliderType = 'sensor' | 'collider' | 'selector';
export const ColliderTypes: ColliderType[] = ['sensor', 'collider', 'selector'];

export class FabricStore2 extends BaseStore implements FabricInterface {
  constructor() {
    super();
  }

  loadingImages = new Set<string>();

  images = new Map<string, FabricImage>();

  /** Fabric Interface start */
  loadImage = (id: string, data: Image) => {
    this.loadingImages.add(id);
    console.debug('loading image', id, data);
    fabric.Image.fromURL(data.url, this.onImageLoad(data), {
      name: id,
      originX: 'center',
      originY: 'center',
      centeredScaling: true,
      crossOrigin: 'anonymous',
      lockScalingFlip: true,
    });
  };

  onImageLoad = (data: Image) => (img: fabric.Image) => {
    console.log('on image load', { img, data, this: this });
    if (this.loadingImages.has(img.name)) {
      const object = new FabricImage(this, img, data, {
        onModify: self => {
          this.updateImageFn(self.id, self.toSchema(this.center));
        },
        onSelect: self => this.selectPrimFn(self.id, ''),
        onDeselect: self => console.log('deselected', self.id),
      });
      this.images.set(img.name, object);
      this.loadingImages.delete(img.name);
    }
  };

  deleteImage = (id: string) => {
    this.loadingImages.delete(id);
    const img = this.images.get(id);
    img?.delete();
    this.images.delete(id);
  };

  selectImage = (id: string) => {
    this.canvas.discardActiveObject();
    this.handleSelectionChange(id);
    const image = this.images.get(id);
    if (image) {
      this.canvas.setActiveObject(image.fabricObject); //id = 0, 1, 2 etc.
    }
    // // this.images.get(id)?.notifySelect();
    // this.deselectAllOtherImages(id);
    console.log('this canvas', { ...this.canvas, objects: [...this.canvas._objects] });
    this.canvas.renderAll();
  };

  getImage = (id: string) => {
    return this.images.get(id);
  };

  getAllImages = () => {
    return this.images;
  }

  deselectAllOtherImages = (id: string) => {
    for (const img of this.images.values()) {
      if (img.id !== id) {
        img.notifyDeselect();
      }
    }
    this.canvas.renderAll();
  };

  deselectAll = () => {
    for (const img of this.images.values()) {
        img.notifyDeselect();
    }
    this.canvas.renderAll();
  };

  loadPrimitive = (imageId: string, primId: string, data: Primitive) => {
    const image = this.images.get(imageId);
    if (image) {
      image.loadPrimitive(primId, data);
    }
  };

  deletePrimitive = (imageId: string, primId: string) => {
    const image = this.images.get(imageId);
    if (image) {
      image.deletePrimitive(primId);
    }
  };

  selectPrimitive = (imageId: string, primitiveId: string) => {
    this.canvas.discardActiveObject();
    this.handleSelectionChange(primitiveId);
    // const image = this.images.get(imageId);
    // image?.selectPrimitive(primitiveId);
    this.canvas.renderAll();
  };

  dumpCanvas = () => {
    this.loadingImages.clear();
    for (const image of this.images.values()) {
      image.delete();
    }
  };

  private updateImageFn: (id: string, data: Image) => void;

  setImageUpdateFn = (fn: (id: string, data: Image) => void) => {
    this.updateImageFn = fn;
  };

  private updatePrimFn: (id: string, data: Primitive) => void;

  setPrimUpdateFn = (fn: (id: string, data: Primitive) => void) => {
    this.updatePrimFn = fn;
  };

  private selectPrimFn: (imageId: string, primitiveID: string) => void;

  setSelectFn = (fn: (imageId: string, primitiveID: string) => void) => {
    this.selectPrimFn = fn;
  };
  getThumbnail = () => {
    const items = this.canvas.getObjects();
    const remove = [];
    items.forEach(obj => {
      if (obj.excludeFromExport) {
        remove.push(obj);
      }
    });
    remove.forEach(obj => {
      this.canvas.remove(obj);
    });
    for (const image of this.images.values()) {
      image.handleSelect();
    }

    const data = this.canvas.toDataURL();
    remove.forEach(obj => {
      this.canvas.add(obj);
    });
    for (const image of this.images.values()) {
      image.handleDeselect();
    }

    return data;
  };
  printAllObjects = () => {};
  /** Fabric Interface end*/

  canvas: fabric.Canvas;

  _center: Vector2;

  set center(val: Vector2) {
    this._center = val;
  }

  get center(): Vector2 {
    return this._center.clone();
  }

  centerDot: fabric.Circle;

  // relativeToCenter = (vec: Vector2): Vector2 => {
  //   return new Vector2(vec.x - this.center.x, vec.y - this.center.y);
  // };

  // relativeFromCenter = (vec: Vector2): Vector2 => {
  //   return new Vector2(this.center.x + vec.x, this.center.y + vec.y);
  // };

  getCanvas() {
    return this.canvas;
  }

  /** It returns the Fabric pattern containing a tileable gridline */
  getGridLinePattern(){
    return new fabric.Pattern(
      {source: isometricGrid, offsetX: this.center.x, offsetY: this.center.y});
  }

  setCanvas(canvas: fabric.Canvas) {
    this.canvas = canvas;

    // canvas panning, and zooming, from fabric examples
    this.canvas.on('mouse:down', function (opt) {
      var evt = opt.e;
      if (evt.altKey === true) {
        this.isDragging = true;
        this.selection = false;
        this.lastPosX = evt.clientX;
        this.lastPosY = evt.clientY;
      }
    });
    this.canvas.on('mouse:move', function (opt) {
      if (this.isDragging) {
        var e = opt.e;
        var vpt = this.viewportTransform;
        vpt[4] += e.clientX - this.lastPosX;
        vpt[5] += e.clientY - this.lastPosY;
        this.requestRenderAll();
        this.lastPosX = e.clientX;
        this.lastPosY = e.clientY;
      }
    });
    this.canvas.on('mouse:up', function (opt) {
      // on mouse up we want to recalculate new interaction
      // for all objects, so we call setViewportTransform
      this.setViewportTransform(this.viewportTransform);
      this.isDragging = false;
      this.selection = true;
    });
    this.canvas.on('mouse:wheel', function (opt) {
      var delta = opt.e.deltaY;
      var zoom = canvas.getZoom();
      zoom *= 0.999 ** delta;
      if (zoom > 20) zoom = 20;
      if (zoom < 0.01) zoom = 0.01;
      canvas.setZoom(zoom);
      opt.e.preventDefault();
      opt.e.stopPropagation();
    });

    this.canvas.on('selection:updated', this.handleFabricSelectionChange);
    this.canvas.on('selection:created', this.handleFabricSelectionChange);
    this.canvas.on('selection:cleared', this.handleFabricSelectionChange);
    this.canvas.on('object:moving', e => this.handleObject('notifyMove')(e.target.name));
    this.canvas.on('object:modified', e => this.handleObject('notifyModify')(e.target.name));

    this.canvas.setDimensions({ width: '800', height: '800' });
    this.canvas.selection = false;

    this.center = new Vector2(this.canvas.width * 0.5, this.canvas.height * 0.5);
    this.centerDot = new fabric.Circle({
      top: this.center.y,
      left: this.center.x,
      radius: 6,
      selectable: false,
      fill: 'red',
      opacity: 0.5,
      evented: false,
      originX: 'center',
      originY: 'center',
      excludeFromExport: true,
      name: 'front dot',
      hasControls: false,
      hasBorders: false,
    });
    this.canvas.add(this.centerDot);
  }

  setColorFilter = (hue: number, saturation: number, value: number) => {
    this.images.forEach(image => {
      //incoming Saturation is multiplier [0-2.0]
      //fabric wants [-1.0-1.0]
      image.setColorFilter(hue, saturation - 1.0, value - 1.0);
    });
    if (this.canvas) {
      this.canvas.renderAll();
    }
  };

  selection: string = null;

  handlers = new Map<string, FabricObjectHandlers>();

  addObject = (obj: fabric.Object, handlers: FabricObjectHandlers) => {
    this.handlers.set(obj.name, handlers);
    this.canvas.add(obj);
    this.canvas.bringToFront(this.centerDot);
  };

  handleObject = (action: keyof FabricObjectHandlers) => (id: string) => {
    const handler = this.handlers.get(id);
    if (handler) {
      handler[action]();
    }
  };

  handleFabricSelectionChange = (e: fabric.IEvent) => {
    this.handleSelectionChange(e.target?.name);
  };

  handleSelectionChange = (name?: string) => {
    if (this.selection) {
      this.handleObject('notifyDeselect')(this.selection);
    }
    if (name !== undefined) {
      this.selection = name;
      this.handleObject('notifySelect')(this.selection);
    } else {
      this.selection = null;
    }
  };

  handleDispose() {}
}
