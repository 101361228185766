import { Divider, Typography } from '@mui/material';
import { ColoredDot } from 'common/components/ColoredDot';
import { FlexColumn } from 'common/FlexColumn';
import { FlexRow } from 'common/FlexRow';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { EditableVec2 } from 'stores/editor/EditableVec2';
import { ValueEditor } from './ValueEditor';

type Vec2EditorProps = {
  value: EditableVec2;
};
export const Vec2Editor = observer((props: Vec2EditorProps) => {
  const { value: vec2 } = props;

  return (
    <FlexRow alignItems='baseline' position='relative'>
      {vec2.color && <ColoredDot color={vec2.color} />}
      <Typography sx={{ width: '100px' }}>{vec2.label}</Typography>
      <ValueEditor value={vec2.x} nested />
      <ValueEditor value={vec2.y} nested />
    </FlexRow>
  );
});
