import { IconButton, Paper, Typography } from '@mui/material';
import { FlexColumn } from 'common/FlexColumn';
import { FlexRow } from 'common/FlexRow';
import * as React from 'react';
import { useDropzone } from 'react-dropzone';
import { SelectEditor } from '../editor/SelectEditor';
import { useRoot } from '../RootProvider';
import { JobList } from './JobList';
import { useHistory } from 'react-router-dom';
import { ArrowBack, Cancel, DoneAll } from '@mui/icons-material';
import { Filler } from 'common/components/Filler';
import { AsyncIconButton } from 'common/components/AsyncIconButton';
import { AssetType } from 'stores/RootStore';

type CreatePageProps = {};
export const StructureBulkCreate = (props: CreatePageProps) => {
  const root = useRoot();
  const bulkStore = root.structureBulk;

  const history = useHistory();

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();

  React.useEffect(() => {
    //@ts-ignore
    bulkStore.submitFiles(acceptedFiles);
  }, [acceptedFiles]);

  return (
    <FlexColumn>
      <FlexRow sx={{ pt: 4 }} alignItems='baseline'>
        <IconButton
          onClick={() => {
            history.push('/');
          }}
        >
          <ArrowBack />
        </IconButton>
        <Typography variant='h2'>Bulk Structure Creation</Typography>
      </FlexRow>
      <FlexRow>
        <Filler />
        <AsyncIconButton onClick={bulkStore.startAll}>
          <DoneAll />
        </AsyncIconButton>
        <AsyncIconButton onClick={bulkStore.deleteAll}>
          <Cancel />
        </AsyncIconButton>
      </FlexRow>
      <Paper elevation={2}>
        <div {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} {...{ directory: '', webkitdirectory: '', type: 'file' }} />
          <p>Drag 'n' drop some folders here, or click to select folders</p>
        </div>
        <aside></aside>
      </Paper>
      <JobList type={AssetType.Floor} jobs={bulkStore.jobs} />
    </FlexColumn>
  );
};
