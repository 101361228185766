import { useState } from 'react';

/**
 * TODO: change any[] to VariadicCurry.
 * https://medium.com/@hernanrajchert/creating-typings-for-curry-using-ts-3-x-956da2780bbf
 */
export function useConfirmDialog(
  onConfirm: ((...value1: any[]) => void) | ((...value2: any[]) => Promise<void>),
) {
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [value, setValue] = useState<any[]>(null);
  const [showProgress, setShowProgress] = useState<boolean>(false);

  function handleInitAction(...args) {
    setValue(args);
    setShowConfirm(true);
  }

  function handleCancelAction() {
    setValue(null);
    setShowConfirm(false);
  }

  async function handleConfirmAction() {
    if (typeof onConfirm === 'function' && value) {
      setShowProgress(true);
      await onConfirm(...value);
    }
    setShowConfirm(false);
    setShowProgress(false);
  }

  return [showConfirm, handleInitAction, handleCancelAction, handleConfirmAction, showProgress] as [
    boolean,
    (...value2: any[]) => void,
    () => void,
    () => Promise<void>,
    boolean,
  ];
}
