import { Box, TextField, TextFieldProps, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { ColoredDot } from 'common/components/ColoredDot';
import { useTextField, useUrlField } from 'common/hooks/useTextField';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { EditableValue } from 'stores/editor/EditableValue';

type ValueEditorProps = {
  value: EditableValue<string>;
  autoFocus?: boolean;
  nested?: boolean;
  color?: string;
  small?: boolean;
};
export const ValueEditor = observer((props: ValueEditorProps) => {
  const { value: eValue, autoFocus, nested, small, color } = props;
  const inputRef = React.useRef<HTMLInputElement>(null);

  const [value, setValue] = React.useState(eValue.value ?? '');

  const onBlur = e => {
    console.debug('onBlur called', value);
    eValue.value = value;
  };

  const onChange = e => {
    setValue(e.target.value);
  };
  const hasError = Boolean(eValue.error);
  const errorProps: TextFieldProps = hasError ? { error: true, helperText: eValue.error } : null;

  React.useEffect(() => {
    setValue(eValue.value);
  }, [eValue.key]);

  const smallProps = small
    ? { variant: 'outlined' as const, label: eValue.label }
    : { variant: 'standard' as const };

  return (
    <Box display='flex' alignItems='baseline'>
      {color && <ColoredDot color={color} />}
      {!small && (
        <Typography sx={nested ? { color: grey[500] } : { width: '100px', mr: 1 }}>
          {eValue.label}
        </Typography>
      )}
      <TextField
        fullWidth={true}
        size={'small'}
        {...smallProps}
        inputRef={inputRef}
        value={value ?? ''}
        onChange={onChange}
        onBlur={onBlur}
        {...errorProps}
        autoFocus={autoFocus}
        onKeyDown={ev => {
          console.debug(ev.key);
          if (ev.key === 'Enter' || ev.key === 'Escape') {
            inputRef.current?.blur();
          }
        }}
        sx={{
          p: 1,
        }}
      />
    </Box>
  );
});
