import { FrameReferencePrimitive } from '@sophya/eyekia';
import { fabric } from 'fabric';
import { Vector2 } from 'vector_math.js';
import { DEFAULT_CIRCLE } from './FabricCircle';
import { FabricObjectBase, FabricObjectCallbacks } from './FabricObjectBase';
import { FabricPlane } from './FabricPlane';
import { FabricStore2, FacingFront, Placement } from './FabricStore2';

type PlaneOrientation = 'YZ' | 'XZ' | 'XY';
export class FabricFrame extends FabricObjectBase<fabric.Circle> {
  plane: FabricPlane;

  constructor(
    store: FabricStore2,
    fabricObject: fabric.Circle,
    private schema: FrameReferencePrimitive,
    callbacks?: FabricObjectCallbacks<FabricFrame>,
  ) {
    super(store, fabricObject, callbacks);

    let planeOrientation: PlaneOrientation = null;
    if (schema.placement == 'wall') {
      if (schema.facingDir == 'E') {
        planeOrientation = 'YZ';
      } else {
        planeOrientation = 'XZ';
      }
    } else {
      planeOrientation = 'XY';
    }

    //create zone
    this.plane = new FabricPlane(
      this.store,
      new fabric.Circle({
        ...DEFAULT_CIRCLE,
        radius: 8,
        left: this.x,
        top: this.y,
        excludeFromExport: true,
        hasControls: false,
        name: `${this.id}-frame`,
      }),
      planeOrientation,
      schema.shape,
      'black',
      {
        onMove: this.handleZoneModify,
        onModify: this.handleZoneModify,
        onSelect: this.notifySelect,
        onDeselect: this.notifyDeselect,
      },
    );

    this.children.add(this.plane);
    this.childrenToMove.add(this.plane);
    this.handleDeselect();
  }

  handleSelect = () => {
    this.plane.handleSelect();
    this.enable();
  };

  handleDeselect = () => {
    this.plane.handleDeselect();
    this.disable();
  };

  handleZoneModify = () => {
    this.callbacks?.onModify?.(this);
  };

  toSchema = (relativeTo: Vector2): FrameReferencePrimitive => {
    let facing: FacingFront;
    let placement: Placement;
    switch (this.plane.getPlane()) {
      case 'XY':
        facing = this.schema.facingDir;
        placement = 'floor';
        break;
      case 'XZ':
        facing = 'S';
        placement = 'wall';
        break;
      case 'YZ':
        facing = 'E';
        placement = 'wall';
        break;
    }

    return {
      type: 'frame',
      shape: this.plane.toSchema(relativeTo),
      facingDir: facing,
      placement,
      frameId: this.schema.frameId,
    };
  };
}
