import { ShadowPrimitive } from '@sophya/eyekia';
import { ISOPAL } from 'stores/fabric2/IsoPal';
import { Vector2 } from 'vector_math.js';
import { DEFAULT_CIRCLE } from './FabricCircle';
import { FabricObjectBase, FabricObjectCallbacks } from './FabricObjectBase';
import { FabricStore2 } from './FabricStore2';
import { FabricZone } from './FabricZone';
import { fabric } from 'fabric';
export class FabricShadow extends FabricObjectBase<fabric.Circle> {
  constructor(
    store: FabricStore2,
    fabricObject: fabric.Circle,
    private schema: ShadowPrimitive,
    callbacks?: FabricObjectCallbacks<FabricShadow>,
  ) {
    super(store, fabricObject, callbacks);

    //create zone
    this.zone = new FabricZone(
      this.store,
      new fabric.Circle({
        ...DEFAULT_CIRCLE,
        radius: 0,
        left: this.x,
        top: this.y,
        excludeFromExport: true,
        hasControls: false,
        name: `${this.id}-zone`,
      }),
      schema.shape,
      'black',
      {
        onMove: this.handleZoneModify,
        onModify: this.handleZoneModify,
        onSelect: this.notifySelect,
        onDeselect: this.notifyDeselect,
      },
    );

    this.children.add(this.zone);
    this.childrenToMove.add(this.zone);
    this.refreshShadow();
  }

  handleSelect = () => {
    this.zone.handleSelect();
  };

  handleDeselect = () => {
    this.zone.handleDeselect();
  };

  zone: FabricZone;

  rectShadow: FabricObjectBase<fabric.Polygon>;
  ellipseShadow: FabricObjectBase<fabric.Ellipse>;

  handleZoneModify = () => {
    this.callbacks?.onModify?.(this);
    this.refreshShadow();
  };

  refreshShadow = () => {
    if (this.schema.shadowType === 'rect') {
      this.refreshRectShadow();
    } else {
      this.refreshEllipseShadow();
    }
  };

  refreshRectShadow = () => {
    if (this.rectShadow) {
      this.children.delete(this.rectShadow);
      this.childrenToMove.delete(this.rectShadow);
      this.rectShadow.delete();
    }

    const points = this.zone.generateCorners();
    this.rectShadow = new FabricObjectBase(
      this.store,
      new fabric.Polygon(
        points.map(val => ({ x: val.x, y: val.y })),
        {
          fill: 'black',
          opacity: 0.5,
          selectable: false,
          evented: false,
          excludeFromExport: true,
        },
      ),
    );

    this.children.add(this.rectShadow);
    this.childrenToMove.add(this.rectShadow);
    this.rectShadow.fabricObject.sendToBack();
  };

  refreshEllipseShadow = () => {
    if (this.ellipseShadow) {
      this.children.delete(this.ellipseShadow);
      this.childrenToMove.delete(this.ellipseShadow);
      this.ellipseShadow.delete();
    }

    const dimensions = this.zone.generateDimensions();
    const start = this.zone.start;
    const end = this.zone.end;
    const height = end.y - start.y;
    const width = end.x - start.x;
    let skewX, angle, rx, ry;
    if (this.zone.start.x < this.zone.end.x) {
      skewX = -0.325;
      angle = 26.184;
      rx = dimensions.x * 0.4;
      ry = dimensions.y * 0.4;
    } else {
      skewX = 0.325;
      angle = -26.184;
      rx = dimensions.y * 0.4;
      ry = dimensions.x * 0.4;
    }

    const ellipse = new fabric.Ellipse({
      rx,
      ry,
      originX: 'center',
      originY: 'center',
      top: this.zone.start.y + height / 2,
      left: this.zone.start.x + width / 2,
      evented: false,
      fill: 'black',
      opacity: 0.5,
      centeredRotation: true,
      skewX,
      angle,
      excludeFromExport: true,
    });

    this.ellipseShadow = new FabricObjectBase(this.store, ellipse);
    this.children.add(this.ellipseShadow);
    this.childrenToMove.add(this.ellipseShadow);
    this.ellipseShadow.fabricObject.sendToBack();
  };

  toSchema = (relativeTo: Vector2): ShadowPrimitive => {
    return {
      shadowType: this.schema.shadowType,
      type: 'shadow',
      shape: this.zone.toSchema(relativeTo),
    };
  };
}
