import { ColorVariation, OrientationStructureKey, StructureImage } from "@sophya/eyekia";
import { recolorUrl } from 'common/VariationRenderer';
import { action, makeObservable, observable } from "mobx";
import { EditableValue } from "./editor/EditableValue";
import { EditableVec2, VEC_ZERO } from "./editor/EditableVec2";


export class StructureImageDO {
    public pivotPosition: EditableVec2;
    public url: EditableValue<string>;
    public displayUrl: string;
    currentColor: ColorVariation;

    saveURLSync = () => {
        this.onSave();
        this.setDisplay();
    }

    constructor(public orientation:OrientationStructureKey,private onSave: () => Promise<void>, public uploadImage: (img: File | Blob) => Promise<string>){
        makeObservable(this, {
            displayUrl: observable,
            setDisplay: action,
        })
        this.url = new EditableValue<string>(
            `URL:`,
            '',
            this.saveURLSync,
          );
          this.pivotPosition = new EditableVec2(
              'Pivot:',
              VEC_ZERO,
              this.onSave,
          )
    }

    recolor = async (variant: ColorVariation) => {
        this.currentColor = variant;
        this.setDisplay();
    }

    setDisplay= async() =>{
        if(this.hasURL()){
            this.displayUrl = await recolorUrl(this.url.value, this.currentColor);
        }else{
            this.displayUrl = "";
        }
    }


    updateFromSchema(image: StructureImage) {
        this.url = new EditableValue<string>(
            `URL:`,
            image.url,
            this.saveURLSync,
          );
          this.pivotPosition = new EditableVec2(
              'Pivot:',
              image.pivotPosition,
              this.onSave,
          )
        this.setDisplay();
    }

    hasURL = () => {
        return this.url.value.length >0;
    }

    toSchema = (): StructureImage => {
        return{
            pivotPosition: this.pivotPosition.toSchema(),
            url: this.url.value,
        }
    }

}