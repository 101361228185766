import { ContentCopy } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { client } from 'clients/eyekia';
import { AsyncButton } from 'common/components/AsyncButton';
import * as React from 'react';
import { AssetDO } from 'stores/Asset';
import { EditableValue } from 'stores/editor/EditableValue';

type DuplicateButtonProps = {
  asset: AssetDO;
};

export const DuplicateButton = (props: DuplicateButtonProps) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <IconButton onClick={handleClickOpen}>
        <ContentCopy />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          {'Do you want to duplicate asset ' + props.asset.name.value + '?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            This will generate a copy identical to this original object in the same category.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <AsyncButton
            variant='contained'
            type='button'
            onClick={async () => {
              await duplicateAsset(props.asset);
              handleClose();
            }}
            autoFocus
          >
            OK
          </AsyncButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const duplicateAsset = async (asset: AssetDO) => {
  const eyekia = client();
  const assetResponse = await eyekia.v1.assetCreate({});

  const assetId = assetResponse.data.assetId;
  const assetDuplicate = asset;
  assetDuplicate.name = new EditableValue<string>(
    'name',
    assetDuplicate.name.value + ' Copy',
    () => {},
  );
  assetDuplicate.id = assetId;

  //@ts-ignore
  const data = await eyekia.v1.assetUpdate(assetId, { data: assetDuplicate.toSchema() });
  assetDuplicate.modifiedSinceLastPublish = data.data.modifiedSinceLastPublish;
  window.location.reload();
};
