import { Vec2 } from '@sophya/eyekia';
import { isValidFloat } from 'common/validationFunctions';
import { EditableFloat } from './EditableValue';

export const VEC_ZERO = { x: 0, y: 0 };

export class EditableVec2 {
  constructor(
    public label: string,
    initialValue: { x: number; y: number },
    private onSubmit: (x: number, y: number) => void,
    public color: string = null,
  ) {
    this.x = new EditableFloat('x', String(initialValue.x), this.handleSubmit);

    this.y = new EditableFloat('y', String(initialValue.y), this.handleSubmit);
  }

  private handleSubmit = (val: string) => {
    this.onSubmit(Number(this.x.value), Number(this.y.value));
  };

  public toSchema = (): Vec2 => {
    return {
      x: Number(this.x.value),
      y: Number(this.y.value),
    };
  };

  x: EditableFloat;
  y: EditableFloat;
}
