import { Add, SaveAlt } from '@mui/icons-material';
import { Box, Dialog, Tooltip } from '@mui/material';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { useAssets } from 'components/useAssets';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import Dropzone from 'react-dropzone';
import { AssetDO } from 'stores/Asset';
import { AssetImportStore } from 'stores/AssetImportStore';
import { OrientationDO } from 'stores/Orientation';
import { AddExistingAssets } from './AddExistingAssets';
type ImportExistingAssetsProps = {
  asset: AssetDO;
};

export const ImportExistingAssets = observer((props: ImportExistingAssetsProps) => {
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    props.asset.openAssetImport();
  };
  const assets = useAssets();
  const importStore = new AssetImportStore(assets, props.asset);

  return (
    <>
      <div>
        <Tooltip title="Import existing assets">
          <IconButton onClick={handleClick}>
            <SaveAlt />
          </IconButton>
        </Tooltip>
      </div>
      <AddExistingAssets
        open={props.asset.assetImportOpen}
        onClose={props.asset.closeAssetImport}
        asset={props.asset}
        importStore={importStore}
      />
    </>
  );
});
