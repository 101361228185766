import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { AuthStore } from 'stores/AuthStore';
import { useComputedState } from 'common/hooks/useComputedState';

export const AuthContext = React.createContext<AuthStore>(null);
export function useAuth() {
  return React.useContext(AuthContext);
}

type AuthProviderProps = React.PropsWithChildren<{}>;

export const AuthProvider = observer((props: AuthProviderProps) => {
  const {} = props;

  const store = useComputedState(() => {
    const s = new AuthStore();
    return [s, () => s.dispose()];
  }, []);

  return <AuthContext.Provider value={store}>{props.children}</AuthContext.Provider>;
});
