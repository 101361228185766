import { Button, Dialog, DialogActions } from '@mui/material';
import { Box } from '@mui/system';
import { useAssets } from 'components/useAssets';
import { observer } from 'mobx-react-lite';
import React, { useState, useCallback, useRef, useEffect } from 'react';
import * as ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { AsyncButton } from './AsyncButton';

function canvasToBlob(canvas: HTMLCanvasElement): Promise<Blob | undefined> {
  return new Promise((resolve, reject) => {
    if (!canvas) {
      resolve(undefined);
    }

    canvas.toBlob(
      blob => {
        resolve(blob);
      },
      'image/png',
      1,
    );
  });
}

type ImageCropProps = {
  onCrop: (data: Blob) => Promise<void>;
  onCancel: () => void;
  imgDataUrl: string;
  aspectRatio?: number;
};

const ImageCrop = observer((props: ImageCropProps) => {
  const { onCrop, imgDataUrl, onCancel, aspectRatio = 1 } = props;

  const [upImg, setUpImg] = useState<string>(null);
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [crop, setCrop] = useState<Partial<ReactCrop.Crop>>({
    unit: '%' as const,
    width: 30,
    aspect: aspectRatio,
  });
  const [completedCrop, setCompletedCrop] = useState(null);

  useEffect(() => {
    setUpImg(imgDataUrl);
  }, [imgDataUrl]);

  const onLoad = useCallback(img => {
    imgRef.current = img;
  }, []);

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }

    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const new_crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');
    const pixelRatio = window.devicePixelRatio;

    canvas.width = new_crop.width * pixelRatio * scaleX;
    canvas.height = new_crop.height * pixelRatio * scaleY;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      image,
      new_crop.x * scaleX,
      new_crop.y * scaleY,
      new_crop.width * scaleX,
      new_crop.height * scaleY,
      0,
      0,
      new_crop.width * scaleX,
      new_crop.height * scaleY,
    );
  }, [completedCrop]);

  return (
    <>
      <ReactCrop.default
        src={upImg}
        onImageLoaded={onLoad}
        crop={crop}
        onChange={c => setCrop(c)}
        onComplete={c => setCompletedCrop(c)}
      />
      <Box display='flex' justifyContent='center' alignItems='center'>
        <canvas
          ref={previewCanvasRef}
          // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
          style={{
            width: Math.round(completedCrop?.width ?? 0),
            height: Math.round(completedCrop?.height ?? 0),
            backgroundColor: 'black',
            margin: '8px',
          }}
        />
      </Box>
      <DialogActions>
        <Button onClick={onCancel} color='secondary'>
          Cancel
        </Button>
        <AsyncButton
          variant='contained'
          type='button'
          disabled={!completedCrop?.width || !completedCrop?.height}
          onClick={() => canvasToBlob(previewCanvasRef.current).then(blob => blob && onCrop(blob))}
        >
          Save
        </AsyncButton>
      </DialogActions>
    </>
  );
});

export default ImageCrop;
