import { Label } from "@mui/icons-material";
import { Box } from "@mui/material";
import { ImageUrlEditor } from "components/editor/ImageUrlEditor";
import { Vec2Editor } from "components/editor/Vec2Editor";
import { observer } from "mobx-react-lite";
import { StructureImageDO } from "stores/StructureImage";

type StructureImagePanelProps = {
  image: StructureImageDO;
};
export const StructureImagePanel = observer((props: StructureImagePanelProps) => {
  const { image } = props;


  return (
    <Box  margin='20px'>
      <span>Ori: {image.orientation}</span>
      <ImageUrlEditor value={image.url} uploadFunction={image.uploadImage} canDelete={true}/>
      <Vec2Editor value={image.pivotPosition} />
      <img
        src={image.displayUrl}
        width='170px'
        crossOrigin='anonymous'
      ></img>
    </Box>
  );
});
