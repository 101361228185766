import {
  Typography,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Checkbox,
  Button,
  Avatar,
  Stack,
  Badge,
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { OrientationKey } from 'stores/Orientation';
import { useAssets } from '../useAssets';

type AssetPreviewProps = {
  uid: string;
  assetId: string;
  handleCheck: (id: string) => void;
  handleRotate?: (id: string) => void;
  handleSet?: (id: string) => void;
  isChecked: boolean;
  orientationMap?: Map<OrientationKey, OrientationKey>;
};
export const CheckedAssetPreview = observer((props: AssetPreviewProps) => {
  const assets = useAssets();

  const asset = assets.getAsset(props.assetId);
  const [checked, setChecked] = React.useState(props?.isChecked);

  const handleCheck = event => {
    props?.handleCheck(props.uid);
  };

  const handleRotate = event => {
    props?.handleRotate(props.uid);
  };

  const handleSet = event => {
    props?.handleSet(props.uid);
  };

  const styles = {
    card: {
      height: '45%',
      width: '30%',
      margin: 0.5,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
  };

  return (
    <Card sx={styles.card}>
      <CardMedia component='img' height='140' image={asset.thumbnail} />

      <CardContent>
        <Typography gutterBottom variant='h6' component='h2'>
          {asset?.name.value || props.assetId}
        </Typography>
        <Typography component='p' color='text.secondary'>
          {asset?.description?.value}
        </Typography>
        <Typography>
          {props.orientationMap &&
            Array.from(props.orientationMap.entries()).map(([keyDest, keySource]) => (
              <Stack direction="row" spacing={4}>
                <Badge
                overlap="circular"
                color="success"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                badgeContent={
                  keySource
                }
                >
                  <Avatar src={[...asset.getOrientation(keySource).images][0][1].url.value} sx={{ width: 60, height: 60 }}/>
                </Badge>
                {'->'} {keyDest}
                <br></br>
              </Stack>
            ))}
        </Typography>
      </CardContent>
      <CardActions>
        <Checkbox checked={props.isChecked} onClick={handleCheck} />
        {props.orientationMap && <Button onClick={handleRotate}>Rotate</Button>}
        {props.orientationMap && <Button onClick={handleSet}>Edit</Button>}
      </CardActions>
    </Card>
  );
});
