import React from 'react';
type Dispose = () => void;
/**
 * Generate a disposable state that's computed when any dependencies change.
 * @param compute Compute function returns the new computed state and the dispose function for the new state.
 * @param deps Array of dependencies for the compute function.
 */
export function useComputedState<T>(compute: () => [T, Dispose], deps: any[]) {
  const [state, setState] = React.useState(compute);

  const firstRef = React.useRef(true);
  React.useEffect(() => {
    if (state) {
      const [value, dispose] = state;
      if (firstRef.current) {
        // this is the first effect. return the current dispose function.
        firstRef.current = false;
        return dispose;
      } else {
        // generate new state
        const newState = compute();
        setState(newState);
        const callback = newState[1];
        if (callback) return callback;
      }
    }
  }, deps);
  // return the state value.
  return state && state[0];
}
