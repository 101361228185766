import { Box, BoxProps } from '@mui/system';
import * as React from 'react';

type FlexColumnProps = React.PropsWithChildren<
  BoxProps & {
    fullSize?: boolean;
  }
>;
export const FlexColumn = (props: FlexColumnProps) => {
  const { children, fullSize = false, ...boxProps } = props;

  const fullSizeProps = fullSize ? { width: '100%', height: '100%' } : {};

  return (
    <Box {...fullSizeProps} display='flex' flexDirection='column' {...boxProps}>
      {props.children}
    </Box>
  );
};
