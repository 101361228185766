import Add from '@mui/icons-material/Add';
import { IconButton } from '@mui/material';
import { AsyncIconButton } from 'common/components/AsyncIconButton';
import * as React from 'react';
import { AutoId } from '../../common/AutoId';
import { useAssets } from '../useAssets';

type NewAssetButtonProps = {};
export const NewAssetButton = (props: NewAssetButtonProps) => {
  const assetStore = useAssets();

  return (
    <AsyncIconButton onClick={assetStore.handleCreateAsset}>
      <Add />
    </AsyncIconButton>
  );
};
