import { Check, Close } from '@mui/icons-material';
import { Card, IconButton, TextField, Typography } from '@mui/material';
import { AsyncIconButton } from 'common/components/AsyncIconButton';
import { Filler } from 'common/components/Filler';
import { FlexRow } from 'common/FlexRow';
import { SelectEditor } from 'components/editor/SelectEditor';
import { ValueEditor } from 'components/editor/ValueEditor';
import { useRoot } from 'components/RootProvider';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { AssetCreationJob } from 'stores/creation/CreationJob';

type JobPreviewProps = { job: AssetCreationJob };
export const JobPreview = observer((props: JobPreviewProps) => {
  const { job } = props;

  const root = useRoot();
  const creationStore = root.creation;
  const categories = root.categories;

  const status = job.status;

  return (
    <Card sx={{ mt: 1, mb: 1 }}>
      <FlexRow sx={{ mt: 2, mb: 2, pl: 2, pr: 2 }}>
        {status === 'finished' && <Typography sx={{ mr: 1 }}>{job.assetId}</Typography>}
        <TextField value={`./${job.folder}/`} label={'folder'} disabled size='small' />
        <ValueEditor value={job.name} small />
        <SelectEditor value={job.category} getDisplay={categories.getName} small />
        <Typography>
          {`${job.numDirections} directions: [ ${[...job.directions.values()].join(', ')} ]`}
        </Typography>
        <Filler />
        {status === 'finished' && <Typography>Done!</Typography>}
        {status !== 'finished' && (
          <AsyncIconButton onClick={() => job.startJob()}>
            <Check></Check>
          </AsyncIconButton>
        )}
        {status === 'init' && (
          <IconButton onClick={() => creationStore.deleteJob(job.key)}>
            <Close />
          </IconButton>
        )}
      </FlexRow>
    </Card>
  );
});
