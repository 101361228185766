import { Image, Primitive } from '@sophya/eyekia';
import { AssetStore } from './AssetStore';
import { BaseStore } from './BaseStore';
import { CategoryStore } from './CategoryStore';
import { Primitive as PrimitiveType } from '@sophya/eyekia';
import { BulkCreationStore } from './BulkCreationStore';
import { FabricStore2 } from './fabric2/FabricStore2';
import { StructureStore } from './StructureStore';
import { StructureBulkStore } from './StructureBulkStore';

export type FabricInterface = {
  loadImage: (id: string, data: Image) => void;
  deleteImage: (imageID: string) => void;
  selectImage: (imageID: string) => void;
  loadPrimitive: (imageID: string, primID: string, data: PrimitiveType) => void;
  deletePrimitive: (imageID: string, primID: string) => void;
  selectPrimitive: (id: string, primitiveID: string) => void;
  dumpCanvas: () => void;
  setImageUpdateFn: (fn: (id: string, data: Image) => void) => void;
  setPrimUpdateFn: (fn: (id: string, data: Primitive) => void) => void;
  setSelectFn: (fn: (imageId: string, primitiveID: string) => void) => void;
  getThumbnail: () => string;
  printAllObjects: () => void;
  setColorFilter: (hue: number, saturation: number, value: number) => void;
};

export type ConfigInterface = {
  getFrameList: () => string[];
  updateFrameSize: (frameId: string, size: { x: number; y: number }) => void;
};

export enum AssetType {
  Furniture,
  Wall,
  Floor,
}

export class RootStore extends BaseStore {
  fabric: FabricStore2 = new FabricStore2();
  assets: AssetStore;
  structures: StructureStore;
  categories = new CategoryStore();
  creation: BulkCreationStore;
  structureBulk: StructureBulkStore;
  collator = new Intl.Collator();

  constructor() {
    super();
    this.assets = new AssetStore(this.fabric, this);
    this.structures = new StructureStore(this);
    this.creation = new BulkCreationStore(this);
    this.structureBulk = new StructureBulkStore(this);
  }

  handleDispose(): void {
    this.fabric.dispose();
    this.assets.dispose();
    this.creation.dispose();
    this.structureBulk.dispose();
    this.structures.dispose();
  }
}
