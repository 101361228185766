import { AutoId } from 'common/AutoId';
import { action, makeObservable, observable } from 'mobx';
import { AssetDO } from './Asset';
import { AssetStore } from './AssetStore';
import { BaseStore } from './BaseStore';
import { OrientationKey } from './Orientation';

type AssetImportData = {
  assetId: string;
  isChecked: boolean;
  orientationMap: Map<OrientationKey, OrientationKey>;
  possibleOrientations: OrientationKey[];
};
export class AssetImportStore extends BaseStore {
  potentialAssets = new Set<string>();
  stagedAssets = new Map<string, AssetImportData>();

  constructor(private assets: AssetStore, private asset: AssetDO) {
    super();
    makeObservable(this, {
      potentialAssets: observable,
      stagedAssets: observable,
      togglePotentialAsset: action,
      toggleStagedAsset: action,
      stagePotentialAssets: action,
      unstageAssets: action,
      unstageAllAssets: action,
    });
  }

  potentialContains = (assetId: string) => {
    return this.potentialAssets.has(assetId);
  };

  getStagedAssets = () => {
    return Array.from(this.stagedAssets.entries());
  };

  hasPotentialAssets = () => {
    return this.potentialAssets.size > 0;
  };

  hasStagedAssets = () => {
    return this.stagedAssets.size > 0;
  };

  hasStagedCheckedAssets = () => {
    return this.getStagedAssets().filter(([uid, data]) => data.isChecked).length > 0;
  };

  togglePotentialAsset = (assetId: string) => {
    if (this.potentialAssets.has(assetId)) {
      this.potentialAssets.delete(assetId);
    } else {
      this.potentialAssets.add(assetId);
    }
  };

  toggleStagedAsset = (uid: string) => {
    const entry = this.stagedAssets.get(uid);
    entry.isChecked = !entry.isChecked;
    this.stagedAssets.set(uid, entry);
  };

  rotateStagedAsset = (uid: string) => {
    const entry = this.stagedAssets.get(uid);
    entry.orientationMap.forEach((valueSource, keyDest) => {
      const assetOrientation = this.assets.getNextOrientation(entry.assetId, valueSource);
      if (assetOrientation) {
        entry.orientationMap.set(keyDest, assetOrientation.orientation);
      }
    });

    this.stagedAssets.set(uid, entry);
  };

  getStagedAssetData = (uid: string) => {
    return this.stagedAssets.get(uid);
  };

  setStagedAssetMappings = (uid: string, mappings: Map<OrientationKey, OrientationKey>) => {
    const entry = this.stagedAssets.get(uid);
    entry.orientationMap = mappings;
    this.stagedAssets.set(uid, entry);
  };

  stagePotentialAssets = () => {
    this.potentialAssets.forEach(assetId => {
      const orientationMap = new Map<OrientationKey, OrientationKey>();
      const asset = this.assets.getAsset(assetId);
      this.asset.orientations.forEach((_, orientationDestination) => {
        const assetOrientation = this.assets.getBestOrientation(assetId, orientationDestination);
        if (assetOrientation) {
          orientationMap.set(orientationDestination, assetOrientation.orientation);
        }
      });

      this.stagedAssets.set(AutoId.newId(), {
        assetId,
        isChecked: false,
        orientationMap,
        possibleOrientations: [...asset.orientations.keys()],
      });
    });
    this.potentialAssets.clear();
  };

  unstageAssets = () => {
    const toRemove = Array.from(this.stagedAssets.entries())
      .filter(([uid, data]) => data.isChecked)
      .map(([uid]) => uid);
    toRemove.forEach(uid => this.stagedAssets.delete(uid));
  };

  unstageAllAssets = () => {
    this.stagedAssets.clear();
  };

  handleImportAssets = () => {
    this.stagedAssets.forEach(data => {
      [...data.orientationMap.keys()].forEach(orientation => {
        const assetOrientation = this.assets.getOrientation(
          data.assetId,
          data.orientationMap.get(orientation) ?? 'N',
        );
        if (assetOrientation) {
          assetOrientation.images.forEach(image => {
            this.asset.orientations.get(orientation).createImageFromExisting(image, this.assets.getAsset(data.assetId).config);
          });
        }
      });
    });
    this.asset.refreshCanvas();
  };

  protected handleDispose(): void {}
}
