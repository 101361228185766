import { AutoId } from 'common/AutoId';
import { Vector2 } from 'vector_math.js';
import { FabricStore2 } from './FabricStore2';
import { fabric } from 'fabric';

const DEBUG = false;

/**
 * Type defintions for the optional callbacks that can be passed to any fabricObject
 */
export type FabricObjectCallbacks<TSelf> = {
  onMove?: (obj: TSelf, delta: Vector2) => void;
  onModify?: (obj: TSelf) => void;
  onSelect?: (obj: TSelf) => void;
  onDeselect?: (obj: TSelf) => void;
};

/**
 * Type defintions for callbacks to be compatible with the signaling we have
 * setup on the canvas.
 */
export type FabricObjectHandlers = {
  notifyMove: () => void;
  notifyModify: () => void;
  notifySelect: () => void;
  notifyDeselect: () => void;
};

/**
 * This is the object base for the entire fabric stack. This implements the fundamental
 * behaviors that everything else relies on.
 *
 * In particular this:
 * - propagates deletion and movement to it's children.
 * - propagates actions to it's parent through optional callbacks
 * - allows overriding of actions by subclasses.
 * - auto registers itself with the FabricStore2 to be notified on actions
 *
 * That means, when creating a subclass there are three questions you must ask yourself:
 * 1. Do I have to create any children? Do I want them to move with me? Do I want
 * Them to notify me of anything?
 * 2. Do I need to implement any special behavior when an action is done to me?
 * (via the `handle${action}` functions)
 * 3. Do I need to create a typed toSchema function for my parent to take data out of me?
 */
export class FabricObjectBase<TObject extends fabric.Object> implements FabricObjectHandlers {
  constructor(
    protected store: FabricStore2,
    public fabricObject: TObject,
    protected callbacks?: FabricObjectCallbacks<FabricObjectBase<TObject>>,
  ) {
    this.pastLocation = new Vector2(this.x ?? 0, this.y ?? 0);
    this.store.addObject(this.fabricObject, this);
    if (this.id === undefined && this.fabricObject.selectable) {
      console.warn(`fabricObjectBase has ${this.id} as an id!`, this);
    }
  }

  static fromClass<StaticObject extends fabric.Object, StaticOptions extends fabric.IObjectOptions>(
    store: FabricStore2,
    fabricClass: new (options?: StaticOptions) => StaticObject,
    options?: StaticOptions,
    id = AutoId.newId(),
    callbacks?: FabricObjectCallbacks<FabricObjectBase<StaticObject>>,
  ): FabricObjectBase<StaticObject> {
    return new FabricObjectBase(store, new fabricClass({ ...options, name: id }), callbacks);
  }

  pastLocation: Vector2;

  protected children = new Set<FabricObjectBase<fabric.Object>>();
  protected childrenToMove = new Set<FabricObjectBase<fabric.Object>>();

  notifyMove = () => {
    const currentLocation = new Vector2(this.x, this.y);
    const currentCopy = currentLocation.clone();
    const delta = currentCopy.sub(this.pastLocation);
    DEBUG && console.log('notifyMove', this.id, delta);
    this.pastLocation = currentLocation;
    for (const child of this.childrenToMove.values()) {
      child.move(delta);
    }
    this.handleMove(delta);
    this.callbacks?.onMove?.(this, delta);
    this.callbacks?.onModify?.(this);
  };

  handleMove = (delta: Vector2) => {};

  notifyModify = () => {
    DEBUG && console.log('notifyModify', this.id);
    this.handleModify();
    this.callbacks?.onModify?.(this);
  };

  handleModify = () => {};

  notifySelect = () => {
    DEBUG && console.log('notifySelect', this.id);
    this.handleSelect();
    this.callbacks?.onSelect?.(this);
  };

  handleSelect = () => {};

  notifyDeselect = () => {
    DEBUG && console.log('notifyDeselect', this.id);
    this.handleDeselect();
    this.callbacks?.onDeselect?.(this);
  };

  handleDeselect = () => {};

  move = (delta: Vector2) => {
    this.x += delta.x;
    this.y += delta.y;
    this.fabricObject.setCoords();
    for (const child of this.childrenToMove.values()) {
      child.move(delta);
    }
  };

  enable = () => {
    this.fabricObject.visible = true;
    this.fabricObject.evented = true;
    this.fabricObject.selectable = true;
    this.fabricObject.set('dirty', true);
  };

  disable = () => {
    this.fabricObject.visible = false;
    this.fabricObject.evented = false;
    this.fabricObject.selectable = false;
    this.fabricObject.set('dirty', true);
  };

  // relativeTo = (vec: Vector2): Vector2 => {
  //   return new Vector2(vec.x - this.x, vec.y - this.y);
  // };

  // relativeFrom = (vec: Vector2): Vector2 => {
  //   return new Vector2(this.x + vec.x, this.y + vec.y);
  // };

  // absoluteFrom = (vec: Vector2): Vector2 => {
  //   return this.store.relativeToCenter(this.relativeFrom(vec));
  // };

  delete() {
    for (const child of this.children.values()) {
      child.delete();
    }
    this.store.canvas.remove(this.fabricObject);
  }

  /** Convenient getters and setters */

  get id() {
    return this.fabricObject.name;
  }

  get x() {
    return this.fabricObject.left;
  }

  set x(val: number) {
    this.fabricObject.left = val;
  }

  get y() {
    return this.fabricObject.top;
  }

  set y(val: number) {
    this.fabricObject.top = val;
  }

  get scaleX() {
    return this.fabricObject.scaleX;
  }

  set scaleX(val: number) {
    this.fabricObject.scaleX = val;
  }

  get scaleY() {
    return this.fabricObject.scaleY;
  }

  set scaleY(val: number) {
    this.fabricObject.scaleY = val;
  }

  get angle() {
    return this.fabricObject.angle;
  }

  set angle(val: number) {
    this.fabricObject.angle = val;
  }

  get visible() {
    return this.fabricObject.visible;
  }

  set visible(val: boolean) {
    this.fabricObject.visible = val;
  }

  position(): Vector2 {
    return new Vector2(this.x, this.y);
  }
}
