import { Box } from '@mui/system';
import * as React from 'react';
import { Route, Switch, BrowserRouter as Router } from 'react-router-dom';
import { AssetType } from 'stores/RootStore';
import { AssetBrowser } from './AssetBrowser';
import { AssetEditorPage } from './AssetEditorPage';
import { StructureBrowser } from './assets/StructureBrowser';
import { AuthPage } from './AuthPage';
import { CategoryPage } from './CategoryPage';
import { CreatePage } from './creation/CreatePage';
import { StructureBulkCreate } from './creation/StructureBulk';
import { StructureEditorPage } from './StructureEditorPage';
import { HomePage } from './HomePage';
import { LogoutPage } from './LogoutPage';
import { RootProvider } from './RootProvider';

type BasePageProps = {};
export const BasePage = (props: BasePageProps) => {
  return (
    <Box
      width='100vw'
      height='100vh'
      sx={
        {
          // bgcolor: 'background.paper',
        }
      }
    >
      <Switch>
        <Route path='/logout'>
          <LogoutPage />
        </Route>
        <Route path='*'>
          <AuthPage>
            <RootProvider>
              <Switch>
                <Route exact path='/'>
                  <HomePage />
                </Route>
                <Route exact path='/asset'>
                  <AssetBrowser />
                </Route>

                <Route exact path='/categories'>
                  <CategoryPage />
                </Route>
                <Route path='/asset/:assetId'>
                  <AssetEditorPage />
                </Route>
                <Route exact path='/floor'>
                  <StructureBrowser type={AssetType.Floor} />
                </Route>
                <Route exact path='/wall'>
                  <StructureBrowser type={AssetType.Wall} />
                </Route>
                <Route path='/floor/:structureId'>
                  <StructureEditorPage type={AssetType.Floor} />
                </Route>
                <Route path='/wall/:structureId'>
                  <StructureEditorPage type={AssetType.Wall} />
                </Route>
                <Route exact path='/structure'>
                  <StructureBulkCreate />
                </Route>
                <Route path='/create'>
                  <CreatePage />
                </Route>
              </Switch>
            </RootProvider>
          </AuthPage>
        </Route>
      </Switch>
    </Box>
  );
};
