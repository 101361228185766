import { FlexColumn } from 'common/FlexColumn';
import { D2TransformEditor } from 'components/editor/D2TransformEditor';
import { IsoShapeEditor } from 'components/editor/IsoShapeEditor';
import { SelectEditor } from 'components/editor/SelectEditor';
import { ValueEditor } from 'components/editor/ValueEditor';
import { Vec2Editor } from 'components/editor/Vec2Editor';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { ShapePrimitiveDO } from 'stores/Primitive';

type ShapePrimitivePanelProps = {
  primitive: ShapePrimitiveDO;
};

export const ShapePrimitivePanel = observer((props: ShapePrimitivePanelProps) => {
  const { primitive } = props;

  return (
    <FlexColumn>
      <IsoShapeEditor value={primitive.shape} />
      <ValueEditor value={primitive.height} />
      <SelectEditor value={primitive.colliderType} />
    </FlexColumn>
  );
});
