import { client } from 'clients/eyekia';
import { AutoId } from 'common/AutoId';
import { action, makeObservable, observable, runInAction } from 'mobx';
import {
  Primitive,
  Orientation,
  Image,
} from '@sophya/eyekia';
import SmartSet from '../common/SmartSet';
import { uploadImageToAsset } from '../common/uploadImageToAsset';
import { AssetConfigDO } from './AssetConfig';
import { ImageDO, ImageIds } from './Image';
import { AssetType, ConfigInterface, FabricInterface } from './RootStore';
import { FramePrimitiveDO } from './Primitive';

export type OrientationKey = 'N' | 'NE' | 'E' | 'SE' | 'S' | 'SW' | 'W' | 'NW';

export function orientationClockwise(current: OrientationKey): OrientationKey {
  switch (current) {
    case 'N':
      return 'NE';
    case 'NE':
      return 'E';
    case 'E':
      return 'SE';
    case 'SE':
      return 'S';
    case 'S':
      return 'SW';
    case 'SW':
      return 'W';
    case 'W':
      return 'NW';
    case 'NW':
      return 'N';
    default:
      return 'N';
  }
}

export function orientationOpposite(current: OrientationKey): OrientationKey {
  switch (current) {
    case 'N':
      return 'S';
    case 'NE':
      return 'SW';
    case 'E':
      return 'W';
    case 'SE':
      return 'NW';
    case 'S':
      return 'N';
    case 'SW':
      return 'NE';
    case 'W':
      return 'E';
    case 'NW':
      return 'SE';
    default:
      return 'N';
  }
}

type PrimitiveLayout = Primitive;

export const OrientationKeys = new SmartSet<OrientationKey>([
  'N',
  'NE',
  'E',
  'SE',
  'S',
  'SW',
  'W',
  'NW',
]);

export function isOriKey(value: string): value is OrientationKey {
  return OrientationKeys.has(value as OrientationKey);
}

export class OrientationDO {
  constructor(
    public assetId: string,
    public orientation: OrientationKey,
    private fabric: FabricInterface,
    private config: ConfigInterface,
    private onSave: () => Promise<void>,
  ) {
    makeObservable(this, {
      images: observable,
      imageUploadOpen: observable,
      currentImage: observable,
      selectImage: action,
    });
  }

  cycleImage = () => {
    const imageKeys = Array.from(this.images.keys());
    const index = imageKeys.findIndex(key => this.currentImage === key);
    if (index === -1) {
      return;
    }
    const nextIndex = (index + 1) % imageKeys.length;
    const newImage = imageKeys[nextIndex];
    console.log({
      orientationKeys: imageKeys,
      index,
      nextIndex,
      newOrientation: newImage,
    });
    this.selectImage(newImage);
    this.selectToFabric();
  };

  currentImage: string = null;

  images: Map<string, ImageDO> = new Map();

  imageUploadOpen: boolean = false;
  imageDoIds: ImageIds;

  openImageUpload = (): void => {
    let imageIds: ImageIds = {
      assetId: this.assetId,
      orientationKey: this.orientation,
      imageId: AutoId.newId(),
    };

    this.imageUploadOpen = true;
    this.imageDoIds = imageIds;
  };

  closeImageUpload = (): void => {
    this.imageUploadOpen = false;
  };

  handleImageDrop(files: File[]) {
    runInAction(() => {
      this.imageUploadOpen = false;
    });
    //console.log(files);
    if (files.length == 0) {
      console.log('no files submitted');
      return;
    }
    const f = files[0];
    const type = f.type;
    if (!(type === 'image/png')) {
      console.log('file type wrong; was -' + type + '-');
      return;
    }
    this.networkCreateImage(f);
  }

  networkCreateImage = async (img: File): Promise<void> => {
    const downloadUrl = await uploadImageToAsset(this.assetId, AssetType.Furniture, img);

    this.createImageWithUrl(downloadUrl);
  };

  private createImageWithUrl(url: string) {
    let image = new ImageDO(this.fabric, this.config, this.imageDoIds, this.onSave);
    image.setUrl(url);
    this.onSave();
    let id = this.imageDoIds.imageId;
    this.images.set(id, image);
    this.currentImage = id;
    this.fabric.deleteImage(id);
    this.fabric.loadImage(id, image.toSchema());
  }

  public createImageFromExisting(source: ImageDO, sourceConfig: AssetConfigDO) {
    let parentConfig : AssetConfigDO = this.config as AssetConfigDO;

    //We add the new frames from the source asset at the Asset parent level
    let frameIDs = new Map<string,string>();
    sourceConfig.frames.forEach((frameSource, idSource) => {
      const frameId = parentConfig.createNewFrameConfig();
      parentConfig.frames.get(frameId).updateFromSchema(frameSource.toSchema());
      frameIDs.set(idSource, frameId);
    });

    let image = new ImageDO(this.fabric, parentConfig, this.imageDoIds, this.onSave);
    image.updateFromSchema(source.toSchema());
    //We create original IDs for the primitives
    [...image.primitives.keys()].forEach(id => {
      const newId = AutoId.newId();
      let primitiveCopy = image.getPrimitive(id);
      //We replace the original frame parent for the new one that we created above
      if(primitiveCopy.type === 'frame' && frameIDs.has((primitiveCopy as FramePrimitiveDO).frameId.value)){
        (primitiveCopy as FramePrimitiveDO).frameId.value = frameIDs.get((primitiveCopy as FramePrimitiveDO).frameId.value);
      }
      image.primitives.set(newId, primitiveCopy);
      image.primitives.delete(id);
    });
    this.onSave();

    let id = AutoId.newId();
    this.images.set(id, image);
  }

  deleteImage = async (id: string) => {
    runInAction(() => {
      this.images.delete(id);
      if (id === this.currentImage) {
        this.currentImage = null;
      }
      this.fabric.deleteImage(id);
    });
    await client().v1.assetOrientationImageDelete(this.assetId, this.orientation, id);
  };

  getImage(imageId: string): ImageDO | undefined {
    return this.images.get(imageId);
  }

  selectImage(id: string) {
    this.currentImage = id;
    this.images.get(id)?.select();
  }

  selectToFabric() {
    this.fabric.selectImage(this.currentImage);
  }

  populateCanvas() {
    console.log('populating canvas', this.images);
    this.images.forEach((pri, id) => {
      this.fabric.loadImage(id, pri.toSchema());
    });
    if (this.images.size > 0) {
      this.currentImage = this.images.keys().next().value;
    }
  }

  updateFromSchema(ori: Orientation) {
    for (const [id, data] of Object.entries(ori.images)) {
      let imageIds: ImageIds = {
        assetId: this.assetId,
        orientationKey: this.orientation,
        imageId: id,
      };
      let image = new ImageDO(this.fabric, this.config, imageIds, this.onSave);
      image.updateFromSchema(data);
      this.images.set(id, image);
    }
    this.currentImage = this.images.keys().next().value;
  }

  toSchema = (): Orientation => {
    const p: {
      [key: string]: Image;
    } = {};
    this.images.forEach((value, key) => {
      p[key] = value.toSchema();
    });

    return { images: p };
  };
}
