import { ChevronRight, DeleteForever, KeyboardArrowDown } from '@mui/icons-material';
import { Collapse, Divider, IconButton, Typography } from '@mui/material';
import { FlexRow } from 'common/FlexRow';
import { motion } from 'framer-motion';
import * as React from 'react';
import { Filler } from './Filler';

type CollapsableSectionProps = React.PropsWithChildren<{
  label?: string;
  closed?: boolean;
  onDelete?: () => void;
}>;
export const CollapsableSection = (props: CollapsableSectionProps) => {
  const [isOpen, setOpen] = React.useState(props.closed === undefined ? true : closed);

  return (
    <>
      <FlexRow>
        {props.label && (
          <>
            <Typography>{props.label}</Typography> <Filler />
          </>
        )}
        {props.onDelete && (
          <IconButton onClick={props.onDelete}>
            <DeleteForever />
          </IconButton>
        )}
        <IconButton onClick={() => setOpen(val => !val)}>
          {isOpen ? <KeyboardArrowDown /> : <ChevronRight />}
        </IconButton>
      </FlexRow>
      <Divider />
      <motion.div
        animate={{ height: isOpen ? 'auto' : '0', opacity: isOpen ? 1 : 0 }}
        style={{ paddingLeft: '40px' }}
      >
        {props.children}
      </motion.div>
    </>
  );
};
