import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import { AsyncButton } from 'common/components/AsyncButton';
import { useTextField } from 'common/hooks/useTextField';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { ValueEditor } from './editor/ValueEditor';
import { useRoot } from './RootProvider';

type CategoryEditorProps = {};
export const CategoryEditor = observer((props: CategoryEditorProps) => {
  const root = useRoot();
  const categories = root.categories;

  const [value, setValue, textFieldProps] = useTextField({});

  React.useEffect(() => {
    if (categories.categoryIdToEdit) {
      setValue(categories.categories.get(categories.categoryIdToEdit)?.name.value);
    }
    return () => {
      setValue('');
    };
  }, [categories.categoryIdToEdit]);

  const category = categories.get(categories.categoryIdToEdit);

  if (!category) {
    categories.handleCloseEditor();
    return null;
  }

  return (
    <Dialog open={categories.categoryEditorOpen}>
      <DialogTitle>Edit Category</DialogTitle>
      <DialogContent>
        <ValueEditor value={category.name} />
      </DialogContent>
      <DialogActions>
        <Button
          variant='outlined'
          onClick={() => {
            categories.handleCloseEditor();
          }}
        >
          Cancel
        </Button>
        <AsyncButton
          variant='contained'
          onClick={() => {
            return categories
              .handleSaveCategory(categories.categoryIdToEdit)
              .then(categories.handleCloseEditor);
          }}
        >
          Save
        </AsyncButton>
      </DialogActions>
    </Dialog>
  );
});
