import { IconButton, Paper, Typography } from '@mui/material';
import { FlexColumn } from 'common/FlexColumn';
import { FlexRow } from 'common/FlexRow';
import * as React from 'react';
import { useDropzone } from 'react-dropzone';
import { SelectEditor } from '../editor/SelectEditor';
import { useRoot } from '../RootProvider';
import { JobList } from './JobList';
import { useHistory } from 'react-router-dom';
import { ArrowBack, Cancel, DoneAll } from '@mui/icons-material';
import { Filler } from 'common/components/Filler';
import { AsyncIconButton } from 'common/components/AsyncIconButton';
import { AssetType } from 'stores/RootStore';

type CreatePageProps = {};
export const CreatePage = (props: CreatePageProps) => {
  const root = useRoot();
  const categories = root.categories;

  const history = useHistory();

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();

  console.log('accepted files', acceptedFiles);

  React.useEffect(() => {
    //@ts-ignore
    root.creation.submitFiles(acceptedFiles);
  }, [acceptedFiles]);

  return (
    <FlexColumn>
      <FlexRow sx={{ pt: 4 }} alignItems='baseline'>
        <IconButton
          onClick={() => {
            history.push('/');
          }}
        >
          <ArrowBack />
        </IconButton>
        <Typography variant='h2'>Bulk Creation</Typography>
      </FlexRow>
      <FlexRow>
        <Typography sx={{ mr: 1 }}>Creating assets for </Typography>
        <SelectEditor value={root.creation.category} getDisplay={categories.getName} />
        <Filler />
        <AsyncIconButton onClick={root.creation.startAll}>
          <DoneAll />
        </AsyncIconButton>
        <AsyncIconButton onClick={root.creation.deleteAll}>
          <Cancel />
        </AsyncIconButton>
      </FlexRow>
      <Paper elevation={2}>
        <div {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} />
          <p>Drag 'n' drop some folders here, or click to select folders</p>
        </div>
        <aside></aside>
      </Paper>
      <JobList type={AssetType.Furniture} jobs={root.creation.jobs} />
    </FlexColumn>
  );
};
