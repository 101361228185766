import {
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  Grid,
  Paper,
  Tab,
  Tabs,
} from '@mui/material';
import { AutoId } from 'common/AutoId';
import { FlexRow } from 'common/FlexRow';
import SmartSet from 'common/SmartSet';
import { useRoot } from 'components/RootProvider';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useState } from 'react';
import { AssetDO } from 'stores/Asset';
import { AssetImportStore } from 'stores/AssetImportStore';
import { OrientationDO, OrientationKey } from 'stores/Orientation';
import { useAssets } from '../useAssets';
import { AssetOrientationEditor } from './AssetOrientationEditor';
import { CheckedAssetPreview } from './CheckedAssetPreview';

type AssetListProps = {
  open: boolean;
  onClose: () => void;
  asset: AssetDO;
  importStore: AssetImportStore;
};
type CurrentOrientationEdit = {
  uid: string;
  mappings: Map<OrientationKey, OrientationKey>;
  possible: SmartSet<OrientationKey>;
};

export const AddExistingAssets = observer((props: AssetListProps) => {
  const { onClose, open, importStore } = props;
  const root = useRoot();
  const { collator } = root;
  const assets = useAssets();
  const categoryAssetIds = assets.assetsByCategory.get(assets.currentCategoryId);

  const [oriChangeOpen, setOriChangeOpen] = useState(false);

  const [currentOriChange, setChangeData] = useState<CurrentOrientationEdit>();

  const handleOpenOrientationMap = (uid: string) => {
    const data = importStore.getStagedAssetData(uid);
    setChangeData({
      uid,
      mappings: data.orientationMap,
      possible: new SmartSet(data.possibleOrientations),
    });
    setOriChangeOpen(true);
  };

  const assetMenu = () => (
    <Paper sx={{ width: 700, height: 600, overflow: 'auto' }}>
      {root.categories.refreshing ? (
        <CircularProgress variant='indeterminate'></CircularProgress>
      ) : (
        <Tabs
          variant='scrollable'
          onChange={(ev, value) => {
            assets.handleLoadAssets(value);
          }}
          value={assets.currentCategoryId}
        >
          {root.categories.categories.size > 0 &&
            [...root.categories.categories.entries()]
              .sort((a, b) => collator.compare(a[1].name.value, b[1].name.value))
              .map(([id, data]) => {
                return <Tab key={id} value={id ?? 'null'} label={data.name.value} />;
              })}
        </Tabs>
      )}
      <FlexRow sx={{ flexWrap: 'wrap' }}>
        {assets.loadingAssets ? (
          <CircularProgress variant='indeterminate' />
        ) : (
          categoryAssetIds?.map(assetId => (
            <CheckedAssetPreview
              uid={assetId}
              assetId={assetId}
              key={assetId}
              handleCheck={importStore.togglePotentialAsset}
              isChecked={importStore.potentialContains(assetId)}
            ></CheckedAssetPreview>
          ))
        )}
      </FlexRow>
    </Paper>
  );

  const assetToPushList = () => (
    <Paper sx={{ width: 700, height: 600, overflow: 'auto' }}>
      <AssetOrientationEditor
        open={oriChangeOpen}
        onConfirm={async newOrientations =>
          importStore.setStagedAssetMappings(currentOriChange?.uid, newOrientations)
        }
        onClose={() => setOriChangeOpen(false)}
        mapping={currentOriChange?.mappings}
        invertArrow={true}
        availableOrientations={currentOriChange?.possible}
      />
      <FlexRow sx={{ flexWrap: 'wrap' }}>
        {importStore.getStagedAssets().map(([uid, data]) => (
          <CheckedAssetPreview
            uid={uid}
            assetId={data.assetId}
            key={uid}
            handleCheck={importStore.toggleStagedAsset}
            isChecked={data.isChecked}
            orientationMap={data.orientationMap}
            handleRotate={importStore.rotateStagedAsset}
            handleSet={handleOpenOrientationMap}
          ></CheckedAssetPreview>
        ))}
      </FlexRow>
    </Paper>
  );

  return (
    <Dialog onClose={onClose} open={open} fullWidth={true} maxWidth={'xl'}>
      <DialogTitle>Existing Assets</DialogTitle>
      <Grid container alignItems='center'>
        <Grid item xs>
          {assetMenu()}
        </Grid>
        <Grid item xs>
          <Grid container direction='column' alignItems='center'>
            <Button
              sx={{ my: 0.5 }}
              variant='outlined'
              size='small'
              onClick={importStore.stagePotentialAssets}
              disabled={!importStore.hasPotentialAssets()}
              aria-label='move selected right'
            >
              &gt;
            </Button>
            <Button
              sx={{ my: 0.5 }}
              variant='outlined'
              size='small'
              onClick={importStore.unstageAssets}
              disabled={!importStore.hasStagedCheckedAssets()}
              aria-label='move selected left'
            >
              &lt;
            </Button>
            <Button
              sx={{ my: 0.5 }}
              variant='outlined'
              size='small'
              onClick={importStore.unstageAllAssets}
              disabled={!importStore.hasStagedAssets()}
              aria-label='move all left'
            >
              ≪
            </Button>
          </Grid>
        </Grid>
        <Grid item xs>
          {assetToPushList()}
        </Grid>
        <Grid container direction='column' alignItems='center'>
          <Button
            variant='contained'
            disabled={!importStore.hasStagedAssets()}
            onClick={() => {
              importStore.handleImportAssets();
              onClose();
            }}
          >
            Import
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  );
});
