import { Warning } from '@mui/icons-material';
import { Button, Typography } from '@mui/material';
import { Filler } from 'common/components/Filler';
import { FlexRow } from 'common/FlexRow';
import { useConfirmDialog } from 'common/hooks/useConfirmDialog';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { AssetDO } from 'stores/Asset';
import { StructureDO } from 'stores/Structure';
import { AssetType } from 'stores/RootStore';
import { ConfirmationDialog } from './ConfirmDialog';
import { useRoot } from './RootProvider';

type PublishSectionProps = {
  asset: EyekiaDO;
};

type EyekiaDO = AssetDO | StructureDO;
export const PublishSection = observer((props: PublishSectionProps) => {
  const { asset } = props;

  if (!asset) {
    return null;
  }

  if (asset.published.value && !asset.modifiedSinceLastPublish) {
    return <PublishedSection asset={asset} />;
  } else {
    return <UnpublishedSection asset={asset} />;
  }
});

const typographyStyle = {
  width: '100 px',
  mr: 1,
} as const;

export const PublishedSection = (props: { asset: EyekiaDO }) => {
  const { asset } = props;

  const [open, handleInit, handleCancel, handleConfirm] = useConfirmDialog(
    (...value: any[]) => (asset.published.value = false),
  );

  return (
    <>
      <FlexRow>
        <Typography sx={typographyStyle}>The asset is published. </Typography>
        <Button variant='outlined' color='secondary' onClick={handleInit}>
          Unpublish
        </Button>
        <ConfirmationDialog
          open={open}
          onClose={handleCancel}
          onConfirm={handleConfirm}
          title={'UN-PUBLISH this asset?'}
          message={
            'Unpublishing this asset will make it unavailable for people to use in the map maker, but it will not remove it from existing maps. Please be careful!'
          }
        />
      </FlexRow>
    </>
  );
};

export const UnpublishedSection = (props: { asset: EyekiaDO }) => {
  const { asset } = props;

  const [open, handleInit, handleCancel, handleConfirm] = useConfirmDialog(() => {
    return asset.publishAsset();
  });

  return (
    <>
      <FlexRow>
        {asset.published && asset.modifiedSinceLastPublish && <Warning sx={{ color: 'orange' }} />}
        <Typography sx={typographyStyle}>
          {asset.published && asset.modifiedSinceLastPublish
            ? 'This asset has been MODIFIED'
            : 'The asset is NOT published.'}
        </Typography>
        <Button variant='contained' color='primary' onClick={handleInit}>
          Publish
        </Button>
        <ConfirmationDialog
          open={open}
          onClose={handleCancel}
          onConfirm={handleConfirm}
          title={'PUBLISH this asset?'}
          message={
            'Publishing this asset will make it available for people to use in the map maker. Do not do this until youve tested it in the web app! Please be careful!'
          }
        />
      </FlexRow>
    </>
  );
};
