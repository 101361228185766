import { ColorVariation, VariationRenderer } from '@sophya/hephaestus-assets-core';

const canvasDraw = document.createElement('canvas');
const canvasGL = document.createElement('canvas');
const gl = canvasGL.getContext('webgl');

const renderer = new VariationRenderer(
  canvasDraw,
  image => {
    if (image instanceof HTMLImageElement) {
      const ctx = canvasDraw.getContext('2d');
      ctx.drawImage(image, 0, 0);
      return ctx;
    }
    return null;
  },
  (width, height) => {
    canvasGL.width = width;
    canvasGL.height = height;
  },
  gl,
);

export function recolorImage(image: HTMLImageElement, variant: ColorVariation) {
  return renderer.renderImage(image, variant);
}

export async function recolorUrls(urls: string[], variant: ColorVariation) {
  var outputUrls = [];
  for (const ind in urls) {
    const url = urls[ind];
    if (url.length !== 0) {
      outputUrls.push(await recolorUrl(url, variant));
    } else {
      outputUrls.push(url);
    }
  }
  return outputUrls;
}

export async function recolorUrl(url: string, variant: ColorVariation): Promise<string> {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = 'anonymous';

    img.onload = () => {
      const dataUrl = recolorImage(img, variant);
      resolve(dataUrl);
    };

    img.onerror = () => {
      reject('could not load image');
    };

    img.src = url;
  });
}
