import { client } from 'clients/eyekia';
import { Category } from '@sophya/eyekia';
import { BaseStore } from './BaseStore';
import { EditableValue } from './editor/EditableValue';

export class CategoryDO extends BaseStore {
  constructor(public id: string) {
    super();
    this.name = new EditableValue('name', '', () => {});
    this.thumbnail = new EditableValue('thumbnail', '', () => {});
    this.rank = new EditableValue('rank', 0, () => {})
  }

  name: EditableValue<string>;

  thumbnail: EditableValue<string>;

  rank: EditableValue<number>;

  updateFromSchema(category: Category): CategoryDO {
    this.name = new EditableValue('name', category.name, () => {});
    this.thumbnail = new EditableValue('thumbnail', category.thumbnail, () => {});
    this.rank = new EditableValue('rank', category.rank, () => {});
    return this;
  }

  toSchema(): Category {
    let thumbnail = this.thumbnail?.value ? { thumbnail: this.thumbnail.value } : {};
    return {
      name: this.name.value,
      rank: this.rank.value,
      ...thumbnail,
    };
  }

  handleDispose() {}

  save = async () => {
    await client().v1.categoryUpdate(this.id, {
      data: this.toSchema(),
    });
  };
}
