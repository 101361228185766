export default class SmartSet<T> extends Set<T> {
  constructor(iterable?: Iterable<T>) {
    super(iterable);
  }

  union(other: Set<T>): SmartSet<T> {
    return new SmartSet([...other, ...this]);
  }

  intersection(other: Set<T>): SmartSet<T> {
    return new SmartSet([...this].filter(x => other.has(x)));
  }

  difference(other: Set<T>): SmartSet<T> {
    return new SmartSet([...this].filter(x => !other.has(x)));
  }

  map(callbackfn: (value: T, index: number, array: T[]) => unknown, thisArg?: any): unknown[] {
    return [...this].map(callbackfn, thisArg);
  }
}
