import { FlexColumn } from 'common/FlexColumn';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { Divider, Link as MuiLink, Typography } from '@mui/material';
import { FlexRow } from 'common/FlexRow';

type HomePageProps = {};
export const HomePage = (props: HomePageProps) => {
  return (
    <FlexColumn sx={{ p: 2 }}>
      <Typography variant={'h2'}>Welcome to the asset creator</Typography>
      <FlexRow width='100%'>
        <Typography variant={'h4'}>{`This is the `}</Typography>
        <Typography variant='h4' sx={{ fontFamily: 'Monospace', color: 'red' }} pl={2} pr={2}>
          {` ${process.env.REACT_APP_ENV} `}
        </Typography>
        <Typography variant={'h4'}>{` environment`}</Typography>
      </FlexRow>
      <Divider sx={{ mt: 4, mb: 4 }} />
      <Link to='/categories'>Categories</Link>
      <Link to='/asset'>Asset List</Link>
      <Link to='/create'>Bulk Create</Link>
      <Link to='/structure'>Structure Bulk Creation</Link>
      <Link to='/floor'>Floor List</Link>
      <Link to='/wall'>Wall List</Link>
    </FlexColumn>
  );
};
