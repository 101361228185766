import { Add } from '@mui/icons-material';
import { Box, Dialog } from '@mui/material';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import Dropzone from 'react-dropzone';
import { OrientationDO } from 'stores/Orientation';
import { AddExistingAssets } from './AddExistingAssets';

const style = {
  width: '100%',
  maxWidth: 360,
  bgcolor: 'background.paper',
};

type AddOrientationImagesProps = {
  orientation: OrientationDO;
};

export const AddOrientationImages = observer((props: AddOrientationImagesProps) => {
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    props.orientation.openImageUpload();
  };

  const handleDrop = (files: any[]) => {
    props.orientation.handleImageDrop(files);
  };

  return (
    <>
      <div>
        <IconButton onClick={handleClick}>
          <Add />
        </IconButton>
      </div>
      <Dialog open={props.orientation.imageUploadOpen} onClose={props.orientation.closeImageUpload}>
        <Box sx={{ verticalAlign: 'middle', margin: 'auto' }} width='300px' height='200px'>
          <Dropzone onDrop={acceptedFiles => handleDrop(acceptedFiles)}>
            {({ getRootProps, getInputProps }) => (
              <section style={{ height: '100%', width: '100%' }}>
                <div {...getRootProps()} style={{ height: '100%', width: '100%' }}>
                  <input {...getInputProps()} />
                  <p
                    style={{
                      width: '100%',
                      margin: 'auto',
                      position: 'absolute',
                      top: '40%',
                      textAlign: 'center',
                    }}
                  >
                    Drop here, or click to select files
                  </p>
                </div>
              </section>
            )}
          </Dropzone>
        </Box>
      </Dialog>
    </>
  );
});
