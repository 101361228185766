import { TextFieldProps } from '@mui/material/TextField';
import { useState, useCallback, useEffect } from 'react';

export const useTextField = (
  props?: Omit<TextFieldProps, 'defaultValue'>,
): [string, (str: string) => void, TextFieldProps] => {
  const [value, innerSetValue] = useState<string>((props?.value as string) || '');

  const { ...restOProps } = props;

  const onChange = useCallback(
    e => {
      innerSetValue(e.target.value);
      if (props.onChange) {
        props.onChange(e);
      }
    },
    [props.onChange],
  );

  return [value, innerSetValue, { ...restOProps, value, onChange } as TextFieldProps];
};

export const useUrlField = (
  props?: TextFieldProps,
): [string, (s: string) => void, boolean, (isError: boolean) => void, TextFieldProps] => {
  const [value, setValue] = useState(
    props.defaultValue ? (props.defaultValue as string) : ('' as string),
  );
  const errorMessage = 'Please enter a valid url';
  const [error, setError] = useState<string>(null);
  const { defaultValue, ...otherProps } = props;
  const callError = (isError: boolean) => {
    if (isError) {
      setError(errorMessage);
    } else {
      setError(null);
    }
  };
  const onBlur = e => {
    console.debug('onBlur called', value);
    let itemUrl: string = value;
    const isUrl = isValidUrl(itemUrl);
    console.debug('isValue :', itemUrl, isUrl);
    if (!isUrl) {
      setError(errorMessage);
    } else {
      setError(null);
    }
  };
  const onChange = e => {
    setValue(e.target.value);
  };
  const hasError = Boolean(error && !otherProps.disabled);
  const errorProps: TextFieldProps = hasError ? { error: true, helperText: error } : null;

  return [
    value,
    setValue,
    hasError,
    callError,
    {
      ...otherProps,
      value,
      onChange,
      onBlur,
      ...errorProps,
    },
  ];
};

function isValidUrl(testUrl: string): boolean {
  return (
    /^(ftp|http|https):\/\/[^ "]+$/.test(testUrl) || // url
    /^mailto:([^\?]*)/.test(testUrl) // mailto
  );
}
