import { BaseStore } from './BaseStore';
import { FabricStore2 as FabricStore } from './fabric2/FabricStore2';
import { AssetDO } from './Asset';
import { action, makeObservable, observable, runInAction } from 'mobx';
import { AutoId } from '../common/AutoId';
import { OrientationDO, OrientationKey } from './Orientation';
import { PrimitiveDO } from './Primitive';
import { AssetType, FabricInterface, RootStore } from './RootStore';
import { Image, Asset, Primitive } from '@sophya/eyekia';
import { client } from 'clients/eyekia';
import { StructureDO } from './Structure';

export class StructureStore extends BaseStore {
  floors = new Map<string, StructureDO>();
  walls = new Map<string, StructureDO>();

  items(type: AssetType) {
    switch (type) {
      case AssetType.Floor:
        return this.floors;
      case AssetType.Wall:
        return this.walls;
        break;
    }
  }

  constructor(private root: RootStore) {
    super();
    makeObservable(this, {
      floors: observable,
      walls: observable,
      loadingStructures: observable,
    });

    this.handleLoadStructures();
  }

  loadingStructures: boolean = false;

  handleLoadStructures = async () => {
    this.loadingStructures = true;
    const resFloor = await client().v1.floorList({ noCache: true });
    const resWall = await client().v1.wallList({ noCache: true });
    runInAction(() => {
      for (const floor of resFloor.data.floors) {
        const { id, data } = floor;

        const floorDo = new StructureDO(id, this, AssetType.Floor);
        //@ts-ignore
        floorDo.updateFromSchema(data);
        this.floors.set(id, floorDo);
      }

      for (const wall of resWall.data.walls) {
        const { id, data } = wall;

        const wallDo = new StructureDO(id, this, AssetType.Wall);
        //@ts-ignore
        wallDo.updateFromSchema(data);
        this.walls.set(id, wallDo);
      }

      this.loadingStructures = false;
    });
  };

  currentStructure: string;

  async handleCreateStructure(type: AssetType) {
    switch (type) {
      case AssetType.Floor:
        return this.createFloor();
      case AssetType.Wall:
        return this.createWall();
    }
  }

  async handlePublishAll(type: AssetType) {
    const structures = Array.from(this.items(type), ([, structureDO]) => structureDO);
    const publishTasks = structures.map((structure) => {
      if (!structure.published.value || structure.modifiedSinceLastPublish) {
        return structure.publishAsset();
      }
    });
    await Promise.all(publishTasks);
  }

  createFloor = async (): Promise<void> => {
    const res = await client().v1.floorCreate({});
    const id = res.data.floorId;
    runInAction(() => {
      this.floors.set(id, new StructureDO(id, this, AssetType.Floor));
    });
  };
  createWall = async (): Promise<void> => {
    const res = await client().v1.wallCreate({});
    const id = res.data.wallId;
    runInAction(() => {
      this.walls.set(id, new StructureDO(id, this, AssetType.Wall));
    });
  };

  handleDispose(): void {
    for (const floor of this.floors.values()) {
      floor.dispose();
    }
    for (const wall of this.walls.values()) {
      wall.dispose();
    }
  }

  getStructure(type: AssetType, id: string) {
    switch (type) {
      case AssetType.Floor:
        return this.floors.get(id);

      case AssetType.Wall:
        return this.walls.get(id);
    }
  }

  handleDeleteStructure(type: AssetType, id: string) {
    switch (type) {
      case AssetType.Floor:
        this.deleteFloor(id);
        break;
      case AssetType.Wall:
        this.deleteWall(id);
        break;
    }
  }

  deleteFloor(id: string): void {
    runInAction(() => {
      this.floors.delete(id);
    });
    this.networkDeleteFloor(id);
  }
  networkDeleteFloor = async (id: string): Promise<void> => {
    await client().v1.floorDelete(id);
  };

  deleteWall(id: string): void {
    runInAction(() => {
      this.walls.delete(id);
    });
    this.networkDeleteWall(id);
  }
  networkDeleteWall = async (id: string): Promise<void> => {
    await client().v1.wallDelete(id);
  };
}
