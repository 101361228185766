import { Add, ArrowBack, DeleteForever, Edit, Image } from '@mui/icons-material';
import { CircularProgress, IconButton, TextField, Typography } from '@mui/material';
import { AsyncIconButton } from 'common/components/AsyncIconButton';
import { Filler } from 'common/components/Filler';
import { FlexColumn } from 'common/FlexColumn';
import { FlexRow } from 'common/FlexRow';
import { useConfirmDialog } from 'common/hooks/useConfirmDialog';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { useTextField } from '../common/hooks/useTextField';
import { CategoryEditor } from './CategoryEditor';
import { CategoryThumbnailModal } from './CategoryThumbnailModal';
import { ConfirmationDialog } from './ConfirmDialog';
import { useRoot } from './RootProvider';

type CategoryPageProps = {};
export const CategoryPage = observer((props: CategoryPageProps) => {
  const root = useRoot();
  const categories = root.categories;
  const history = useHistory();

  const [saving, setSaving] = React.useState(false);

  const [categoriesState, setCategoriesState] = React.useState(categories.categories);

  const [value, setValue, textFieldProps] = useTextField({
    disabled: saving,
  });

  const [open, handleInit, handleCancel, handleConfirm] = useConfirmDialog(
    categories.handleDeleteCategory,
  );

  const handleUpdateRank = async (rank, id) => {
    await categories.handleSaveRank(id, rank).finally(() => {
      const categoryUpdated = categories.categories;
      categoryUpdated.get(id).rank.value = rank;
      setCategoriesState(categoryUpdated);
    });
  };


  return (
    <FlexColumn fullSize>
      <FlexRow>
        <IconButton
          onClick={() => {
            history.push('/');
          }}
        >
          <ArrowBack />
        </IconButton>
        <Typography variant={'h2'}>Categories</Typography>
      </FlexRow>
      {categories.categories.size > 0 ? (
        [...categories.entries()]
          .sort((a, b) =>  ((a[1].rank.value) - b[1].rank.value) || a[1].name.value.localeCompare(b[1].name.value))
          .map(([id, data]) => {
            if (id === null) {
              return null;
            } else {
              return (
                <FlexRow>
                  <TextField label="Rank" id="outlined-size-small" type={'number'} 
                    value={categoriesState.get(id).rank.value} 
                    onChange={(e) => handleUpdateRank(e.target.value, id)}
                    sx={{width:'80px'}}
                  />
                  <Typography key={id}>{data.name.value}</Typography>
                  <img width='100px' src={data.thumbnail.value} />
                  <Filler />
                  <IconButton onClick={() => categories.handleOpenThumbnail(id)}>
                    <Image />
                  </IconButton>
                  <IconButton onClick={() => categories.handleOpenEditor(id)}>
                    <Edit />
                  </IconButton>
                  <IconButton onClick={() => handleInit(id)}>
                    <DeleteForever />
                  </IconButton>
                </FlexRow>
              );
            }
          })
      ) : (
        <CircularProgress variant='indeterminate' />
      )}
      <FlexRow>
        <TextField value={value} {...textFieldProps} />
        <AsyncIconButton
          onClick={async () => {
            setSaving(true);
            await categories.handleCreateCategory(value).finally(() => {
              setSaving(false);
              setValue('');
            });
          }}
        >
          <Add />
        </AsyncIconButton>
      </FlexRow>
      <ConfirmationDialog
        open={open}
        onClose={handleCancel}
        onConfirm={handleConfirm}
        title={'CAREFUL! Delete Category?'}
        message={
          'This will remove the category from all assets that have this category. You WILL have to recategorize the assets that dont have a category'
        }
      />
      <CategoryEditor />
      <CategoryThumbnailModal />
    </FlexColumn>
  );
});
