import { Dialog } from '@mui/material';
import ImageCrop from 'common/components/ImageCrop';
import { useAssets } from 'components/useAssets';
import { observer } from 'mobx-react-lite';
import React from 'react';
import 'react-image-crop/dist/ReactCrop.css';

const AssetThumbnailModal = observer(() => {
  const assets = useAssets();

  const asset = assets.getAsset(assets.currentAsset);

  if (!asset) {
    return null;
  }

  return (
    <Dialog open={asset.thumbnailModalOpen} onClose={asset.cancelThumbnailCrop}>
      <ImageCrop
        imgDataUrl={asset.thumbnailPreCropDataUrl}
        onCrop={asset.saveThumbnailCrop}
        onCancel={asset.cancelThumbnailCrop}
      />
    </Dialog>
  );
});

export default AssetThumbnailModal;
