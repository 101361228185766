import { Box } from '@mui/material';
import { useRoot } from 'components/RootProvider';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { BulkCreationStore } from 'stores/BulkCreationStore';
import { AssetCreationJob } from 'stores/creation/CreationJob';
import { StructureCreationJob } from 'stores/creation/StructureBulkJob';
import { AssetType } from 'stores/RootStore';
import { StructureBulkStore } from 'stores/StructureBulkStore';
import { JobPreview } from './JobPreview';
import { StructureJobPreview } from './StructureJobPreview';

type JobListProps = { type: AssetType; jobs: AssetCreationJob[] | StructureCreationJob[] };
export const JobList = observer((props: JobListProps) => {
  const root = useRoot();
  const { type, jobs } = props;

  const hasJobs = jobs.length > 0;
  var content;

  switch (type) {
    case AssetType.Furniture:
      content = <Box>{hasJobs && jobs.map(job => <JobPreview key={job.key} job={job} />)}</Box>;
      break;
    case AssetType.Floor || AssetType.Wall:
      content = (
        <Box>{hasJobs && jobs.map(job => <StructureJobPreview key={job.key} job={job} />)}</Box>
      );
  }

  return content;
});
