import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { RootStore } from '../stores/RootStore';
import { useComputedState } from '../common/hooks/useComputedState';
import { useAuth } from './AuthProvider';

export const RootContext = React.createContext<RootStore>(null);
export function useRoot() {
  return React.useContext(RootContext);
}

type RootProviderProps = React.PropsWithChildren<{}>;

export const RootProvider = observer((props: RootProviderProps) => {
  const {} = props;

  const auth = useAuth();

  const authorized = auth.authorized;

  const store = useComputedState(() => {
    if (authorized) {
      const s = new RootStore();
      return [s, () => s.dispose()];
    } else {
      return [null, () => {}];
    }
  }, [authorized]);

  return <RootContext.Provider value={store}>{store ? props.children : null}</RootContext.Provider>;
});
