import { Asset, Image, Orientation } from '@sophya/eyekia';
import { client } from 'clients/eyekia';
import { AutoId } from 'common/AutoId';
import { imageDimensions } from 'common/getImageDimensions';
import { uploadImageToAsset } from 'common/uploadImageToAsset';
import iterate from 'iterare';
import { makeObservable, observable } from 'mobx';
import { BaseStore } from 'stores/BaseStore';
import { EditableSelect, EditableValue } from 'stores/editor/EditableValue';
import { OrientationKey, isOriKey } from 'stores/Orientation';
import { AssetType } from 'stores/RootStore';

const numberToOriKey: OrientationKey[] = ['N', 'NE', 'E', 'SE', 'S', 'SW', 'W', 'NW'];

type UploadValues = {
  url: string;
  file: File;
  dims: {
    width: number;
    height: number;
  };
};

export function fileNameToOriKey(name: string): OrientationKey | undefined {
  const extLength = '.png'.length;
  const nameParts = name.slice(0, -extLength).split('_');
  const lastPart = nameParts[nameParts.length - 1];
  if (isOriKey(lastPart)) {
    return lastPart;
  } else if (!isNaN(parseInt(lastPart))) {
    const num = parseInt(lastPart);
    if (num >= 0 && num < 8) {
      return numberToOriKey[num];
    }
  }
  return undefined;
}

export class AssetCreationJob extends BaseStore {
  constructor(
    private getCategories: () => string[],
    public folder: string,
    private files: File[],
    initialName: string,
    initialCategory: string,
  ) {
    super();
    makeObservable(this, {
      status: observable,
    });
    this.name = new EditableValue<string>('name', initialName, () => {});
    this.category = new EditableSelect<string>(
      'category',
      initialCategory,
      () => {},
      this.getCategories,
    );

    this.countDirections();
  }

  key: string = AutoId.newId();

  assetId: string = null;

  private countDirections() {
    const directions = new Set<OrientationKey>();
    for (const file of this.files) {
      const oriKey = fileNameToOriKey(file.name);
      if (oriKey) {
        directions.add(oriKey);
      }
    }
    this.numDirections = directions.size;
    this.directions = directions;
  }

  numDirections: number = 0;

  directions: Set<OrientationKey>;

  name: EditableValue<string>;
  category: EditableSelect<string>;

  status: 'init' | 'started' | 'finished' = 'init';

  startJob = async () => {
    if (this.status !== 'init') {
      return;
    }

    this.status = 'started';
    const eyekia = client();

    const assetResponse = await eyekia.v1.assetCreate({});
    const assetId = assetResponse.data.assetId;

    const filesByOrientation = new Map<OrientationKey, File[]>();

    for (const file of this.files) {
      const oriKey = fileNameToOriKey(file.name);
      if (oriKey) {
        const files = filesByOrientation.get(oriKey) ?? [];
        files.push(file);
        filesByOrientation.set(oriKey, files);
      }
    }

    const allOrientations = await Promise.all(
      iterate(filesByOrientation.entries())
        .map(async ([ori, fileGroup]) => {
          //
          const promises = fileGroup.map(async (file: File) => {
            const urlPromise = uploadImageToAsset(assetId, AssetType.Furniture, file);
            const dimensionsPromise = imageDimensions(file);
            const res = await Promise.all([urlPromise, dimensionsPromise]);
            return {
              url: res[0],
              dims: res[1],
              file,
            };
          });
          const results = await Promise.all(promises);
          return {
            ori,
            results,
          };
        })
        .toArray(),
    );
    //RZNewman ignore "Type instantiation is excessively deep and possibly infinite"
    //@ts-ignore
    const asset: Asset = {
      name: this.name.value,
      description: '',
      categoryId: this.category.value,
      orientations: {},
    };

    for (const data of allOrientations) {
      const orientation: Orientation = {
        images: {},
      };

      for (const results of data.results) {
        const image: Image = {
          url: results.url,
          type: 'image',
          primitives: {},
          transform: {
            position: { x: 0, y: 0 },
            depthBand: 0,
            frontPoint: {
              x: 0,
              y: -results.dims.height / 2,
            },
          },
        };

        const imageId = AutoId.newId();

        orientation.images[imageId] = image;
      }

      asset.orientations[data.ori] = orientation;
    }

    //@ts-ignore string index types are wrong
    await eyekia.v1.assetUpdate(assetId, { data: asset });
    this.assetId = assetId;
    this.status = 'finished';
  };

  handleDispose() {}
}
